import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal as ModalAn, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import fondo from '../../assets/img/matriculas/fondo.svg';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';

import informacoes from '../../assets/img/pagamentos/informacoes.png';
import { Auth } from '../../models/auth.model';
import { ICobrancaDTO, ICobrancaPagarMeDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useAuthService } from '../../services/auth.service';
import { useCobrancaService } from '../../services/cobranca.service';
import { useNotificationService } from '../../services/notification.service';
import { MatriculaProgresso } from './progresso';
import './style.css';

const { Option } = Select;

export const MatriculasCreateStep4: React.FunctionComponent = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [primeiraCobranca, setPrimeiraCobranca] = useState<ICobrancaDTO>()
	const [cobrancas, setCobrancas] = useState<ICobrancaDTO[]>()
	const { setIsGlobalLoading } = useGlobalContext();
	const cobrancaService = useCobrancaService();
	const notification = useNotificationService();
	const [tamanhoCVV, setTamanhoCVV] = useState<number>();
	const [cobrancaCredito, setCobrancaCredito] = useState<ICobrancaPagarMeDTO>();
	const [token, setToken] = useState<Auth>();
	const authService = useAuthService();
	const { idMatricula } = useParams<any>();

	const finaliza = async () => {
		setIsGlobalLoading(true);

		if (!cobrancaCredito?.tipoPagamento?.codigo) {
			notification({ description: "Selecione a forma de pagamento", type: 'warning', message: 'Aviso!' });
			return;
		}

		if (primeiraCobranca) {
			if (cobrancaCredito) {
				cobrancaService
					.pagarme(cobrancaCredito, token.access_token)
					.then(({ data }) => {
						const { url, statusCobranca, acquirerReturnCode, qrCode } = data;
						setCobrancaCredito({ ...cobrancaCredito, url, statusCobranca, acquirerReturnCode, qrCode });
						// if (!("AGR,FLH").includes(data.statusCobranca)) {
						//     setCurrent(step.CONCLUSAO);
						// }

						if (data.statusCobranca === "GRD") {
							const interval = setInterval(() => {
								cobrancaService.findStatus(data.id, token.access_token).then(({ data }) => {
									if (data === 'PGO') {
										setCobrancaCredito({ ...cobrancaCredito, statusCobranca: data });
										clearInterval(interval);
									}
								})
							}, 10000);
						}

						if (data.acquirerReturnCode && data.statusCobranca !== "PGO"
							&& (cobrancaCredito)) {

							switch (data.acquirerReturnCode) {
								case "0000":
									ModalAn.error({ content: "Pagamento não autorizado (Antifraude)", title: 'Aviso!' });
									break;

								case "1000":
									ModalAn.error({ content: "Transação não autorizada, oriente o portador a contatar o banco/emissor do cartão", title: 'Aviso!' });
									break;

								case "2002":
									ModalAn.error({ content: "Transação com suspeita de fraude", title: 'Aviso!' });
									break;

								case "1001":
									ModalAn.error({ content: "Pagamento não autorizado (Cartão vencido, a data de vencimento do cartão expirou)", title: 'Aviso!' });
									break;

								case "1011":
									ModalAn.error({ content: "Pagamento não autorizado (Cartão inválido)", title: 'Aviso!' });
									break;

								case "1016":
									ModalAn.error({ content: "Pagamento não autorizado (Saldo insuficiente)", title: 'Aviso!' });
									break;

								case "1022":
									ModalAn.error({ content: "Pagamento não autorizado (Violação de segurança)", title: 'Aviso!' });
									break;

								default:
									ModalAn.error({ content: "Pagamento não autorizado.", title: 'Aviso!' });
									break;
							}
						}
					})
					.finally(() => setIsGlobalLoading(false))
			} else {
				setIsGlobalLoading(false)
				notification({ description: "Falha no pagamento", type: 'warning', message: 'Aviso!' });
			}
		}
	}

	useEffect(() => {
		if (primeiraCobranca != null) {
			const isencao = location.search.substring(1);

			setIsGlobalLoading(true);
			authService.basic()
				.then(({ data }) => {
					setToken(data);
					return data;
				})
				.then(async (responseToken) => {

					const { data } = await cobrancaService
						.findCheckout(primeiraCobranca?.codigoPedido, isencao, responseToken.access_token);

					if (data !== null) {

						setCobrancaCredito(data);
					} else {
						notification({ description: "Cobrança não encontrada", type: 'warning', message: 'Aviso!' });
					}
				})
				.finally(() => setIsGlobalLoading(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [primeiraCobranca])

	const fetchPagamento = () => {
		setIsGlobalLoading(true);
		const predicate = new Predicate();
		predicate.addSort("dataVencimento");
		cobrancaService.findByMatriculaIds(idMatricula).then((response) => setCobrancas(response.data))
			.catch((error) => console.log(error))
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		fetchPagamento();
	}, [])

	useEffect(() => {
		setPrimeiraCobranca(cobrancas?.find(c => c.parcela === 0) || cobrancas?.find(c => c.parcela === 1))
	}, [cobrancas])


	const abrirCheckout = async () => {
		const url = `/checkout/${primeiraCobranca.codigoPedido}`;
		window.open(url);
	}

	const copiarLink = async () => {
		navigator.clipboard.writeText(primeiraCobranca.qrCode);
		notification({ description: "", type: 'success', message: 'Link Copiado' });
	}

	function onChangeNumber(numero) {
		setCobrancaCredito({ ...cobrancaCredito, cartao: { ...cobrancaCredito?.cartao, numero } })
	}

	function onChangeBrand(bandeira) {
		if (bandeira === 'Amex') {
			setTamanhoCVV(4);
		} else {
			setTamanhoCVV(3);
		}
		setCobrancaCredito({ ...cobrancaCredito, cartao: { ...cobrancaCredito?.cartao, bandeira } })
	}

	function onChangeName(nome) {
		setCobrancaCredito({ ...cobrancaCredito, cartao: { ...cobrancaCredito?.cartao, nome } })
	}

	function onChangeMonth(mes) {
		setCobrancaCredito({ ...cobrancaCredito, cartao: { ...cobrancaCredito?.cartao, mes } })
	}

	function onChangeYear(ano) {
		setCobrancaCredito({ ...cobrancaCredito, cartao: { ...cobrancaCredito?.cartao, ano } })
	}

	function onChangeCvv(cvv) {
		setCobrancaCredito({ ...cobrancaCredito, cartao: { ...cobrancaCredito?.cartao, cvv } })
	}

	const dadosCartao = () => {

		return (

			<Form
				name="basic"
				labelCol={{ span: 8 }}
				// wrapperCol={{ span: 16 }}
				initialValues={{ remember: true }}
				// onFinish={onFinish}
				// onFinishFailed={onFinishFailed}
				autoComplete="off">

				<h3>Dados do Cartão</h3>
				<div className="justify">
					<div style={{ width: '55%' }}>
						<div className="input-franquia" id="box-pedido" >
							<h5>Número de Cartão</h5>
							<Form.Item
								name="q"
								rules={[{ required: true, message: '' }]}
							>
								<MaskedInput mask="1111 1111 1111 1111" name="card" onChange={(e) => onChangeNumber(e.target.value)} />
							</Form.Item>
						</div>
					</div>
					<div style={{ width: '35%' }}>
						<div className="input-franquia" id="box-pedido">
							<h5>Bandeira</h5>
							<Form.Item
								name="r"
								rules={[{ required: true, message: '' }]}
							>
								<Select defaultValue="" size='small' onChange={onChangeBrand}>
									<Option value="Mastercard">Mastercard</Option>
									<Option value="Visa">Visa</Option>
									<Option value="Diners">Diners Club</Option>
									<Option value="Amex">American Express</Option>
									<Option value="Elo">Elo</Option>
								</Select>
							</Form.Item>
						</div>
					</div>
				</div>
				<div className="input-franquia" id="box-pedido">
					<h5>Nome como está no Cartão</h5>
					<Form.Item
						name="w"
						rules={[{ required: true, message: 'Campo Obrigatório' }]}
					>
						<Input onChange={(e) => onChangeName(e.target.value)} />
					</Form.Item>
				</div>
				<div className="justify">
					<div style={{ width: '33%' }}>
						<div className="input-franquia" id="box-pedido">
							<h5>Mês</h5>
							<Form.Item
								name="mes"
								rules={[{ required: true, message: 'Campo Obrigatório', len: 2 }]}
							>
								<MaskedInput mask="11" name="MM" onChange={(e) => onChangeMonth(e.target.value)} />
							</Form.Item>
						</div>
					</div>
					<div style={{ width: '33%' }}>
						<div className="input-franquia" id="box-pedido">
							<h5>Ano</h5>
							<Form.Item
								name="ano"
								rules={[{ required: true, message: 'Campo Obrigatório', len: 2 }]}
							>
								<MaskedInput mask="11" name="AA" onChange={(e) => onChangeYear(e.target.value)} />
							</Form.Item>
						</div>
					</div>
					<div style={{ width: '33%' }}>
						<div className="input-franquia" id="box-pedido">
							<h5>CVV</h5>
							<Form.Item
								name="b"
								rules={[{ required: true, message: 'Campo Obrigatório', len: tamanhoCVV }]}
							>
								<MaskedInput mask={tamanhoCVV == 4 ? "1111" : "111"} name="CVV" onChange={(e) => onChangeCvv(e.target.value)} />
							</Form.Item>
						</div>
					</div>
				</div>
			</Form>

		)
	}

	return (
		<div className="container-fondo">
			<Header />
			<MatriculaProgresso step={4} percent={80} />
			<div className="franquia" style={{ left: 100 }}>
				<div className="user-row" id="row-aluno">
					<h3>Pagamento da matrícula</h3>
					<div className="matriculas">
						<div className="pagamentos">
							{primeiraCobranca?.tipoPagamento?.codigo === "PIX" ?
								<div>
									<h2>Chave PIX!</h2>
									<img src={primeiraCobranca.linkBoleto} /><br />
									<Button type="primary" onClick={copiarLink}>Copiar Qr Code</Button>
								</div>
								: primeiraCobranca?.tipoPagamento?.codigo === "BOL" ?
									<div>
										<h2>Boleto</h2>
										<iframe src={primeiraCobranca.linkBoleto} width="700px" height="600px"></iframe>
									</div>
									: primeiraCobranca?.tipoPagamento?.codigo === "CRC" || primeiraCobranca?.tipoPagamento?.codigo === "CRR" ?
										<div>
											{cobrancaCredito?.statusCobranca !== 'PGO' ? dadosCartao() :
												<div className="content-body">
													{cobrancaCredito.statusCobranca === 'PGO' ? <img src={informacoes} /> : null}
												</div>}
										</div> : null}

							<div className="botoes">
																				
																	{/* {!isOpenModalPagamento && <Button
										onClick={() => finishPagamento()}
										className="button-primary"
										style={{ width: 345 }}
									>
										Gerar Pagamento
									</Button>} */}
								{(primeiraCobranca?.tipoPagamento?.codigo === "CRC" || primeiraCobranca?.tipoPagamento?.codigo === "CRR") && cobrancaCredito?.statusCobranca !== "PGO" ?
									<Button
										onClick={finaliza}
										className="button-primary"
										style={{ width: "38.75rem" }}
									>
										Finalizar pagamento
									</Button> : primeiraCobranca?.tipoPagamento?.codigo !== "CRC" && primeiraCobranca?.tipoPagamento?.codigo !== "CRR" ?
										<Link to="/matriculas/create/step-5">
											<Button className="button-primary" style={{ width: "43.75rem" }}>
												Próximo
											</Button>
										</Link> : cobrancaCredito?.statusCobranca === "PGO" ?
											<Link to="/matriculas/create/step-5">
												<Button className="button-primary" style={{ width: "43.75rem" }}>
													Próximo
												</Button>
											</Link> : null}
							</div>
						</div>
					</div>
					{/* <ModalPagamento
						title={'Pagamento de Matrícula'}
						subtitle={getSubtitleText()}
						method={matricula.tipoPagamento.id}
						open={isOpenModalPagamento}
						onClose={() => setIsOpenModalPagamento(false)}
						onFinish={finishPagamento}
					/> */}
				</div>
			</div>
			<Button
				onClick={() => setIsOpen(true)}
				className="button-fechar"
				icon={<CloseOutlined rev={undefined} />}
			/>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a adição desta Nova Matricula?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/alunos"
			/>
			<img src={fondo} alt="" className="img-fondo" />
		</div>
	);
};
