import { useState } from "react";
import { useTurmaContext } from "../../../context/TurmaContext";
import { IDiaSemana, IHorarioCleanDTO, INegocioDTO } from "../../../models/happy-code-api.model";

interface Props {
    negocioSelected: INegocioDTO;
}

const useTime = ({ negocioSelected }: Props) => {
    const { turma, setTurma } = useTurmaContext();
    const [adicionarHorario, setAdicionarHorario] = useState<boolean>(false);

    const onConfirmarHorario = (novaHora: string, dia: IDiaSemana) => {
        const horaSplitada = novaHora?.split('-');

        const novoHorario: IHorarioCleanDTO = {
            id: null,
            horaInicio: horaSplitada[0],
            horaFim: horaSplitada[1],
            codigo: dia,
            descricao: null,
            diaSemana: dia,
            descricaoHorario: null,
            ativo: true,
        };

        setTurma((prev) => ({
            ...prev,
            horarios: [...prev.horarios, novoHorario],
        }));

        setAdicionarHorario(false);
    };

    const shouldShowAddCurriculumHours = () => {
        const horariosLength = turma.horarios?.length || 0;

        switch (negocioSelected?.codigo) {
            case 'HOC':
            case 'HEN':
                return horariosLength < 2;
            case 'HOI':
                return horariosLength < 4;
            case 'CUR':
                return horariosLength < 1;
            case 'COL':
            case 'CBO':
                return true;
            default:
                return false;
        }
    };

    return {
        onConfirmarHorario,
        shouldShowAddCurriculumHours,
        setAdicionarHorario,
        adicionarHorario, 
    };
};

export default useTime;
