import { useMemo } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { formatHora } from '../../../util/format';
import { horarioDaGrade } from '../../../components/Calendar/funcoes';

const useGridFirstTime = () => {
    const { unidade, tipoUnidade } = useGlobalContext();

    const criarGradeAPartirDaPrimeiraHoraDaFranquia = useMemo(() => {
        if (!unidade?.horaAulaInicio || !unidade?.horaFechamento) return [];

        const primeiroHorario = formatHora(unidade.horaAulaInicio);
        const horaFechamentoHub = formatHora(unidade.horaFechamento);

        return horarioDaGrade(primeiroHorario, horaFechamentoHub, tipoUnidade?.id === 1);
    }, [unidade, tipoUnidade]);

    return criarGradeAPartirDaPrimeiraHoraDaFranquia;
};

export default useGridFirstTime;
