import { QuestionCircleOutlined } from "@ant-design/icons";
import { TableColumnsType, Tooltip } from "antd";
import { IOperacoesPagarMeV4DTO } from "../../../../models/relatorio.model";
import { formatLocaleDateString, formatMoney, formattedDate } from "../../../../util/format";
import useTranslate from "./hooks/use-translation.hook";

const TableColumns = () => {
	const { translate } = useTranslate();
	
	const columns: TableColumnsType<IOperacoesPagarMeV4DTO> = [
		{
			title: (
				<div>
					Data de Criação
					<Tooltip title="Data que foi criada a Cobrança.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: ['operacao', 'date_created'],
			align: "center",
			key: 'date_created',
			render: (text) => formatLocaleDateString(text),
		},
		{
			title: (
				<div>
					Data Pagamento
					<Tooltip title="Data que foi liberado pagamento/saque.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'dhPagamento',
			align: "center",
			key: 'dhPagamento',
			render: (text) => formattedDate(text),
		},
		{
			title: (
				<div>
					Nome do Aluno
					<Tooltip title="Nome do aluno.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'nomeAluno',
			align: "center",
			key: 'nomeAluno',
			render: (text) => text || "-",
		},
		{
			title: (
				<div>
					Nome do Responsável
					<Tooltip title="Nome do Responsável.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'nomeResponsavel',
			align: "center",
			key: 'nomeResponsavel',
			render: (text) => text || "-",
		},
		{
			title: (
				<div>
					Tipo Pagamento
					<Tooltip title="Tipo Pagamento.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: ['operacao', 'movement_object', 'payment_method'],
			align: "center",
			key: 'payment_method',
			render(_value, record, _index) {
				return translate(record.operacao.movement_object.payment_method);
			},
		},
		{
			title: (
				<div>
					Valor da Cobrança
					<Tooltip title="Valor total da cobrança.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'vlCobranca',
			align: "center",
			key: 'vlCobranca',
			render(value, record, _index) {
				const valorFinal = record?.flUnica ? value / record?.parcelaFinanceiro : value
				return formatMoney(valorFinal) || "-";
			},
		},
		{
			title: (
				<div>
					Valor Pago
					<Tooltip title="Valor que foi liberado e está disponível para saque">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'vlPago',
			align: "center",
			key: 'vlPago',
			render(value, record, _index) {
				const valorFinal = record?.flUnica ? value / record?.parcelaFinanceiro : value
				return formatMoney(valorFinal) || "-";
			},
		},
		{
			title: (
				<div>
					Valor Sede
					<Tooltip title="Valor da licença.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'vlSede',
			align: "center",
			key: 'vlSede',
			render(value, record, _index) {
				const valorFinal = record?.flUnica ? value / record?.parcelaFinanceiro : value
				return formatMoney(valorFinal) || "-";
			},
		},
		{
			title: (
				<div>
					Valor Franquia
					<Tooltip title="Valor do HUB bruto, sem subtrair as taxas.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'vlFranquia',
			align: "center",
			key: 'vlFranquia',
			render(value, record, _index) {
				const valorFinal = record?.flUnica ? value / record?.parcelaFinanceiro : value
				return formatMoney(valorFinal) || "-";
			},
		},
		{
			title: (
				<div>
					Taxa
					<Tooltip title="Taxa paga na transação ao pagar.me.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: ['operacao', 'movement_object', 'fee'],
			align: "center",
			key: 'fee',
			render: (text) => formatMoney(text / 100) || "-",
		},
		{
			title: (
				<div>
					Valor
					<Tooltip title="Valor do HUB após subtrair as taxas.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: ['operacao', 'movement_object', 'amount'],
			align: "center",
			key: 'amount',
			render: (text, record) => {
				const valor = text / 100;
				const taxa = record.operacao.movement_object.fee / 100;
				const valorFinal = valor - taxa;
				return formatMoney(valorFinal) || "-";
			},
		},
		{
			title: (
				<div>
					Parcela
					<Tooltip title="Se for cartão de crédito este campo vai informar qual a parcela.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'parcela',
			align: "center",
			key: 'parcela',
			render: (text) => text || "-",
		},
		{
			title: (
				<div>
					Parcela Financeiro
					<Tooltip title="Total de parcelas.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'parcelaFinanceiro',
			key: 'parcelaFinanceiro',
		},
		{
			title: (
				<div>
					Única
					<Tooltip title="Parcela Única.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'flUnica',
			align: "center",
			key: 'flUnica',
			render: (text) => text ? "Sim" : "Não",
		},
		{
			title: (
				<div>
					Operação
					<Tooltip title="Tipo de Operação.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: ['operacao', 'type'],
			align: "center",
			key: 'type',
			render(_value, record, _index) {
				return translate(record.operacao.type);
			},
		},
		{
			title: (
				<div>
					Status da Operação
					<Tooltip title="Status atual da transação.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: ['operacao', 'status'],
			align: "center",
			key: 'status',
			render(_value, record, _index) {
				return translate(record.operacao.status);
			},
		},
		{
			title: 'Objeto de Movimento',
			dataIndex: ['operacao', 'movement_object', 'object'],
			align: "center",
			key: 'movement_object',
			render(_value, record, _index) {
				return translate(record.operacao.movement_object.object);
			},
		},
		{
			title: 'Status do Movimento',
			dataIndex: ['operacao', 'movement_object', 'status'],
			key: 'movement_status',
			render(_value, record, _index) {
				return translate(record.operacao.movement_object.status);
			},
		},
		{
			title: (
				<div>
					Descrição do Currículo
					<Tooltip title="Descrição do Currículo.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'dsCurso',
			align: "center",
			key: 'dsCurso',
			render: (text) => text || "-",
		},
		{
			title: (
				<div>
					ID da Operação
					<Tooltip title="Identificador da Operação.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: ['operacao', 'id'],
			align: "center",
			key: 'id',
			render: (text) => text || "-",
		},
		{
			title: (
				<div>
					ID da Cobrança
					<Tooltip title="Identificador da Cobrança.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: 'cobrancaId',
			align: "center",
			key: 'cobrancaId',
			render: (text) => text || "-",
		},
		{
			title: (
				<div>
					ID do Saque
					<Tooltip title="Identificador do Saque.">
						<QuestionCircleOutlined className="ml-5 mr-5" rev={undefined} />
					</Tooltip>
				</div>
			),
			dataIndex: ['operacao', 'oid'],
			align: "center",
			key: 'oid',
			render: (text) => text || "-",
		},
		{
			title: 'Nome da Unidade',
			dataIndex: 'nomeUnidade',
			align: "center",
			key: 'nomeUnidade',
			render: (text) => text || "-",
		},
	];

	return columns;
};

export default TableColumns;
