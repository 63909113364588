
import { Button, Progress } from 'antd';
import 'antd/dist/antd.css';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../components/Header';
import { useGlobalContext } from '../../context/GlobalContext';

import { IPasswordChangeProfile } from '../../models/happy-code-api.model';
import { useNotificationService } from '../../services/notification.service';
import { useUsuarioService } from '../../services/usuario.service';
import './style.css';

const Senha: React.FunctionComponent = () => {
  const { usuario, setIsGlobalLoading } = useGlobalContext();
  const serviceUsuario = useUsuarioService();
  const password = useRef({});
  const history = useHistory();
  const notification = useNotificationService();

  const { register, handleSubmit, watch, formState: { errors } } = useForm<IPasswordChangeProfile>();

  const save = (data: IPasswordChangeProfile) => {
    setIsGlobalLoading(true);
    serviceUsuario.alteraSenhaProfile(data)
      .then(() => notification({ message: "Sucesso!", description: "Sua senha foi alterada com sucesso!" }))
      .finally(() => {
        setIsGlobalLoading(false);
        history.push('/usuarios/profile');
      });
  }

  password.current = watch("senha", "");

  return (
    <div className="container-fondo">
      <form onSubmit={handleSubmit(save)} className="container" style={{ backgroundColor: 'var(--white-color' }}>
        <Header />
        <div className="progress">
          <Progress className="progress-user" percent={20} strokeLinecap="square" showInfo={false} />
        </div>
        <div className="franquia" id="aluno-detalhe-row">
          <div className="user-row">
            <div className="box-title-senha">
              <h3 id="title-senha">Alterar Senha</h3>
              <p>Esqueceu a Senha</p>
            </div>
            {/* <h6 id="sub-title-senha">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tristique quam tortor.</h6> */}
            <ul className="password-requisitos-alterar">
              <li>Mínimo 5 caracteres</li>
              <li>Pelo menos 1 letra maiúscula</li>
              <li>Pelo menos 1 número</li>
            </ul>
            <input
              type="hidden"
              value={usuario?.id}
              {...register('id', { required: true })}
            />
            <div className="input-box">
              <input
                type="password"
                placeholder="Digite a Senha Atual"
                {...register('senhaAnterior', { required: true })}
              />
              {/* <Input placeholder="Senha Atual" id="senha-anterior" onChange={(e) => setSenhaNova({ ...senhaNova, senhaAnterior: e.target.value })} /> */}
            </div>
            <div className="input-box">
              <input
                type="password"
                placeholder="Digite a Nova Senha"
                {...register('senha', {
                  required: true,
                  pattern: /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z!@#$%&*]{5,}$/,
                })}
              />
              {errors.senha && <small className="input-error">A senha digitada não possui os requisitos acima</small>}

              {/* <Input placeholder="Nova Senha" id="senha-nova" onChange={(e) => setSenhaNova({ ...senhaNova, senha: e.target.value })} /> */}
            </div>
            <div className="input-box">
              <input type="password" placeholder="Digite novamente a senha"
                {...register('senhaConfirma',
                  { validate: value => value === password.current || "As senhas não combinam" }
                )}
              />
              {errors.senhaConfirma && <small className="input-error">A senha digitada não possui os requisitos acima</small>}

              {/* <Input placeholder="Confirme a Nova Senha" id="senha-confirma" onChange={(e) => setSenhaNova({ ...senhaNova, senhaConfirma: e.target.value })} /> */}
            </div>
            <div className="botoes" id="botoes-senha">
              <Link to="/usuarios/profile">
                <Button type="primary" className="button-primary" style={{ marginRight: 10 }}>Cancelar</Button>
              </Link>
              <Button type="primary" htmlType="submit" className="button-second">
                Alterar Senha
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )

}

export default Senha;