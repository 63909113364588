import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';
import * as Unicons from '@iconscout/react-unicons';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { IMensagemHomeDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useMensagemHomeService } from '../../services/mensagem-home';
import FiltrosMensagem from './filtros-mensagem';
import ItemListmensagem from './item-lista';
import './style.css';

const MensagemHome: React.FunctionComponent = () => {
	const [data, setData] = useState<PageableResponse<IMensagemHomeDTO>>();
	const history = useHistory();
	const [predicate] = useState<Predicate>(new Predicate());
	const {usuario} = useGlobalContext();

	const { setIsGlobalLoading } = useGlobalContext();

	const mensagemHomeService = useMensagemHomeService();

	const findMensagem = () => {
		setIsGlobalLoading(true);
		mensagemHomeService
			.find(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		setIsGlobalLoading(true);
		findMensagem();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findMensagem();
	};
	const ordenar = (campo) => {
		predicate.addSort(campo);
		findMensagem();
	};
	return (
		<div className="container">
			<NewMenu selecionado={29} />
			<div className="dashboard" style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}>
				<div className="box-title">
					<h3 className="title">Mensagens ({data?.totalElements ? data?.totalElements : 0})</h3>
					<Button className="button-primary" id="criar-c" icon={<PlusOutlined rev={undefined} />} onClick={() => history.push('/mensagem-home-create')}>
						Nova Mensagem
					</Button>
				</div>
				<FiltrosMensagem findMensagem={findMensagem} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '15%' }}>
							<span>ID</span>
						<div className="arrows" onClick={() => ordenar('id')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '49%' }}>
							<span>TITULO</span>
							<div className="arrows" onClick={() => ordenar('titulo')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '11%' }}>
							<span>DATA VENCIMENTO</span>
							<div className="arrows" onClick={() => ordenar('dataVencimento')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '5%' }}></div>
					</div>
					<div className="tabela-body">{data && data.content.map((mensagem) => <ItemListmensagem key={mensagem.id} mensagem={mensagem} />)}</div>
					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default MensagemHome;
