
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IMaterialApoioDTO } from '../../../../models/happy-code-api.model';
import { useMaterialApoioService } from '../../../../services/materiais.apoio.service';
import { useNotificationService } from '../../../../services/notification.service';

interface TItemLista {
    materialApoio: IMaterialApoioDTO;
}

const AcademyMaterialApoioList = memo(({ materialApoio }: TItemLista) => {
    const history = useHistory();
    const [ativo, setAtivo] = useState<boolean>();
    const materialApoioService = useMaterialApoioService(); 
    const notification = useNotificationService();
    const { setIsGlobalLoading } = useGlobalContext();
    const {idPasta} = useParams<any>();

    const goTo = () => {
            history.push(`/academy/gestao-pasta-material/${idPasta}/materiais-apoio/create/${materialApoio?.id}`)
    }

    useEffect(() => {
        setAtivo(materialApoio.ativo);
    }, [materialApoio.ativo])

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} a materialApoio?`,
            icon: <ExclamationCircleTwoTone rev={undefined} />,
            content: materialApoio?.descricao,
            okText: 'Sim',
            okType: `${ativo ? 'danger' : 'primary'}`,
            cancelText: 'Não',
            onOk: () =>
                materialApoioService.patch({ id: materialApoio.id, ativo: !ativo })
                    .then(() => {
                        setAtivo(!ativo)
                        notification({ description: 'Material de apoio alterado com sucesso!', type: 'success', message: 'Sucesso' });
                    })
                    .finally(() => setIsGlobalLoading(false)),
        });
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={showDeleteConfirm}>
                <a rel="noopener noreferrer">
                    Suspender/Ativar
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
            <div className="user" id="cursos" style={{ width: "30%" }}>
                <div className="nome">
                    <p>{materialApoio?.id}</p>
                    {ativo ? (<div>
                        <div className="estado-user">
                            <div className="dot-verde"></div>
                            <p className="ativo">Ativo</p>
                        </div>
                    </div>) : <div>
                        <div className="estado-user">
                            <div className="dot-vermelho"></div>
                            <p className="inativo">Inativo</p>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "16%", textAlign: "center" }}>
                <p>{materialApoio?.descricao}</p>
            </div>
            <div className="funcao-user" id="cursos-id" style={{ width: "20%" }}>
                <p>{materialApoio?.pasta?.nome}</p>
            </div>
            {/* <div className="funcao-user" id="cursos-id" style={{ width: "10%" }}>
                <p>{alterDescription()}</p>
            </div> */}
            <div className="funcao-user" id="cursos-id" style={{ width: "25%" }}>
            </div>
            <div className="registro-opcao" style={{ width: "5%" }}>
                <a id="editar" onClick={goTo}>
                    <Unicons.UilEdit size="20" color="#92A9CB" />
                </a>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
                </Dropdown>
            </div>
        </div>
    )
})

AcademyMaterialApoioList.displayName = 'AcademyMaterialApoioList';


export default AcademyMaterialApoioList;
