import { useHistory } from 'react-router-dom';
import { classMiniatureIcons } from '../../constants/class-miniature-icons';
import { useGlobalContext } from '../../context/GlobalContext';
import { useTurmaContext } from '../../context/TurmaContext';
import { IMatriculaDTO, ITurmaOfferDTO } from '../../models/happy-code-api.model';
import { useTurmaService } from '../../services/turma.service';
import { OffersChipType } from '../../types/offer/offers-chip.type';
import useMiniatura from './use-miniatura.hook';

interface MiniaturaProps {
	cor: string;
	variant: OffersChipType;
	turmaOffer: ITurmaOfferDTO;
	telaTurma: boolean;
	horario: string;
	diaSemana: string;
	idMiniatura: number;
	currentContractEnrollments: IMatriculaDTO[];
	otherEnrollmentsData: IMatriculaDTO[];
}

export const Miniatura = ({
	variant,
	cor,
	turmaOffer,
	telaTurma,
	horario,
	diaSemana,
	idMiniatura,
	currentContractEnrollments,
	otherEnrollmentsData,
}: MiniaturaProps) => {
	const { turmaSelectedClass, selectTurmaOnClick, disabled, turmaCinzaClass, isTurmaSelected } = useMiniatura({
		turmaOffer,
		variant,
		currentContractEnrollments,
		otherEnrollmentsData,
	});

	const { setIsGlobalLoading } = useGlobalContext();
	const { setTurma, editBtnIsVisible } = useTurmaContext();
	// const [isOpenCross, setIsOpenCross] = useState<boolean>(false);
	const turmaService = useTurmaService();
	const history = useHistory();
	const { curriculo } = turmaOffer.turma;
	// const { matricula} = useAlunoContext();
	// const [ofertaCross, setOfertaCross] = useState<ITurmaDTO[]>();
	// const [crossDto, setCrossDto] = useState<ITurmaCrossSellDTO>();

	// const crossSelling = () => {
	// 	setIsGlobalLoading(true);
	// 	turmaService
	// 		.findCrossSelling(crossDto)
	// 		.then((response) => {
	// 			//setOfertaCross(response.data);
	// 			const ofertacross = response.data
	// 				.map((element) => {
	// 					if (!matricula.turmas.some((el) => el.turma.id === element.id)) {
	// 						return element;
	// 					}
	// 					return undefined;
	// 				})
	// 				.filter((c) => c?.id);
	// 			// setOfertaCross(ofertacross);
	// 			// if (ofertacross.length > 0) {
	// 			// 	setIsOpenCross(true);
	// 			// }
	// 		})
	// 		.finally(() => setIsGlobalLoading(false));
	// };

	// useEffect(() => {
	// 	setCrossDto({
	// 		...crossDto,
	// 		idUnidade: unidade.id,
	// 		horaInicio: horario,
	// 		diaSemana: diaSemana,
	// 	});

	// 	return () =>
	// 		setCrossDto({
	// 			idUnidade: null,
	// 			horaInicio: null,
	// 			diaSemana: null,
	// 		});
	// }, []);

	const handleEditTurma = (turma) => {
		setIsGlobalLoading(true);
		turmaService
			.findFullById(turma)
			.then((response) => {
				setTurma(response.data);
				history.push('/turmas/create/step-2');
				editBtnIsVisible(true);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	// const closeCross = () => {
	// 	setIsOpenCross(false);
	// };

	return (
		<>
			<button
				disabled={disabled}
				onClick={() => (!telaTurma ? selectTurmaOnClick() : handleEditTurma(turmaOffer.turma))}
				style={{ backgroundColor: !isTurmaSelected ? cor : '#1A4DA1' }}
				className={`card__miniatura box-${turmaSelectedClass()}-miniatura d-flex-col-start-star box-space-grid-${idMiniatura} ${turmaCinzaClass}`}
			>
				<div className="d-flex-row-start-center w-100 gap-3">
					<img src={classMiniatureIcons[variant.replace('ô', 'o')]} alt="" />
					<p>{curriculo.descricaoCurriculo + ' (' + curriculo.faixaEtariaIni + ' - ' + curriculo.faixaEtariaFim + ')'}</p>
				</div>
				<div className="d-flex-row-between-center box-info w-100">
					<div className="box-sala">
						<p>{turmaOffer.turma.sala?.descricaoSala}</p>
					</div>
					<div className="box-vaga">
						<p>{turmaOffer?.turma.maxAlunos}</p>
					</div>
				</div>
			</button>

			{/* <ModalCreateCross
				title={'Oferta!'}
				textButton1="Voltar para tela inicial"
				textButton2="Novo aluno"
				styleButton1="button-line"
				open={isOpenCross}
				onClose={closeCross}
				crossSell={ofertaCross}
			/> */}
		</>
	);
};
