import { CloseOutlined } from '@mui/icons-material';
import { Button, Checkbox, Input, InputNumber, Radio, Select, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PresentImage } from '../../../../components/ApresentarImagem';
import ContadorCaracteres from '../../../../components/ContadorCaracteres';
import Header from '../../../../components/Header';
import Modal from '../../../../components/Modal';
import { useGlobalContext } from '../../../../context/GlobalContext';
import '../../../../global.css';
import { INegocioCleanDTO, ITipoUnidadeCleanDTO, ITrilhaDTO } from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { useAcademyArquivoService } from '../../../../services/academy-arquivo';
import { useAcademyTrilha } from '../../../../services/academy-trilha';
import { useNegocioService } from '../../../../services/negocio.service';
import { useNotificationService } from '../../../../services/notification.service';
import { useTipoUnidadeService } from '../../../../services/tipo-unidade.service';
import { validateFileExtension } from '../../../../util/util';
import './style.css';

const TrilhaCreate: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();
	const arquivoService = useAcademyArquivoService();
	const trilhaService = useAcademyTrilha();
	const negocioService = useNegocioService();
	const tipoUnidadeSerivice = useTipoUnidadeService();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const [trilha, setTrilha] = useState<ITrilhaDTO>();
	const [predicate] = useState<Predicate>(new Predicate());
	const { idTrilha } = useParams<any>();
	const [sendFileIcone, setSendFileIcone] = useState<File | null>(null);
	const [sendFileCapa, setSendFileCapa] = useState<File | null>(null);
	const [validaIcone, setValidaIcone] = useState<boolean>(false);
	const [validaCapa, setValidaCapa] = useState<boolean>(false);
	const [negocios, setNegocios] = useState<INegocioCleanDTO[]>([]);
	const [tipoUnidades, setTipoUnidades] = useState<ITipoUnidadeCleanDTO[]>([]);

	const findTrilhas = () => {
		setIsGlobalLoading(true);
		trilhaService
			.findById({ id: idTrilha })
			.then((response) => {
				setTrilha(response.data);
				setValidaIcone(response.data.icone ? true : false);
				setValidaCapa(response.data.capa ? true : false);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findNegocio = () => {
		setIsGlobalLoading(true);
		negocioService
			.findList(predicate)
			.then((response) => {
				setNegocios(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findTipoUnidade = () => {
		setIsGlobalLoading(true);
		tipoUnidadeSerivice
			.findList()
			.then((response) => {
				setTipoUnidades(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};
	const enviarArquivo = async () => {
		setIsGlobalLoading(true);
		// setarValores();

		const fetchTrilha = async () => {
			try {
				let updatedUploadTrilha = { ...trilha };

				if (sendFileIcone) {
					const { data } = await arquivoService.upload(sendFileIcone);
					updatedUploadTrilha = {
						...updatedUploadTrilha,
						arquivoIcone: {
							id: data.id,
							codigo: data.codigo,
							url: data.url,
							ativo: false,
						},
					};
				} else {
					updatedUploadTrilha = {
						...updatedUploadTrilha,
						arquivoIcone: null,
					};
				}

				if (sendFileCapa) {
					const { data } = await arquivoService.upload(sendFileCapa);
					updatedUploadTrilha = {
						...updatedUploadTrilha,
						arquivoCapa: {
							id: data.id,
							codigo: data.codigo,
							url: data.url,
							ativo: false,
						},
					};
				} else {
					updatedUploadTrilha = {
						...updatedUploadTrilha,
						arquivoCapa: null,
					};
				}

				if (!idTrilha) {
					await trilhaService.enviarArquivo(updatedUploadTrilha);
					history.push(`/academy/gestao-trilha/trilha`);
					notification({
						description: `Trilha cadastrado com sucesso, por gentileza atualizar a página.`,
						type: 'success',
						message: 'Sucesso!',
					});
				} else {
					await trilhaService.editarArquivoCustom(trilha.id, updatedUploadTrilha);
					history.push(`/academy/gestao-trilha/trilha`);
					notification({
						description: `Trilha alterado com sucesso, por gentileza atualizar a página.`,
						type: 'success',
						message: 'Sucesso!',
					});
				}
			} catch (error) {
				notification({
					description: `Desculpe, não foi possível editar a trilha.`,
					type: 'error',
					message: 'Erro!',
				});
			}
			setIsGlobalLoading(false);
		};
		fetchTrilha();
	};

	const submitValidationFields = () => {
		if (!trilha.descricao) {
			return notification({
				description: `O campo "Descrição" não pode ficar vazio.`,
				type: 'warning',
				message: 'Atenção!',
			});
		}
		if (!trilha?.acessoB2c && !trilha?.acessoB2b && !trilha?.acessoComercial) {
			document.getElementById('checkTipoAcesso')?.focus();
			return notification({
				description: `Verifique o campo "Visualização Academy". É necessário selecionar um tipo de visualização para o Academy.`,
				type: 'warning',
				message: 'Atenção!',
			});
		}

		if (trilha?.id && sendFileIcone && !validaIcone && validateFileExtension(sendFileIcone?.name)) {
			document.getElementById('arquivoIcone')?.focus();
			notification({
				description: `A extensão do arquivo selecionado para a "ícone" não é permitida. Por favor, selecione um arquivo com extensão válida (.jpg, .jpeg, .png, '.svg', .gif).`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}

		if (!trilha?.id && !validaIcone && validateFileExtension(sendFileIcone?.name)) {
			document.getElementById('arquivoIcone')?.focus();
			notification({
				description: `A extensão do arquivo selecionado para a "ícone" não é permitida. Por favor, selecione um arquivo com extensão válida (.jpg, .jpeg, .png, '.svg', .gif).`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}

		if (validaIcone && validateFileExtension(trilha?.icone)) {
			document.getElementById('linkIcone')?.focus();
			notification({
				description: `A extensão do link selecionado para a "ícone" não é permitida. Por favor, selecione um link com extensão válida (.jpg, .jpeg, .png, '.svg', .gif).`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}

		if (trilha?.id && sendFileCapa && !validaCapa && validateFileExtension(sendFileCapa?.name)) {
			document.getElementById('arquivoCapa')?.focus();
			notification({
				description: `A extensão do arquivo selecionado para a "capa" não é permitida. Por favor, selecione um arquivo com extensão válida (.jpg, .jpeg, .png, '.svg', .gif).`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}

		if (!trilha?.id && !validaCapa && validateFileExtension(sendFileCapa?.name)) {
			document.getElementById('arquivoCapa')?.focus();
			notification({
				description: `A extensão do arquivo selecionado para a "capa" não é permitida. Por favor, selecione um arquivo com extensão válida (.jpg, .jpeg, .png, '.svg', .gif).`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}

		if (validaCapa && validateFileExtension(trilha?.capa)) {
			document.getElementById('linkCapa')?.focus();
			notification({
				description: `A extensão do link selecionado para a "capa" não é permitida. Por favor, selecione um link com extensão válida (.jpg, .jpeg, .png, '.svg', .gif).`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!trilha?.nivel) {
			notification({
				description: `O campo "nivel da trilha" é obrigatório.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (trilha.avaliacao && !trilha.qtdQuestoes) {
			notification({
				description: `O campo "Quantidade de questões" é obrigatório quando o campo "Avaliação" estiver marcado.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		enviarArquivo();
	};

	useEffect(() => {
		Promise.all([findNegocio(), findTipoUnidade()]).catch((error) => console.error(error));
		setTrilha({
			...trilha,
			corNome1: '#000',
			corNome2: '#0C306C',
		});

		if (idTrilha) {
			findTrilhas();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const limparArquivoIcone = () => {
		setSendFileIcone(null);
		setTrilha({ ...trilha, arquivoIconeByte: null });
	};

	const limparArquivoCapa = () => {
		setSendFileCapa(null);
		setTrilha({ ...trilha, arquivoCapaByte: null });
	};

	return (
		<div className="container-fondo">
			<Header />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row">
					<div className="card-form">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira informações para cadastro da trilha
							</h3>
							<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
						</div>
						<div className="input-box">
							<label>Visualização Academy</label>
							<div style={{ marginBottom: 5 }}>
								<label style={{ marginRight: 10 }} htmlFor="checkTipoAcesso">
									B2C:
								</label>
								<Checkbox id="checkTipoAcesso" checked={trilha?.acessoB2c} onChange={(e) => setTrilha({ ...trilha, acessoB2c: e.target.checked })} />
							</div>
							<div style={{ marginBottom: 5 }}>
								<label style={{ marginRight: 10 }}>B2B:</label>
								<Checkbox checked={trilha?.acessoB2b} onChange={(e) => setTrilha({ ...trilha, acessoB2b: e.target.checked })} />
							</div>
							<div style={{ marginBottom: 5 }}>
								<label style={{ marginRight: 10 }}>Comercial:</label>
								<Checkbox checked={trilha?.acessoComercial} onChange={(e) => setTrilha({ ...trilha, acessoComercial: e.target.checked })} />
							</div>
						</div>
						<div className="input-box">
							<label>Trilha com Avaliação?</label>
							<Checkbox
								defaultChecked
								checked={trilha?.avaliacao}
								onChange={(e) => {
									if (e.target.checked) {
										setTrilha({ ...trilha, avaliacao: e.target.checked });
									}
									setTrilha({ ...trilha, avaliacao: e.target.checked, qtdQuestoes: null });
								}}
							/>
						</div>
						{trilha?.avaliacao && (
							<>
								<div className="input-box">
									<label>Quantidade de Questões</label>
									<InputNumber min={1} max={30} value={trilha?.qtdQuestoes} onChange={(value) => setTrilha({ ...trilha, qtdQuestoes: value })} />
								</div>
								<div className="input-box">
									<label>Porcentagem de acerto para aprovação</label>
									<div>
										<InputNumber min={1} max={100} value={trilha?.percentualAcertos} onChange={(value) => setTrilha({ ...trilha, percentualAcertos: value })} />
										<span>%</span>
									</div>
								</div>
							</>
						)}
						<div className="input-box">
							<label>Trilha com Certificado?</label>
							<Checkbox checked={trilha?.certificado} onChange={(e) => setTrilha({ ...trilha, certificado: e.target.checked })} />
						</div>

						<div className="input-box">
							<label>Mostrar Respostas?</label>
							<Checkbox checked={trilha?.mostrarRespostas} onChange={(e) => setTrilha({ ...trilha, mostrarRespostas: e.target.checked })} />
						</div>

						<div className="input-box">
							<label>Nível da Trilha</label>
							<Select
								style={{ width: '100%' }}
								value={trilha?.nivel}
								onChange={(value) => setTrilha({ ...trilha, nivel: value })}
								placeholder="Selecione o nível"
							>
								{['ASPIRANTE', 'EXPLORADOR', 'ESTELARES'].map((nivel) => (
									<Select.Option key={nivel} value={nivel}>
										{nivel}
									</Select.Option>
								))}
							</Select>
						</div>
						<div className="input-box">
							<label htmlFor="tipoUnidade">Tipo de unidade</label>
							<Select
								style={{ width: '100%' }}
								id="tipoUnidade"
								placeholder="Selecione"
								allowClear
								showSearch
								optionFilterProp="children"
								defaultValue={trilha?.tipoUnidade?.id}
								value={trilha?.tipoUnidade?.id}
								onChange={(e) => setTrilha({ ...trilha, tipoUnidade: { ...trilha.tipoUnidade, id: e } })}
								filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
							>
								{tipoUnidades &&
									tipoUnidades.map((tipoUnidade) => (
										<Select.Option key={tipoUnidade.id} value={tipoUnidade.id} text={tipoUnidade.descricao}>
											{tipoUnidade.descricao}
										</Select.Option>
									))}
							</Select>
						</div>
						<div className="input-box">
							<label htmlFor="negocio">Negócio</label>
							<Select
								style={{ width: '100%' }}
								id="negocio"
								placeholder="Selecione"
								allowClear
								showSearch
								optionFilterProp="children"
								defaultValue={trilha?.negocio?.id}
								value={trilha?.negocio?.id}
								onChange={(e) => setTrilha({ ...trilha, negocio: { ...trilha.negocio, id: e } })}
								filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
							>
								{negocios &&
									negocios.map((negocio) => (
										<Select.Option key={negocio.id} value={negocio.id} text={negocio.descricao}>
											{negocio.descricao}
										</Select.Option>
									))}
							</Select>
						</div>
						<div className="flex">
							<div className="input-box mr5 wp80">
								<label htmlFor="nome1">Título 1</label>
								<Input id="nome1" defaultValue={trilha?.nome1} value={trilha?.nome1} onChange={(e) => setTrilha({ ...trilha, nome1: e.target.value })} />
							</div>
							<div className="input-box wp20">
								<label htmlFor="colorNome1">Colorir</label>
								<Form.Control
									type="color"
									id="colorNome1"
									defaultValue={trilha?.corNome1}
									value={trilha?.corNome1}
									title="Choose your color"
									onChange={(e) => setTrilha({ ...trilha, corNome1: e.target.value })}
								/>
							</div>
						</div>
						<div className="flex">
							<div className="input-box mr5 wp80">
								<label htmlFor="nome2">Título 2</label>
								<Input id="nome2" defaultValue={trilha?.nome2} value={trilha?.nome2} onChange={(e) => setTrilha({ ...trilha, nome2: e.target.value })} />
							</div>
							<div className="input-box wp20">
								<label htmlFor="colorNome2">Colorir</label>
								<Form.Control
									type="color"
									id="colorNome2"
									defaultValue={trilha?.corNome2}
									value={trilha?.corNome2}
									title="Choose your color"
									onChange={(e) => setTrilha({ ...trilha, corNome2: e.target.value })}
								/>
							</div>
						</div>

						<div className="input-box">
							<label htmlFor="checkedIcone">Tipo do arquivo ícone</label>
							<Radio.Group defaultValue={validaIcone} value={validaIcone} onChange={(e) => setValidaIcone(e.target.value)}>
								<Space direction="vertical">
									<Radio value={false}>Arquivo</Radio>
									<Radio value={true} onClick={limparArquivoIcone}>
										Link
									</Radio>
								</Space>
							</Radio.Group>
						</div>

						{!validaIcone ? (
							<div className="input-box">
								<label htmlFor="arquivoIcone">Upload arquivo ícone</label>
								<Input
									id="arquivoIcone"
									type="file"
									onChange={(e) => {
										setSendFileIcone(e.target.files?.[0] || null);
										setTrilha({ ...trilha, icone: null });
									}}
								/>
							</div>
						) : (
							<div className="input-box">
								<label htmlFor="linkIcone">Informar link ícone</label>
								<Input
									id="linkIcone"
									defaultValue={trilha?.icone}
									value={trilha?.icone}
									onChange={(e) => setTrilha({ ...trilha, icone: e.target.value.trim() })}
								/>
							</div>
						)}

						<div className="flex space-between mb15">
							<div className="wp80 mr5">
								<h2>Título da trilha no academy</h2>
								<div className="input-box hp70 flex" style={{ flexDirection: 'initial', justifyContent: 'normal', alignItems: 'center' }}>
									<div className="mr10">
										<PresentImage
											file={sendFileIcone}
											link={trilha?.icone ? trilha?.icone : process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY + trilha?.arquivoIcone?.url}
										/>
									</div>
									<div>
										<h2 className="exemplo-title-academy">
											<span className="mr5" style={{ color: trilha?.corNome1 }}>
												{trilha?.nome1}
											</span>
											<span style={{ color: trilha?.corNome2 }}>{trilha?.nome2}</span>
										</h2>
									</div>
								</div>
							</div>

							<div className="wp20">
								<h2>Status do curso</h2>
								<div className="input-box hp70">
									<h2 className="exemplo-status-curso-academy" style={{ backgroundColor: trilha?.corNome2 }}>
										Curso
									</h2>
								</div>
							</div>
						</div>

						<div className="input-box">
							<label htmlFor="descricao">Descrição</label>
							<TextArea
								id="descricao"
								maxLength={400}
								defaultValue={trilha?.descricao}
								value={trilha?.descricao}
								onChange={(e) => setTrilha({ ...trilha, descricao: e.target.value })}
							/>
							<ContadorCaracteres contador={trilha?.descricao?.length} limite={400} />
						</div>

						<div className="input-box">
							<label htmlFor="checkedCapa">Tipo do arquivo Capa</label>
							<Radio.Group value={validaCapa} defaultValue={validaCapa} onChange={(e) => setValidaCapa(e.target.value)}>
								<Space direction="vertical">
									<Radio value={false} id="checkedCapa">
										Arquivo
									</Radio>
									<Radio value={true} onClick={limparArquivoCapa}>
										Link
									</Radio>
								</Space>
							</Radio.Group>
						</div>

						{!validaCapa ? (
							<div className="input-box">
								<label htmlFor="arquivoCapa">Upload arquivo capa</label>
								<Input
									id="arquivoCapa"
									type="file"
									onChange={(e) => {
										setSendFileCapa(e.target.files?.[0] || null);
										setTrilha({ ...trilha, capa: null });
									}}
								/>
							</div>
						) : (
							<div className="input-box">
								<label htmlFor="linkCapa">Informar link capa</label>
								<Input id="linkCapa" defaultValue={trilha?.capa} value={trilha?.capa} onChange={(e) => setTrilha({ ...trilha, capa: e.target.value.trim() })} />
							</div>
						)}

						<PresentImage
							file={sendFileCapa}
							link={trilha?.capa ? trilha?.capa : process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_CONTAINER_ACADEMY + trilha?.arquivoCapa?.url}
							size={'100%'}
						/>

						<div className="botoes" style={{ marginTop: 10 }}>
							<Link to={`/academy/gestao-trilha/trilha`}>
								<Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
									Voltar
								</Button>
							</Link>
							<Button style={{ width: 345 }} type="primary" id="proximo" className="button-primary" onClick={submitValidationFields}>
								Salvar
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/academy/gestao-trilha/trilha`}
			/>
		</div>
	);
};

export default TrilhaCreate;
