import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';
import { ILogCleanDTO, IPushNotificacaoDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useLearnPushService } from '../../services/learn-push';
import { useLogService } from '../../services/log.service';
import FiltrosLearnPush from './filtros-learn-push';
import ItemLearnPush from './item-lista';

export const LearnPush: React.FunctionComponent = () => {
	const [data, setData] = useState<PageableResponse<IPushNotificacaoDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
	const learnPushService = useLearnPushService();
	const { setIsGlobalLoading, unidade } = useGlobalContext();

	const history = useHistory();
	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso Learn Push',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	useEffect(() => {
		logService.registraLog(log);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const findLearnPush = () => {
		setIsGlobalLoading(true);
		predicate.addOption('ativo', true)
		return learnPushService.findPush(predicate, unidade?.id)
			.then(({ data }) => {
				setData(data);
			}).finally(() => setIsGlobalLoading(false))
	}

	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findLearnPush();
	};

	const ordenar = (campo) => {
		predicate.addSort(campo);
		findLearnPush();
	};

	return (
		<div className="container">
			<NewMenu selecionado={215} />
			<div className="dashboard" style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}>
				<div className="box-title">
					<h3 className="title">Learn Push ({data?.totalElements ? data?.totalElements : 0})</h3>
					<Button className="button-primary" id="criar-c" icon={<PlusOutlined rev={undefined} />} onClick={() => history.push('push-learn/create')}>
						Novo Push
					</Button>
				</div>
				<FiltrosLearnPush findLearnPush={findLearnPush} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '20%' }}>
							<span>IMG</span>
						</div>
						<div className="item" style={{ width: '15%' }}>
							<span>ID</span>
							<div className="arrows" onClick={() => ordenar('id')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '15%' }}>
							<span>TÍTULO</span>
							<div className="arrows" onClick={() => ordenar('titulo')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '15%' }}>
							<span>SUBTÍTULO</span>
							<div className="arrows" onClick={() => ordenar('subTitulo')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '15%' }}>
							<span>MENSAGEM</span>
							<div className="arrows" onClick={() => ordenar('mensagem')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '15%' }}>
							<span>SEGMENTO</span>
							<div className="arrows" onClick={() => ordenar('segmentacao.id')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '10%' }} />
					</div>
					<div className="tabela-body">
						{data && data.content.map((learnPush) =>
							<ItemLearnPush key={learnPush.id} learnPush={learnPush} />
						)}
					</div>
					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
