import useCheckIfPageIsUpgrade from '../../../../../globalHooks/matricula/use-check-if-page-is-upgrade.hook';
import { useAlunoContext } from '../../../../../context/AlunoContext';
import {
	IHoraUnidadeCleanDTO,
	ITurmaOfferDTO,
} from '../../../../../models/happy-code-api.model';

interface UseEncontraMaximoDescontoCapitalProps {
	ofertaData: IHoraUnidadeCleanDTO;
}

export default function useEncontraMaximoDescontoCapital({
	ofertaData,
}: UseEncontraMaximoDescontoCapitalProps) {
	const { isUpgradePage } = useCheckIfPageIsUpgrade();

	const { matricula } = useAlunoContext();

	const calcularMaximoDescontoCapitalMatriculas = (matriculaData: ITurmaOfferDTO[]) => {
		const curta = matriculaData?.filter((tipo) => tipo?.turma?.curriculoNegocioCodigo === 'CUR');
		const colonia = matriculaData?.filter((tipo) => tipo?.turma?.curriculoNegocioCodigo === 'COL');
		const escola = matriculaData.filter((tipo) => tipo?.turma?.curriculoNegocioCodigo === "HES");

		const duracoesCurta = curta.map(
			(duracao) => duracao.turma.curriculo.duracaoTotal
		);

		const totalDuracaoCurta = duracoesCurta.reduce(
			(acc, duracao) => acc + duracao,
			0
		);

		const curriculo = matriculaData?.filter(
			(tipo) =>
				tipo?.turma?.curriculoNegocioCodigo !== "CUR" &&
				tipo?.turma?.curriculoNegocioCodigo !== "COL" &&
				tipo?.turma?.curriculoNegocioCodigo !== "HES"
		);

		const duracoes = curriculo.map(
			(duracao) => duracao.turma.curriculo.duracaoTotal
		);

		const totalDuracao = duracoes.reduce((acc, duracao) => acc + duracao, 0);

		let normalEnrollmentValue = 0;

		if (curta.length > 0) {
			if (totalDuracaoCurta === 90) {
				normalEnrollmentValue +=
					curta.length * ofertaData?.splitCurtaUmHoraEMeia;
			}
			if (totalDuracaoCurta === 180) {
				normalEnrollmentValue += curta.length * ofertaData?.splitCurtaTresHoras;
			}
			if (totalDuracaoCurta === 270) {
				normalEnrollmentValue +=
					curta.length * ofertaData?.splitCurtaQuatroHorasEMeia;
			}
			if (totalDuracaoCurta >= 360) {
				normalEnrollmentValue += curta.length * ofertaData?.splitCurtaSeisHoras;
			}
		}

		if (colonia.length > 0) {
			normalEnrollmentValue += colonia.length * ofertaData?.splitColoniaCapital;
		}

		if (escola.length > 0) {
			normalEnrollmentValue += escola.length * ofertaData?.splitEscolaCapital;
		}

		if (totalDuracao === 90) {
			normalEnrollmentValue += ofertaData?.splitUmHoraEMeiaCapital;
		}

		if (totalDuracao === 180) {
			normalEnrollmentValue += ofertaData?.splitTresHorasCapital;
		}

		if (totalDuracao === 270) {
			normalEnrollmentValue += ofertaData?.splitQuatroHorasEMeiaCapital;
		}

		if (totalDuracao === 360) {
			normalEnrollmentValue += ofertaData?.splitSeisHorasCapital;
		}

		if (totalDuracao > 360) {
			const horasExcedentes = totalDuracao - 360;
			normalEnrollmentValue += ofertaData?.splitSeisHorasCapital + ((horasExcedentes / 90) * ofertaData?.splitAdicionalCapital);
		}

		return normalEnrollmentValue;
	};

	const encontraMaximoDescontoCapital = () => {
		if (isUpgradePage) {
			const duracoes = matricula.turmas.map(
				(duracao) => duracao.turma.curriculo.duracaoTotal
			);
			const totalDuracao = duracoes.reduce((acc, duracao) => acc + duracao, 0);

			let upgradeValue = 0;

			if (totalDuracao === 90)
				upgradeValue +=
					ofertaData?.splitUmHoraEMeiaCapital -
					ofertaData?.splitUpgradeUmaHoraEMeiaCapital;

			if (totalDuracao === 180)
				upgradeValue +=
					ofertaData?.splitTresHorasCapital -
					ofertaData?.splitUpgradeTresHorasCapital;

			if (totalDuracao === 270)
				upgradeValue +=
					ofertaData?.splitQuatroHorasEMeiaCapital -
					ofertaData?.splitUpgradeQuatroHorasEMeiaCapital;

			if (totalDuracao === 360)
				upgradeValue +=
					ofertaData?.splitSeisHorasCapital -
					ofertaData?.splitUpgradeSeisHorasCapital;
					
			if (totalDuracao > 360) {
				const horasExcedentes = totalDuracao - 360;
				const upgradeValueSplitSeisHoras = ofertaData?.splitSeisHorasCapital - ofertaData?.splitUpgradeSeisHorasCapital
				upgradeValue += upgradeValueSplitSeisHoras + ((horasExcedentes / 90) * ofertaData?.splitAdicionalUpgrade);
			}

			const normalEnrollmentValue = calcularMaximoDescontoCapitalMatriculas(
				matricula.turmas
			);

			return normalEnrollmentValue + upgradeValue;
		}

		return calcularMaximoDescontoCapitalMatriculas(matricula.turmas);
	};

	return {
		encontraMaximoDescontoCapital,
	};
}
