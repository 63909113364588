import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, InputNumber, Progress, Select, Steps } from 'antd';
import 'antd/dist/antd.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import { WEEK_DAYS_ACRONYMS } from '../../constants/week-days-acronyms.constant';
import { useTurmaContext } from '../../context/TurmaContext';
import {
	ICurriculoCleanDTO,
	IDiaSemana,
	INegocioUnidadeDTO,
	ISalaCleanDTO,
	IUnidadePessoaCleanDTO
} from './../../models/happy-code-api.model';
import AddCurriculumHours from "./components/AddCurriculumHours";
import useClassPeriod from './hooks/use-class-period.hook';
import { useFindData } from './hooks/use-find-data.hook';
import useGridFirstTime from './hooks/use-grid-first-time.hook';
import useTime from './hooks/use-time.hook';
import './style.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const TurmasCreateStep1: React.FunctionComponent = () => {
	const { turma, setTurma, editBtnIsVisible } = useTurmaContext();
	const { Step } = Steps;
	const [isOpen, setIsOpen] = useState(false);
	const [novaHora, setNovaHora] = useState<string>();
	const [dia, setDia] = useState<IDiaSemana>();
	const { listProfessor, listNegocios, listCurriculos, listSalas, negocioSelected } = useFindData();
	const { onConfirmarHorario, shouldShowAddCurriculumHours, setAdicionarHorario, adicionarHorario } = useTime({ negocioSelected });
	const { getPeriodo, atualizarPeriodo } = useClassPeriod({ negocioSelected });
	const grade = useGridFirstTime();

	return (
		<div className="container-fondo">
			<Header />
			<div className="progress">
				<Progress className="progress-user" percent={70} strokeLinecap="square" showInfo={false} />
			</div>
			<div className="menu-user">
				<h3>Criação de turma</h3>
				<Steps direction="vertical" current={0}>
					<Step title="Dados Básicos" />
					<Step title="Revisão" />
				</Steps>
			</div>
			<div className="franquia">
				<div className="user-row" id="row-aluno">
					<h3>Dados básicos de turma</h3>
					<div className="input-box">
						<p>Nome da Turma <span id="ob">*</span></p>
						<Input
							placeholder="Nome turma"
							value={turma?.codigo}
							maxLength={48}
							onChange={(e) =>
								setTurma({
									...turma,
									codigo: e.target.value,
									descricao: e.target.value,
								})
							}
						/>
					</div>
					<div className="input-box">
						<p>Máximo de alunos na turma<span id="ob">*</span></p>
						<InputNumber
							className="w-100"
							min={0}
							defaultValue={turma.maxAlunos}
							value={turma?.maxAlunos}
							onChange={(e) => setTurma({ ...turma, maxAlunos: e })}
							placeholder="Máximo de alunos na turma"
						/>
					</div>

					<div className="input-box" id="box-aluno">
						<p>Produto<span id="ob">*</span></p>
						<Select
							className="w-100"
							placeholder="Selecione o produto"
							allowClear
							showSearch
							optionFilterProp="children"
							defaultValue={turma?.curriculoNegocioId}
							value={turma?.curriculoNegocioId}
							onChange={(e) => {
								setTurma({ ...turma, curriculoNegocioId: e }),
									atualizarPeriodo()
							}}
							filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
						>
							{listNegocios?.map((negocioUnidade: INegocioUnidadeDTO) => (
								<Option key={negocioUnidade.negocio.id} value={negocioUnidade.negocio.id} text={negocioUnidade.negocio.descricao}>
									{negocioUnidade.negocio.descricao}
								</Option>
							))}
						</Select>
					</div>

					<div className="input-box" id="box-aluno">
						<p>Curriculo<span id="ob">*</span></p>
						<Select
							className="w-100"
							placeholder="Selecione um curriculo"
							allowClear
							showSearch
							optionFilterProp="children"
							defaultValue={turma.curriculo?.id}
							value={turma.curriculo?.id}
							onChange={(e) => {
								setTurma({ ...turma, curriculo: { ...turma.curriculo, id: e } }),
									getPeriodo();
							}}
							filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
						>
							{listCurriculos &&
								listCurriculos?.map((curriculo: ICurriculoCleanDTO) => (
									<Option key={curriculo?.id} value={curriculo?.id} text={curriculo?.descricaoCurriculo}>
										{curriculo?.descricaoCurriculo}
									</Option>
								))}
						</Select>
					</div>

					<div className="input-box" id="box-aluno">
						<p>Período<span id="ob">*</span></p>
						<RangePicker
							className="w-100"
							value={getPeriodo()}
							defaultValue={getPeriodo()}
							onChange={(values) => {
								if (values) {
									setTurma({
										...turma,
										dataInicio: values[0].toDate(),
										dataFim: values[1].toDate(),
									});
								}
							}}
							format={'DD-MM-YYYY'}
						/>
					</div>

					<div className="inputs-box">
						<div className="input-box" id="box-aluno">
							<p>Sala<span id="ob">*</span></p>
							<Select
								className="w-100"
								placeholder="Selecione uma sala"
								allowClear
								showSearch
								optionFilterProp="children"
								defaultValue={turma?.sala?.id}
								value={turma?.sala?.id}
								onChange={(e) => setTurma({ ...turma, sala: { ...turma.sala, id: e } })}
								filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
							>
								{listSalas &&
									listSalas?.map((sala: ISalaCleanDTO) => (
										<Option key={sala.id} value={sala.id} text={sala.descricaoSala}>
											{sala.descricaoSala}
										</Option>
									))}
							</Select>
						</div>
						<div className="input-box" id="box-aluno">
							<p>Instrutor<span id="ob">*</span></p>
							<Select
								className="w-100"
								placeholder="Selecione uma sala"
								allowClear
								showSearch
								optionFilterProp="children"
								defaultValue={turma?.unidadePessoa?.id}
								value={turma?.unidadePessoa?.id}
								onChange={(e) =>
									setTurma({
										...turma,
										unidadePessoa: { ...turma.unidadePessoa, id: e },
									})
								}
								filterOption={(input, option) => option?.text?.toLowerCase().includes(input.toLowerCase())}
							>
								{listProfessor &&
									listProfessor?.map((prof: IUnidadePessoaCleanDTO) => (
										<Option key={prof.id} value={prof.id} text={prof.pessoaNome}>
											{prof.pessoaNome}
										</Option>
									))}
							</Select>
						</div>
					</div>

					<div className="input-box">
						<p>Horário<span id="ob">*</span></p>
						{turma.horarios && turma.horarios.map((hora, index) => (
							<p key={index}>{hora.codigo + ' - ' + hora.horaInicio + ' às ' + hora.horaFim}</p>
						))}
						{adicionarHorario === true && (
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<Select onChange={(e) => setDia(e as IDiaSemana)} placeholder="Selecione o dia">
									{WEEK_DAYS_ACRONYMS.map((dia) => (
										<Select.Option key={dia} value={dia}>
											{dia}
										</Select.Option>
									))}
								</Select>

								<Select onChange={(e) => setNovaHora(e.toString())} placeholder="Selecione a hora">
									{grade?.filter((g) => g.horaInicial !== null)
										.map((h, index) => (
											<Select.Option key={index} value={h.horaInicial + '-' + h.horaFinal}>
												{h.horaInicial + ' às ' + h.horaFinal}
											</Select.Option>
										))}
								</Select>
								<Button onClick={() => onConfirmarHorario(novaHora, dia)}>Confirmar</Button>
							</div>
						)}
						{shouldShowAddCurriculumHours() && (
							<AddCurriculumHours onClick={() => setAdicionarHorario(true)} />
						)}
					</div>

					<div className="botoes">
						<Link to="/turmas">
							<Button className="button-second" onClick={() => editBtnIsVisible(false)} style={{ width: 345 }}>
								Cancelar
							</Button>
						</Link>
						<div>
							<Link to="/turmas/create/step-2">
								<Button className="button-primary" style={{ width: 345 }}>
									Proximo
								</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>
			<Button onClick={() => setIsOpen(true)} className="button-fechar" icon={<CloseOutlined rev={undefined} />} />
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a adição desta Nova turma?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2="/turmas"
			/>
		</div>
	);
};
