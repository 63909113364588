import * as Unicons from '@iconscout/react-unicons';
import { Button } from "antd";

interface Props {
    onClick: () => void;
}

const AddCurriculumHours: React.FunctionComponent<Props> = ({ onClick }: Props) => {
    return (
        <div onClick={onClick}>
            <Button style={{ display: 'flex', flexDirection: 'row' }}>
                <Unicons.UilPlusCircle size="20" color="#1A4DA1" />
                {'Adicionar horário'}
            </Button>
        </div>
    );
}

export default AddCurriculumHours;