import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import '../../../../global.css';
import { IPastaCleanDTO } from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { PageableResponse } from '../../../../models/response.model';
import { usePastaService } from '../../../../services/pasta.service';
import FiltrosPastas from './filtros-pasta';
import Pagination from '@material-ui/lab/Pagination';
import AcademyPastaMaterialApoioItem from './item-lista';

export default function AcademyPastaMaterialApoio() {
	const [predicate] = useState<Predicate>(new Predicate());
	const [data, setData] = useState<PageableResponse<IPastaCleanDTO>>();
	const pastaService = usePastaService();
	const history = useHistory();
	const { setIsGlobalLoading } = useGlobalContext();

	const findPasta = () => {
		setIsGlobalLoading(true);
		pastaService
			.findClean(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};
	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findPasta();
	};

	const ordenar = (campo: string) => {
		predicate.addSort(campo);
		findPasta();
	};

	return (
		<div className="container">
			<NewMenu selecionado={37} />
			<div className="dashboard">
				<div className="box-title">
					<h3 className="title">Material de Apoio ({data?.totalElements || 0} pastas)</h3>
					<Button
						className="button-primary"
						id="criar-curso"
						icon={<PlusOutlined rev={undefined} />}
						onClick={() => history.push('/academy/gestao-pasta-material/create')}
					>
						Criar Pasta
					</Button>
				</div>
				<FiltrosPastas findPasta={findPasta} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '19%' }}>
							<span>ID</span>
							<div className="arrows" onClick={() => ordenar('id')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '31%' }}>
							<span>PASTA</span>
							<div className="arrows" onClick={() => ordenar('nome')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>

						<div className="item" style={{ width: '10%' }}>
							<span>VISUALIZAÇÃO</span>
						</div>

						<div className="item" style={{ width: '25%' }}></div>
					</div>

					<div className="tabela-body">{data && data.content.map((pasta, index) => <AcademyPastaMaterialApoioItem pasta={pasta} key={index} />)}</div>

					<div className="paginacao">
						<Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data?.pageable?.pageNumber + 1 : 0} count={data ? data?.totalPages : 0} />
					</div>
				</div>
			</div>
		</div>
	);
}
