import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Modal from '../../../../components/Modal';
import Header from '../../../../components/Header';
import { usePastaService } from '../../../../services/pasta.service';
import { IPastaCleanDTO } from '../../../../models/happy-code-api.model';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { useNotificationService } from '../../../../services/notification.service';

export default function AcademyPastaMaterialApoioCreate() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [pasta, setPasta] = useState<IPastaCleanDTO>();
	const { idPasta } = useParams<any>();
	const pastaService = usePastaService();
	const history = useHistory();
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();

	const useFindPasta = () => {
		setIsGlobalLoading(true);
		pastaService
			.findById({ id: idPasta })
			.then(({ data }) => setPasta(data))
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		if (idPasta) useFindPasta();
	}, []);

	const patchPasta = () => {
		setIsGlobalLoading(true);
		pastaService
			.patch(pasta)
			.then(() => {
				notification({
					description: `Pasta editada com sucesso!`,
					type: 'success',
					message: 'Sucesso',
				});
				history.push("/academy/gestao-pasta-material");
			})
			.finally(() => setIsGlobalLoading(false));
	};
	const createPasta = () => {
		if (idPasta) return patchPasta();
		setIsGlobalLoading(true);
		pastaService
			.createClean(pasta)
			.then(() => {
				notification({
					description: `Pasta cadastrada com sucesso!`,
					type: 'success',
					message: 'Sucesso',
				});
				history.push("/academy/gestao-pasta-material");
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const submitValidationFields = (e) => {
		e.preventDefault();
		if (!pasta.acessoB2b && !pasta.acessoB2c) {
			notification({
				description: `Por favor, selecione pelo menos um acesso!`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}

		createPasta();
	};

	return (
		<div className="container-fondo">
			<Header />
			<form onSubmit={(e) => submitValidationFields(e)}>
				<div className="franquia" style={{ left: 0 }}>
					<div className="user-row">
						<div className="card-form">
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
									Insira informações para {idPasta ? 'edição' : 'cadastro'} de pasta
								</h3>
								<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined rev={undefined} />} />
							</div>
							<div className="input-box">
								<label htmlFor="nomePasta"> Nome da Pasta</label>
								<Input
									id="nomePasta"
									maxLength={40}
									placeholder="Ex.: Pasta"
									value={pasta && pasta.nome}
									defaultValue={pasta && pasta.nome}
									onChange={(e) => setPasta({ ...pasta, nome: e.target.value })}
								/>
							</div>

							<div className="input-box">
								<label>Visualização Academy</label>
								<div style={{ marginBottom: 5 }}>
									<label style={{ marginRight: 10 }} htmlFor="checkTipoAcesso">
										B2C:
									</label>
									<Checkbox id="checkTipoAcesso" checked={pasta?.acessoB2c} onChange={(e) => setPasta({ ...pasta, acessoB2c: e.target.checked })} />
								</div>
								<div style={{ marginBottom: 5 }}>
									<label style={{ marginRight: 10 }}>B2B:</label>
									<Checkbox checked={pasta?.acessoB2b} onChange={(e) => setPasta({ ...pasta, acessoB2b: e.target.checked })} />
								</div>
							</div>
							<div className="botoes" style={{ marginTop: 10 }}>
								<Link to={`/academy/gestao-pasta-material`}>
									<Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
										Voltar
									</Button>
								</Link>
								<Button style={{ width: 345 }} type="primary" htmlType="submit" id="proximo" className="button-primary">
									Salvar
								</Button>
							</div>
						</div>
					</div>
				</div>
			</form>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/academy/gestao-pasta-material`}
			/>
		</div>
	);
}
