import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from "../../components/NewMenu";
import { useGlobalContext } from '../../context/GlobalContext';
import '../../global.css';
import { IHoraSedeDTO, IHoraSedeFullDTO, ILogCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useHoraSedeService } from '../../services/hora-sede.service';
import { useLogService } from '../../services/log.service';
import './style.css';
import FiltroHoraSede from './filtros-hora-sede';
import ItemListHoraSede from './item-lista';
import { useHoraSedeContext } from '../../context/HoraSedeContext';

const HoraSede: React.FunctionComponent = () => {

  const { removeHoraSede, setRevisao } = useHoraSedeContext();
  const [data, setData] = useState<PageableResponse<IHoraSedeFullDTO>>();
  const [predicate] = useState<Predicate>(new Predicate());
  const horaSedeService = useHoraSedeService();
  const { setIsGlobalLoading } = useGlobalContext();

  const history = useHistory();

  const logService = useLogService();
  const [log] = useState<ILogCleanDTO>({
    descricaoEvento: "Acesso negocios",
    descricaoTipo: "Acesso a tela",
    idTipo: null,
    id: null,
    descricaoUsuario: null,
    ativo: true
  });

  useEffect(() => {
    removeHoraSede();
    setRevisao(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    logService.registraLog(log)
  }, [])

  const findHoraSede = () => {
    setIsGlobalLoading(true);
    horaSedeService.findFull(predicate)
      .then(({ data }) => setData(data))
      .finally(() => setIsGlobalLoading(false));
  }

  const onPageChange = (event: ChangeEvent<any>, page: number) => {
    event.preventDefault();
    predicate.setPage(page);
    findHoraSede();
  }

  const goToAdd = () => {
    history.push("/hora/create/step-1");
  }

  const ordenar = (campo) => {
    predicate.addSort(campo);
    findHoraSede();
  }

  const handleCampanha = (hora: IHoraSedeFullDTO, state: boolean) => {
    hora.ativo = state;
    setIsGlobalLoading(true);
    horaSedeService.patch(hora)
      .then(() => findHoraSede())
      .finally(() => setIsGlobalLoading(false));
  }

  return (
    <div className="container">
      <NewMenu selecionado={97} />
      <div className="dashboard">
        <div className="box-title">
          <h3 className="title">Registros ({data?.totalElements})</h3>
          <Button className="button-primary" id="criar-campanha" icon={<PlusOutlined rev={undefined}/>} onClick={goToAdd}>Criar Configuração</Button>
        </div>
        <FiltroHoraSede findHoraSede={findHoraSede} predicate={predicate} />
        <div className="tabela">
          <div className="titles-users">            
            <div className="item" style={{ width: '10%' }}>
              <span>ID</span>
              <div className="arrows" onClick={() => ordenar('id')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>  
            <div className="item" style={{ width: '10%' }}>
              <span>Unidades</span>
              <div className="arrows" onClick={() => ordenar('id')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div> 

            <div className="item" style={{ width: '10%' }}>
              <span>SPLIT 1:30</span>
              <div className="arrows" onClick={() => ordenar('splitUmHoraEMeia')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '10%' }}>
              <span>SPLIT 3:00</span>
              <div className="arrows" onClick={() => ordenar('splitTresHoras')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '10%' }}>
              <span>SPLIT 4:30</span>
              <div className="arrows" onClick={() => ordenar('splitQuatroHorasEMeia')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '10%' }}>
              <span>SPLIT 6:00</span>
              <div className="arrows" onClick={() => ordenar('splitSeisHoras')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>

            <div className="item" style={{ width: '15%' }}>
              <span>SPLIT CAPITAL 1:30</span>
              <div className="arrows" onClick={() => ordenar('splitUmHoraEMeiaCapital')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '15%' }}>
              <span>SPLIT CAPITAL  3:00</span>
              <div className="arrows" onClick={() => ordenar('splitTresHorasCapital')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '15%' }}>
              <span>SPLIT CAPITAL  4:30</span>
              <div className="arrows" onClick={() => ordenar('splitQuatroHorasEMeiaCapital')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>
            <div className="item" style={{ width: '15%' }}>
              <span>SPLIT CAPITAL  6:00</span>
              <div className="arrows" onClick={() => ordenar('splitSeisHorasCapital')}>
                <Unicons.UilDirection size="20" color="#92A9CB" />
              </div>
            </div>

            <div className="item" style={{ width: '5%' }}/>
          </div>
          <div className="tabela-body">
            {data && data.content.map((hora, index) => (
              <ItemListHoraSede handleCampanha={(hora, state) => handleCampanha(hora, state)} hora={hora} key={index} />
            ))}
          </div>
          <div className="paginacao">
            <Pagination onChange={(event, page) => onPageChange(event, page - 1)} page={data ? data.pageable.pageNumber + 1 : 0} count={data ? data.totalPages : 0} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoraSede;