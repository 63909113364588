import { useAlunoContext } from '../../../../../context/AlunoContext';
import {
	IHoraUnidadeCleanDTO,
	ITurmaOfferDTO,
} from '../../../../../models/happy-code-api.model';
import useCheckIfPageIsUpgrade from '../../../../../globalHooks/matricula/use-check-if-page-is-upgrade.hook';

interface UseEncontraMaximoDescontoProps {
	ofertaData: IHoraUnidadeCleanDTO;
}

export default function useEncontraMaximoDesconto({
	ofertaData,
}: UseEncontraMaximoDescontoProps) {
	const { isUpgradePage } = useCheckIfPageIsUpgrade();

	const { matricula } = useAlunoContext();

	const calcularMaximoDescontoMatriculas = (matriculaData: ITurmaOfferDTO[]) => {
		const curta = matriculaData?.filter((tipo) => tipo?.turma?.curriculoNegocioCodigo === 'CUR');
		const colonia = matriculaData?.filter((tipo) => tipo?.turma?.curriculoNegocioCodigo === 'COL');
		const escola = matriculaData.filter((tipo) => tipo?.turma?.curriculoNegocioCodigo === "HES");

		const curriculo = matriculaData?.filter(
			(tipo) =>
				tipo?.turma?.curriculoNegocioCodigo !== "CUR" &&
				tipo?.turma?.curriculoNegocioCodigo !== "COL" &&
				tipo?.turma?.curriculoNegocioCodigo !== "HES"
		);

		const duracoesCurta = curta.map(
			(duracao) => duracao.turma.curriculo.duracaoTotal
		);

		const totalDuracaoCurta = duracoesCurta.reduce(
			(acc, duracao) => acc + duracao,
			0
		);

		const duracoes = curriculo.map(
			(duracao) => duracao.turma.curriculo.duracaoTotal
		);

		const totalDuracao = duracoes.reduce((acc, duracao) => acc + duracao, 0);

		let normalEnrollmentValue = 0;

		if (curta.length > 0) {
			if (totalDuracaoCurta === 90) {
				normalEnrollmentValue +=
					curta.length * ofertaData?.splitCurtaUmHoraEMeia;
			}
			if (totalDuracaoCurta === 180) {
				normalEnrollmentValue += curta.length * ofertaData?.splitCurtaTresHoras;
			}
			if (totalDuracaoCurta === 270) {
				normalEnrollmentValue +=
					curta.length * ofertaData?.splitCurtaQuatroHorasEMeia;
			}
			if (totalDuracaoCurta >= 360) {
				normalEnrollmentValue += curta.length * ofertaData?.splitCurtaSeisHoras;
			}
		}

		if (colonia.length > 0) {
			normalEnrollmentValue += colonia.length * ofertaData?.splitColonia;
		}

		if (escola.length > 0) {
			normalEnrollmentValue += escola.length * ofertaData?.splitEscola;
		}

		if (totalDuracao === 90) {
			normalEnrollmentValue += ofertaData?.splitUmHoraEMeia;
		}

		if (totalDuracao === 180) {
			normalEnrollmentValue += ofertaData?.splitTresHoras;
		}

		if (totalDuracao === 270) {
			normalEnrollmentValue += ofertaData?.splitQuatroHorasEMeia;
		}

		if (totalDuracao === 360) {
			normalEnrollmentValue += ofertaData?.splitSeisHoras;
		}

		if (totalDuracao > 360) {
			const horasExcedentes = totalDuracao - 360;
			normalEnrollmentValue += ofertaData?.splitSeisHoras + ((horasExcedentes / 90) * ofertaData?.splitAdicional);
		}

		return normalEnrollmentValue;
	};

	const encontraMaximoDesconto = () => {
		if (isUpgradePage) {
			const duracoes = matricula.turmas.map(
				(duracao) => duracao.turma.curriculo.duracaoTotal
			);
			const totalDuracao = duracoes.reduce((acc, duracao) => acc + duracao, 0);

			let upgradeValue = 0;

			if (totalDuracao === 90)
				upgradeValue +=
					ofertaData?.splitUmHoraEMeia - ofertaData?.splitUpgradeUmaHoraEMeia;

			if (totalDuracao === 180)
				upgradeValue +=
					ofertaData?.splitTresHoras - ofertaData?.splitUpgradeTresHoras;

			if (totalDuracao === 270)
				upgradeValue += ofertaData?.splitQuatroHorasEMeia - ofertaData?.splitUpgradeQuatroHorasEMeia;

			if (totalDuracao === 360)
				upgradeValue += ofertaData?.splitSeisHoras - ofertaData?.splitUpgradeSeisHoras;

			if (totalDuracao > 360) {
				const horasExcedentes = totalDuracao - 360;
				const upgradeValueSplitSeisHoras = ofertaData?.splitSeisHoras - ofertaData?.splitUpgradeSeisHoras
				upgradeValue += upgradeValueSplitSeisHoras + ((horasExcedentes / 90) * ofertaData?.splitAdicionalUpgrade);
			}

			const normalEnrollmentValue = calcularMaximoDescontoMatriculas(
				matricula.turmas
			);

			return normalEnrollmentValue + upgradeValue;
		}

		return calcularMaximoDescontoMatriculas(matricula.turmas);
	};

	return {
		encontraMaximoDesconto,
	};
}
