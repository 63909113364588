import { differenceInDays, parseISO } from "date-fns";

const feriadosNacionais = [
    { dia: 1, mes: 1 },
    { dia: 21, mes: 4 },
    { dia: 1, mes: 5 },
    { dia: 7, mes: 9 },
    { dia: 12, mes: 10 },
    { dia: 2, mes: 11 },
    {dia: 15, mes: 11},
    { dia: 25, mes: 12 },
];

export default function useCalculateDays() {
  const calculateReduction = (weekDay: number) => {
    if (weekDay === 6) return 2; 
    if (weekDay === 0) return 1; 
    return 0;
  };

  const calculateDaysBetweenNoWeekend = (date) => {
    const daysDifference = differenceInDays(new Date(), parseISO(date));
    if (daysDifference < 0) return 0;

    const weekDay = parseISO(date).getDay();
    const dia = parseISO(date).getDate();
    const mes = parseISO(date).getMonth() + 1;  
    
    let adjustedDays = daysDifference;
    adjustedDays -= calculateReduction(weekDay);

    if (feriadosNacionais.some(feriado => feriado.dia === dia && feriado.mes === mes)) {
        if (weekDay === 5) {
            adjustedDays -= 3;
        } else if (calculateReduction(weekDay) === 0) {
            adjustedDays -= 1;
        }
    }

    if (adjustedDays < 0) return 0;

    return adjustedDays;
};

    return {
        calculateDaysBetweenNoWeekend
    };
}


