import { Dispatch, SetStateAction, useState } from 'react';
import useCheckIfPageIsUpgrade from '../../../globalHooks/matricula/use-check-if-page-is-upgrade.hook';
import { OffersFiltersObject } from '../../../interfaces/offer/offers-filters-object.interface.';
import { OffersChipType } from '../../../types/offer/offers-chip.type';

const offerChipsFilter: OffersChipType[] = ['code', 'money', 'speech', 'english', 'curta', 'colônia', 'escolas', 'antigo', 'cb', 'onecode', 'onecom'];

const offersChipFilterUpgrade: OffersChipType[] = ['code', 'money', 'speech', 'english'];

export default function useOffersFilters() {
	const [checkFiltro, setCheckFiltro] = useState(true);

	const { isUpgradePage } = useCheckIfPageIsUpgrade();

	const offersChipFilterDefaultValue = isUpgradePage ? offersChipFilterUpgrade : offerChipsFilter;

	const [offersChipFilter, setOffersChipFilter] = useState<OffersChipType[]>(offersChipFilterDefaultValue);
	const selectOffer = (offerType: OffersChipType, isChecked: boolean, setIsChecked: Dispatch<SetStateAction<boolean>>) => {
		setIsChecked(!isChecked);
		if (isChecked) {
			setOffersChipFilter(offersChipFilter.filter((o) => o !== offerType));
		} else {
			setOffersChipFilter([...offersChipFilter, offerType]);
		}
	};

	const [isCheckedCode, setIsCheckedCode] = useState(true);
	const selectCode = () => {
		selectOffer('code', isCheckedCode, setIsCheckedCode);
	};

	const [isCheckedMoney, setIsCheckedMoney] = useState(true);
	const selectMoney = () => {
		selectOffer('money', isCheckedMoney, setIsCheckedMoney);
	};

	const [isCheckedSpeech, setIsCheckedSpeech] = useState(true);
	const selectSpeech = () => {
		selectOffer('speech', isCheckedSpeech, setIsCheckedSpeech);
	};

	const [isCheckedEnglish, setIsCheckedEnglish] = useState(true);
	const selectEnglish = () => {
		selectOffer('english', isCheckedEnglish, setIsCheckedEnglish);
	};

	const [isCheckedCurtas, setIsCheckedCurtas] = useState(true);
	const selectCurtas = () => {
		selectOffer('curta', isCheckedCurtas, setIsCheckedCurtas);
	};

	const [isCheckedColonia, setIsCheckedColonia] = useState(true);
	const selectColonia = () => {
		selectOffer('colônia', isCheckedColonia, setIsCheckedColonia);
	};
	
	const [isCheckedEscolas, setIsCheckedEscolas] = useState(true);
	const selectEscolas = () => {
		selectOffer('escolas', isCheckedEscolas, setIsCheckedEscolas);
	};

	const [isCheckedAntigo, setIsCheckedAntigo] = useState(true);
	const selectAntigo = () => {
		selectOffer('antigo', isCheckedAntigo, setIsCheckedAntigo);
	};

	const [isCheckedCB, setIsCheckedCB] = useState(true);
	const selectCB = () => {
		selectOffer('cb', isCheckedCB, setIsCheckedCB);
	};

	const [isCheckedOnecode, setIsCheckedOnecode] = useState(true);
	const selectOnecode = () => {
		selectOffer('onecode', isCheckedOnecode, setIsCheckedOnecode);
	};

	const [isCheckedOnecom, setIsCheckedOnecom] = useState(true);
	const selectOnecom = () => {
		selectOffer('onecom', isCheckedOnecom, setIsCheckedOnecom);
	};

	const offerFilterActions: OffersFiltersObject = {
		code: selectCode,
		money: selectMoney,
		speech: selectSpeech,
		english: selectEnglish,
		curta: selectCurtas,
		colonia: selectColonia,
		cb: selectCB,
		escolas: selectEscolas,
		antigo: selectAntigo,
		onecode: selectOnecode,
		onecom: selectOnecom
	};

	const isCheckedState = {
		code: isCheckedCode,
		money: isCheckedMoney,
		speech: isCheckedSpeech,
		english: isCheckedEnglish,
		curta: isCheckedCurtas,
		colonia: isCheckedColonia,
		cb: isCheckedCB,
		escolas: isCheckedEscolas,
		antigo: isCheckedAntigo,
		onecode: isCheckedOnecode,
		onecom: isCheckedOnecom
	};

	const removeFiltros = (check) => {
		setCheckFiltro(check);
		setOffersChipFilter(!check ? [] : offersChipFilterDefaultValue);
		setIsCheckedCode(check);
		setIsCheckedMoney(check);
		setIsCheckedSpeech(check);
		setIsCheckedEnglish(check);
		setIsCheckedCurtas(check);
		setIsCheckedColonia(check);
		setIsCheckedCB(check);
		setIsCheckedEscolas(check);
		setIsCheckedAntigo(check);
		setIsCheckedOnecode(check);
		setIsCheckedOnecom(check);
	};

	// const adicionaFiltros = () => {
	// 	setCheckFiltro(!checkFiltro);
	// 	setOffersChipFilter(offerChipsFilter);
	// 	setIsCheckedCode(true);
	// 	setIsCheckedMoney(true);
	// 	setIsCheckedSpeech(true);
	// 	setIsCheckedEnglish(true);
	// 	setIsCheckedCurtas(true);
	// 	setIsCheckedColonia(true);
	// 	setIsCheckedCB(true);
	// };

	return {
		offersChipFilter,
		offerFilterActions,
		isCheckedState,
		checkFiltro,
		removeFiltros,
	};
}
