import { ExclamationCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CloseOutlined } from "@mui/icons-material";
import { Button, Checkbox, Input, InputNumber, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../../../../components/Header";
import ModalCancelarCadastro from '../../../../components/ModalCancelarCadastro';
import { useGlobalContext } from "../../../../context/GlobalContext";
import { TipoTopicoAula } from '../../../../enum/tipoTopicoAula';
import { IAlternativaCleanDTO, IQuestaoFullDTO, ITipoTopicoAulaEnum, ITopicoAulaDTO } from "../../../../models/happy-code-api.model";
import { Predicate } from '../../../../models/predicate.model';
import { useAcademyQuestaoService } from "../../../../services/academy-questao";
import { useAcademyTopicoService } from "../../../../services/academy-topico";
import { useNotificationService } from "../../../../services/notification.service";
import "./style.css";

const AcaTopicoCreate: React.FunctionComponent = () => {
    const { setIsGlobalLoading } = useGlobalContext();
    const notification = useNotificationService();
    const [predicate] = useState<Predicate>(new Predicate());
    const topicoService = useAcademyTopicoService();
    const questaoService = useAcademyQuestaoService();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [topico, setTopico] = useState<ITopicoAulaDTO>();
    const { idTrilha, idCurso, idAula, idTopico } = useParams<any>();
    const [tipoTopico] = useState<string>('');
    const [questions, setQuestions] = useState<IQuestaoFullDTO[]>([]);
    // const tipo = [TipoTopicoAula.APRESENTACAO, TipoTopicoAula.CONTEUDO, TipoTopicoAula.AVALIACAO];
     const tipo = [TipoTopicoAula.APRESENTACAO, TipoTopicoAula.CONTEUDO];
    const findTopicos = () => {
        setIsGlobalLoading(true);
        topicoService.findById({ id: idTopico })
            .then((response) => {
                setTopico(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const findQuestao = () => {
        setIsGlobalLoading(true);
        predicate.addOption('topicoAula.id', idTopico);
        questaoService.findFullList(predicate)
            .then((response) => {
                setQuestions(response.data);
            })
            .finally(() => setIsGlobalLoading(false));
    };

    const setarValores = () => {
        if (topico?.tipoTopicoAula == TipoTopicoAula.APRESENTACAO) {
            topico.descricao = "Apresentação";
            topico.numeroTopicoAula = 0;
        }

    }

    const create = () => {
        setIsGlobalLoading(true);
        setarValores();
        // if (topico?.tipoTopicoAula === TipoTopicoAula.AVALIACAO) {
        //     topicoService.create(topico)
        //         .then((response) => {
        //             const questao: IQuestaoFullDTO[] = questions.map(questao => ({ ...questao, topicoAula: response.data }))
        //             setQuestions(questao)
        //             questaoService.saveFullAll(questao)
        //         }).then(() => {
        //             history.push(`/academy/gestao-trilha/trilha/${idTrilha}/cursos/${idCurso}/aulas/${idAula}/topico`);
        //             notification({
        //                 description: `Questionário criado com sucesso.`,
        //                 type: "success",
        //                 message: "Sucesso!",
        //             });
        //         })
        //         .finally(() => setIsGlobalLoading(false));
        // } else {
            topicoService.create(topico)
                .then(() => {
                    history.push(
                        `/academy/gestao-trilha/trilha/${idTrilha}/cursos/${idCurso}/aulas/${idAula}/topico`
                    );
                    notification({
                        description: `Tópico criado com sucesso.`,
                        type: "success",
                        message: "Sucesso!",
                    });
                })
                .finally(() => setIsGlobalLoading(false));
        // }
    };

    const patch = () => {
        setIsGlobalLoading(true);
        // if (topico?.tipoTopicoAula === TipoTopicoAula.AVALIACAO) {
        //     topicoService.patch(topico)
        //         .then((response) => {
        //             const questao: IQuestaoFullDTO[] = questions.map(questao => ({ ...questao, topicoAula: response.data }))
        //             setQuestions(questao)
        //             questaoService.patchFullAll(idTopico, questao)
        //         }).then(() => {
        //             history.push(`/academy/gestao-trilha/trilha/${idTrilha}/cursos/${idCurso}/aulas/${idAula}/topico`);
        //             notification({
        //                 description: `Questionário editado com sucesso.`,
        //                 type: "success",
        //                 message: "Sucesso!",
        //             });
        //         })
        //         .finally(() => setIsGlobalLoading(false));
        // } else {
            topicoService.patch(topico)
                .then(() => {
                    history.push(
                        `/academy/gestao-trilha/trilha/${idTrilha}/cursos/${idCurso}/aulas/${idAula}/topico`
                    );
                    notification({
                        description: `Tópico editada com sucesso.`,
                        type: "success",
                        message: "Sucesso!",
                    });
                })
                .finally(() => setIsGlobalLoading(false));
        // }
    };

    const sendRequest = () => {
        if (!idTopico) {
            create();
        } else {
            patch();
        }
    };

    useEffect(() => {
        setTopico({
            ...topico,
            aula: { ...topico?.aula, id: idAula }
        });
        if (idTopico) {
            findTopicos();
            findQuestao();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idTopico, idAula]);

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve) => {
                    loader.file.then((file) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const base64String = reader.result?.toString().split(',')[1] || '';
                            resolve({ default: `data:image/png;base64,${base64String}` });
                        };
                        reader.readAsDataURL(file);
                    });
                });
            },
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository")
            .createUploadAdapter = (loader) => {
                return uploadAdapter(loader);
            };
    }

    useEffect(() => {
        setQuestions([]);
    }, [tipoTopico]);

    // const showDeleteConfirm = (questionIndex) => {
    //     Modal.confirm({
    //         title: `Deseja realmente excluir a questão?`,
    //         icon: <ExclamationCircleTwoTone rev={undefined} />,
    //         content: topico?.descricao,
    //         okText: 'Sim',
    //         cancelText: 'Não',
    //         onOk: () =>
    //             questaoService.delete({ id: questions[questionIndex].id })
    //                 .then(() => {
    //                     notification({
    //                         description: `Questão excluida com sucesso.`,
    //                         type: "success",
    //                         message: "Sucesso!",
    //                     });
    //                 })
    //                 .finally(() => setIsGlobalLoading(false))
    //     });
    // }

    // const addQuestao = () => {
    //     const newQuestion: IQuestaoFullDTO = {
    //         id: null,
    //         ativo: true,
    //         descricao: null,
    //         alternativas:
    //             [
    //                 { resposta: false } as IAlternativaCleanDTO,
    //                 { resposta: false } as IAlternativaCleanDTO,
    //                 { resposta: false } as IAlternativaCleanDTO,
    //                 { resposta: false } as IAlternativaCleanDTO
    //             ],
    //         justificativa: '',
    //         topicoAula: null
    //     };
    //     setQuestions([...questions, newQuestion]);
    // };

    // const deleteQuestao = (questionIndex: number) => {
    //     showDeleteConfirm(questionIndex)

    //     const updatedQuestions = [...questions];
    //     updatedQuestions.splice(questionIndex, 1);
    //     setQuestions(updatedQuestions);
    // };

    const validarTopico = () => {
        let validacao = false;

        if (topico?.tipoTopicoAula == TipoTopicoAula.APRESENTACAO) {
            if (!topico?.conteudo) {
                notification({
                    description: `O Tópico Apresentação precisa ser cadastrado o conteúdo.`,
                    type: 'warning',
                    message: 'Atenção!',
                });
                validacao = true;
            }
        }

        if (topico?.tipoTopicoAula == TipoTopicoAula.CONTEUDO) {
            if (!topico?.conteudo) {
                notification({
                    description: `O Tópico Conteúdo precisa ser cadastrado o conteúdo.`,
                    type: 'warning',
                    message: 'Atenção!',
                });
                validacao = true;
            }

            if (!topico?.descricao) {
                notification({
                    description: `O Tópico Conteúdo precisa ser cadastrado o título.`,
                    type: 'warning',
                    message: 'Atenção!',
                });
                validacao = true;
            }
        }

        // if (topico?.tipoTopicoAula == TipoTopicoAula.AVALIACAO) {
        //     if (!questions?.find(q => q?.descricao)?.descricao) {
        //         notification({
        //             description: `O Tópico Avaliação precisa ter pelo menos uma questão.`,
        //             type: 'warning',
        //             message: 'Atenção!',
        //         });
        //         validacao = true;
        //     }

        //     questions.forEach(q => {
        //         if (!q?.alternativas.find(a => a?.descricao)?.descricao) {
        //             notification({
        //                 description: `Cada questão do Tópico Avaliação precisa ter pelo menos uma alternativa.`,
        //                 type: 'warning',
        //                 message: 'Atenção!',
        //             });
        //         }
        //         if (!q?.alternativas.find(a => a?.resposta)?.resposta) {
        //             notification({
        //                 description: `O Tópico Avaliação precisa ter pelo menos uma resposta correta.`,
        //                 type: 'warning',
        //                 message: 'Atenção!',
        //             });
        //             validacao = true;
        //         }
        //     });
        // }

        if (!validacao) {
            sendRequest();
        }
    }

    return (
        <div className="container-fondo">
            <Header />
            <div className="franquia" style={{ justifyContent: "normal", width: "65%" }}>
                <div className="space1" />
                <div className="user-row">
                    <div className="card-form" style={{ width: "125%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3 id="taxas-h3" style={{ marginBottom: 10 }}>
                                Insira as informações para cadastro da tópico
                            </h3>
                            <Button
                                onClick={() => setIsOpen(true)}
                                id="cancelar"
                                className="button-close"
                                icon={<CloseOutlined />}
                            />
                        </div>

                        <div
                            style={{ width: "100%", backgroundColor: topico?.tipoTopicoAula && idTopico ? "#f5f5f6" : null }}
                            className="input-box"
                        >
                            <label htmlFor="tipo">Tipo</label>
                            <Select
                                style={{ width: "100%" }}
                                id="tipo"
                                placeholder="Selecione"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                disabled={topico?.tipoTopicoAula && idTopico ? true : false}
                                defaultValue={topico?.tipoTopicoAula}
                                value={topico?.tipoTopicoAula}
                                onChange={(e: ITipoTopicoAulaEnum) =>
                                    setTopico({ ...topico, tipoTopicoAula: e })}
                                filterOption={(input, option) =>
                                    option && option?.text?.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {tipo.map((topico, index) => (
                                    <Select.Option key={index} value={topico} text={topico}>
                                        {topico}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>

                        {topico?.tipoTopicoAula === TipoTopicoAula.APRESENTACAO ?
                            <>
                                <div className="input-box" style={{ height: "100%" }}>
                                    <label htmlFor="editor">Descrição da aula</label>
                                    <CKEditor
                                        config={{
                                            extraPlugins: [uploadPlugin],
                                            mediaEmbed: {
                                                previewsInData: true, // Ative a visualização de incorporação na visualização de dados
                                            },
                                        }}
                                        editor={ClassicEditor}
                                        data={topico?.conteudo}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setTopico({ ...topico, conteudo: data });
                                        }}
                                    />
                                </div>
                            </>
                            : null}

                        {topico?.tipoTopicoAula === TipoTopicoAula.CONTEUDO ?
                            <>
                                <div className="input-box">
                                    <label htmlFor="ordem">Ordem </label>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        id="ordem"
                                        min={1}
                                        max={98}
                                        defaultValue={topico?.numeroTopicoAula}
                                        value={topico?.numeroTopicoAula}
                                        onChange={(e) => setTopico({ ...topico, numeroTopicoAula: e })} />
                                </div>
                                <div className="input-box">
                                    <label htmlFor="descricao">Título</label>
                                    <Input
                                        id="descricao"
                                        defaultValue={topico?.descricao}
                                        value={topico?.descricao}
                                        onChange={(e) => setTopico({ ...topico, descricao: e.target.value })}
                                    />
                                </div>

                                <div className="input-box" style={{ height: "100%" }}>
                                    <label htmlFor="editor">Conteúdo da aula</label>
                                    <CKEditor
                                        config={{
                                            extraPlugins: [uploadPlugin],
                                            mediaEmbed: {
                                                previewsInData: true, // Ative a visualização de incorporação na visualização de dados
                                            },
                                        }}
                                        editor={ClassicEditor}
                                        data={topico?.conteudo}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setTopico({ ...topico, conteudo: data });
                                        }}
                                    />
                                </div>
                            </>
                            : null}

                        {/* {topico?.tipoTopicoAula === TipoTopicoAula.AVALIACAO && (
                            <>
                                <Button
                                    style={{ marginBottom: 10 }}
                                    type="primary"
                                    onClick={addQuestao}>
                                    Adicionar Questão <PlusOutlined rev={undefined} />
                                </Button>
                                {questions.map((questao, questionIndex) => (
                                    <div key={questionIndex}>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                type="primary"
                                                danger
                                                onClick={() => deleteQuestao(questionIndex)} // Call the deleteQuestao function
                                                style={{ marginBottom: 10 }}
                                            > Excluir Questão X
                                            </Button>
                                        </div>
                                        <div className="input-box">
                                            <label htmlFor={`questao${questionIndex + 1}`}>{`Questão.${questionIndex + 1}`}</label>
                                         <CKEditor // novo
                                            config={{
                                                extraPlugins: [uploadPlugin],
                                                mediaEmbed: {
                                                    previewsInData: true, 
                                                },
                                                toolbar:{
                                                    removeItems:['heading', 'link', 'bulletedList', 'numberedList', 'alignment', 'decreaseIndent', 'increaseIndent', 'insertTable']
                                                },
                                            }}
                                            editor={ClassicEditor}
                                            data={questao?.descricao}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                const updatedQuestions = [...questions];    
                                                updatedQuestions[questionIndex].descricao = data;
                                                setQuestions(updatedQuestions);                                                
                                            }}
                                        />
                                        </div>

                                        {questao.alternativas.map((alternativa, alternativaIndex) => (
                                            <div
                                                key={alternativaIndex}
                                                className="box-alternativa"
                                                style={{ backgroundColor: alternativa.resposta ? "#1ebe5e" : null }}
                                            >
                                                <div className="input-box" style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                                    <div>
                                                        <label htmlFor={`questao${questionIndex + 1}`} style={{ marginRight: 10 }}>
                                                            {`Alternativa.${alternativaIndex + 1}`} está correta?
                                                        </label>
                                                        <Checkbox
                                                            id={`check-box-estorno-${questionIndex}-${alternativaIndex}`}
                                                            checked={alternativa?.resposta}
                                                            onChange={(e) => {
                                                                const updatedQuestions = [...questions];
                                                                updatedQuestions[questionIndex].alternativas[alternativaIndex].resposta = e.target.checked;
                                                                setQuestions(updatedQuestions);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-box" style={{ marginRight: 10 }}>
                                                    <label htmlFor={`alternativa${questionIndex + 1}-${alternativaIndex + 1}`}>
                                                        {`Alternativa.${alternativaIndex + 1}`}
                                                    </label>

                                                     <CKEditor
                                                        id={`alternativa${questionIndex + 1}-${alternativaIndex + 1}`}
                                                        config={{
                                                            extraPlugins: [uploadPlugin],
                                                            mediaEmbed: {
                                                                previewsInData: true, 
                                                            },
                                                            toolbar:{
                                                                items:['bold', 'italic'],
                                                            }
                                                            
                                                        }}
                                                        editor={ClassicEditor}
                                                        data={alternativa?.descricao}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            const updatedQuestions = [...questions];
                                                            updatedQuestions[questionIndex].alternativas[alternativaIndex].descricao = data;
                                                            setQuestions(updatedQuestions);
                                                        }}
                                                    />
                                                </div>

                                                <div className="input-box" style={{ marginRight: 10 }}>
                                                    <label htmlFor={`justificativa${questionIndex + 1}-${alternativaIndex + 1}`}>
                                                        {`Justificativa.${alternativaIndex + 1}`}
                                                    </label>
                                                    <TextArea
                                                        id={`justificativa${questionIndex + 1}-${alternativaIndex + 1}`}
                                                        value={alternativa.justificativa}
                                                        onChange={(e) => {
                                                            const updatedQuestions = [...questions];
                                                            updatedQuestions[questionIndex].alternativas[alternativaIndex].justificativa = e.target.value;
                                                            setQuestions(updatedQuestions);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                ))}
                            </>
                        )} */}

                        <div className="botoes" style={{ marginTop: 10 }}>
                            <Link
                                to={`/academy/gestao-trilha/trilha/${idTrilha}/cursos/${idCurso}/aulas/${idAula}/topico`}
                            >
                                <Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
                                    Voltar
                                </Button>
                            </Link>
                            <Button
                                style={{ width: 345 }}
                                type="primary"
                                id="proximo"
                                className="button-primary"
                                onClick={validarTopico}
                            >
                                Salvar
                            </Button>
                        </div>
                    </div>
                </div>
            </div >
            <ModalCancelarCadastro
                open={isOpen}
                onClose={() => setIsOpen(false)}
                title="Você deseja cancelar a operação?"
                subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
                textButton1="Não"
                textButton2="Sim, Cancelar"
                styleButton1="button-line"
                styleButton2="button-danger"
                link2={`/academy/gestao-trilha/trilha/${idTrilha}/cursos/${idCurso}/aulas/${idAula}/topico`}
            />
        </div >
    );
};

export default AcaTopicoCreate;
