import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../context/GlobalContext";
import { ICurriculoCleanDTO, INegocioDTO, INegocioUnidadeDTO, ISalaCleanDTO, IUnidadePessoaCleanDTO } from "../../../models/happy-code-api.model";
import { Predicate } from "../../../models/predicate.model";
import { useCurriculoService } from "../../../services/curriculo.service";
import { useNegocioUnidadeService } from "../../../services/negocio-unidade.service";
import { useNegocioService } from "../../../services/negocio.service";
import { useSalaService } from "../../../services/sala.service";
import { useUnidadePessoaService } from "../../../services/unidade-pessoa.service";
import { useTurmaContext } from "../../../context/TurmaContext";

export function useFindData() {
    const { setIsGlobalLoading, unidade } = useGlobalContext();
    const { turma, setTurma } = useTurmaContext();
    const negocioUnidadeService = useNegocioUnidadeService();
    const negocioService = useNegocioService();
    const curriculoService = useCurriculoService();
    const salaService = useSalaService();
    const unidadePessoaService = useUnidadePessoaService();
    const [listProfessor, setListProfessor] = useState<IUnidadePessoaCleanDTO[]>([]);
    const [listNegocios, setListNegocios] = useState<INegocioUnidadeDTO[]>();
    const [listCurriculos, setListCurriculos] = useState<ICurriculoCleanDTO[]>();
    const [listSalas, setListSalas] = useState<ISalaCleanDTO[]>();
    const [negocioSelected, setNegocioSelected] = useState<INegocioDTO>();

    const findNegocios = () => {
        setIsGlobalLoading(true);
        const predicate = new Predicate();
        predicate.addOption('ativo', true);
        predicate.addOption('unidade.id', unidade.id);
        negocioUnidadeService
            .findList(predicate)
            .then((response) => setListNegocios(response.data))
            .finally(() => setIsGlobalLoading(false));
    };

    const findNegocio = () => {
        setIsGlobalLoading(true);
        if (turma?.curriculoNegocioId) {
            negocioService
                .findById({ id: turma?.curriculoNegocioId })
                .then((response) => {
                    setNegocioSelected(response.data);
                })
                .finally(() => setIsGlobalLoading(false));
        }
    };

    const findCurriculos = () => {
        if (turma?.curriculoNegocioId) {
            setIsGlobalLoading(true);
            const predicate = new Predicate();
            predicate.addOption('ativo', true);
            predicate.addOption('negocio.id', turma?.curriculoNegocioId);
            curriculoService
                .findCleanList(predicate)
                .then((response) => setListCurriculos(response.data))
                .finally(() => setIsGlobalLoading(false));
        }
    };

    const findProfessor = () => {
        const professorPredicate = new Predicate();
        professorPredicate.addOption('tipoUnidadePessoa.id', 3);
        professorPredicate.addOption('unidade.id', unidade.id);
        professorPredicate.addOption('pessoa.ativo', true);
        return unidadePessoaService.findCleanList(professorPredicate).then((response) => {
            setListProfessor(response.data);
        });
    };

    const findSalas = () => {
        setIsGlobalLoading(true);
        const predicate = new Predicate();
        predicate.addOption('ativo', true);
        predicate.addOption('unidade.id', unidade.id);
        salaService
            .findCleanList(predicate)
            .then((response) => setListSalas(response.data))
            .finally(() => setIsGlobalLoading(false));
    };

    useEffect(() => {
        if (unidade?.id) {
            findCurriculos();
            findNegocios();
            findSalas();
            findProfessor();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unidade?.id]);

    useEffect(() => {
        findCurriculos();
        findNegocio();
        setTurma({
            ...turma,
            horarios: turma?.horarios?.slice(0, 1),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [turma?.curriculoNegocioId]);

    return { findCurriculos, findNegocio, findNegocios, findProfessor, findSalas, listProfessor, listNegocios, listCurriculos, listSalas, negocioSelected };
}