import code from '../assets/img/box-miniatura/code.png';
import colonia from '../assets/img/box-miniatura/colonia.png';
import curta from '../assets/img/box-miniatura/curtas.png';
import english from '../assets/img/box-miniatura/english.png';
import money from '../assets/img/box-miniatura/money.png';
import speech from '../assets/img/box-miniatura/speech.png';
import escolas from '../assets/img/box-miniatura/escolas.png';
import antigo from '../assets/img/box-miniatura/antigo.png';
import cb from '../assets/img/box-miniatura/curtas.png';
import onecode from '../assets/img/box-miniatura/curtas.png';
import onecom from '../assets/img/box-miniatura/curtas.png';

export const classMiniatureIcons = {
    code,
    money,
    speech,
    english,
    curta,
    colonia,
    escolas,
    antigo,
    cb,
    onecode,
    onecom,
};
