/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react';
import { IHoraSedeFullDTO } from '../models/happy-code-api.model';
import { getObject, removeKey, setObject } from '../util/store';

//Tipando as Props do contexto
interface PropsHoraSedeContext {
	horaSede: IHoraSedeFullDTO;
	revisao: boolean;
	setHoraSede: React.Dispatch<React.SetStateAction<IHoraSedeFullDTO>>;
	setRevisao: React.Dispatch<React.SetStateAction<boolean>>;
	removeHoraSede: React.DispatchWithoutAction;
}

const horaSedeDefaultValue = {} as IHoraSedeFullDTO;

//Valor default do contexto
const DEFAULT_VALUE: PropsHoraSedeContext = {
	horaSede: horaSedeDefaultValue,
	revisao: false,
	setHoraSede: () => { }, //função de inicialização
	setRevisao: () => { }, //função de inicialização
	removeHoraSede: () => { }, //função de inicialização
};

//criando nosso contexto UserContext
const HoraSedeContext = createContext<PropsHoraSedeContext>(DEFAULT_VALUE);
const nameStorage = '@HappyCode/hora-sede';
const nameStorageRevisao = '@HappyCode/hora-sede/revisao';
/**
 * Função que irá conter o estado e função que irá alterar o estado 'setState'
 * quer irá prover o contexto para os componentes filhos da árvore
 */
export const HoraSedeContextProvider: React.FC = ({ children }: any) => {
	const [horaSede, setHoraSedeGlobal] = useState<IHoraSedeFullDTO>(
		DEFAULT_VALUE.horaSede
	);
	const [revisao, setRevisaoGlobal] = useState<boolean>(DEFAULT_VALUE.revisao);

	const setHoraSede = (horaSede) => {
		setHoraSedeGlobal(horaSede);
		setObject(nameStorage, horaSede);
	};

	const setRevisao = (revisao) => {
		setRevisaoGlobal(revisao);
		setObject(nameStorageRevisao, revisao);
	};

	const removeHoraSede = () => {
		setRevisao(false);
		setHoraSedeGlobal(DEFAULT_VALUE.horaSede);
		removeKey(nameStorage);
	};

	useEffect(() => {
		const horaSede = getObject(nameStorage);
		if (horaSede) {
			setHoraSedeGlobal(horaSede);
		}
		const revisao = getObject(nameStorageRevisao);
		if (revisao) {
			setRevisaoGlobal(revisao);
		}
	}, []);

	return (
		<HoraSedeContext.Provider
			value={{
				horaSede,
				revisao,
				setHoraSede,
				setRevisao,
				removeHoraSede,
			}}
		>
			{children}
		</HoraSedeContext.Provider>
	);
};

export const useHoraSedeContext = (): PropsHoraSedeContext => {
	const context = useContext(HoraSedeContext);
	if (!context)
		throw new Error(
			'useNegocioContext must be used within a HoraSedeContextProvider'
		);
	return context;
};
