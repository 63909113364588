import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from '../../components/NewMenu';
import { IBannerCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import FiltrosBanner from './filtros-banner';
import { useGlobalContext } from '../../context/GlobalContext';
import { useBannerService } from '../../services/banner.service';
import ItemListBanner from './item-lista';

const LearnBanner: React.FunctionComponent = () => {
	const [data, setData] = useState<PageableResponse<IBannerCleanDTO>>();
	const history = useHistory();
	const [predicate] = useState<Predicate>(new Predicate());
  const { setIsGlobalLoading } = useGlobalContext();
  const bannerService = useBannerService();

	const findBanner = () => {
		setIsGlobalLoading(true);
		bannerService
			.find(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};
  const ordenar = (campo:string) => {
    predicate.addSort(campo);
    findBanner();
  }

  const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findBanner();
	};
	
	return (
		<div className="container">
			<NewMenu selecionado={112} />
			<div className="dashboard" style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}>
				<div className="box-title">
					<h3 className="title">Banner ({data?.totalElements ? data?.totalElements : 0})</h3>
					<Button className="button-primary" id="criar-c" icon={<PlusOutlined rev={undefined} />} onClick={() => history.push('/banner-learn-create')}>
						Novo banner
					</Button>
				</div>
				<FiltrosBanner findBanner={findBanner} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '20%' }}>
							<span>ID</span>
						<div className="arrows" onClick={() => ordenar('id')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '30%' }}>
							<span>NOME</span>
							<div className="arrows" onClick={() => ordenar('nome')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
            <div className="item" style={{ width: '16%' }}>
							<span>ORDEM</span>
							<div className="arrows" onClick={() => ordenar('ordem')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '18%' }}>
							<span>DATA VENCIMENTO</span>
							<div className="arrows" onClick={() => ordenar('dataExpiracao')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '5%' }}></div>
					</div>
					<div className="tabela-body">{data && data.content.map((banner) => <ItemListBanner key={banner.id} banner={banner} />)}</div>
					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default LearnBanner;
