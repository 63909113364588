import NewMenu from '../../components/NewMenu';

import './style.css';

export const Home: React.FunctionComponent = () => {
  return (
    <div className="container-home">
      <NewMenu selecionado={0} />
    </div>
  );
}