import { memo } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/login/logo-space.png';
import { useGlobalContext } from '../../context/GlobalContext';
import { Avatar } from '../Avatar';
import './style.css';

const Header = memo(() => {
	const { usuario } = useGlobalContext();
	return (
		<header className="header-wrapper">
			<Link
				className="header-wrapper__logo"
				to={usuario.perfil === 'SEDE' ? '/' : '/franquia'}
			>
				<img src={logo} alt={'logo'} className="logo" />
			</Link>

			<Link to="/usuarios/profile" className="header-wrapper__profile">
				<Avatar base64={usuario?.arquivo} size={30} />
				<div className="profile__user">
					<h6>{usuario?.usuario}</h6>
					<span>{usuario?.perfil}</span>
				</div>
			</Link>
		</header>
	);
});

Header.displayName = 'Header';

export default Header;
