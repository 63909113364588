import moment from 'moment';
import { useEffect } from 'react';
import { useTurmaContext } from '../../../context/TurmaContext';
import { INegocioDTO } from '../../../models/happy-code-api.model';

interface Props {
    negocioSelected: INegocioDTO;
}

const useClassPeriod = ({ negocioSelected }: Props) => {
    const { turma, setTurma } = useTurmaContext();

    const getPeriodo = (): [moment.Moment, moment.Moment] | null => {
        if (turma.dataInicio && turma.dataFim) {
            return [moment(turma.dataInicio), moment(turma.dataFim)];
        }
        return null;
    };

    const atualizarPeriodo = () => {
        if (turma.dataInicio) {
            let dataFim = moment(turma.dataInicio);

            if (negocioSelected?.codigo === 'CUR') {
                dataFim = dataFim.add(1, 'months');
            } else if (negocioSelected?.codigo === 'COL') {
                dataFim = dataFim.add(3, 'months');
            } else {
                dataFim = dataFim.add(1, 'year');
            }

            setTurma((prevTurma) => ({
                ...prevTurma,
                dataFim: dataFim.toDate(),
            }));
        }
    };

    useEffect(() => {
        if (!turma.dataInicio || !turma.dataFim) {
            setTurma({
                ...turma,
                dataInicio: moment().startOf('day').toDate(),
                dataFim: moment().startOf('day').toDate(),
            });
        }

        if (turma.dataInicio) {
            atualizarPeriodo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [turma.dataInicio, negocioSelected?.codigo]);

    return { getPeriodo, atualizarPeriodo };
};

export default useClassPeriod;
