import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PresentImage } from '../../../../components/ApresentarImagem';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IQuestaoDTO, IWebinarDTO } from '../../../../models/happy-code-api.model';
import { useAcademyWebinarService } from '../../../../services/academy-webinar';
import { useNotificationService } from '../../../../services/notification.service';
import { useAcademyQuestaoService } from '../../../../services/academy-questao';

interface TItemLista {
	questao: IQuestaoDTO;
}

const AcademyQuestaoItemList = memo(({ questao }: TItemLista) => {
	const history = useHistory();
	const [ativo, setAtivo] = useState<boolean>();
	const questaoService = useAcademyQuestaoService();
	const notification = useNotificationService();
	const { setIsGlobalLoading } = useGlobalContext();

	const goTo = () => {
		history.push(`/academy/gestao-questao/create/${questao.id}`);
	};

	useEffect(() => {
		setAtivo(questao.ativo);
	}, [questao.ativo]);

	const showDeleteConfirm = () => {
		Modal.confirm({
			title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} a questao?`,
			icon: <ExclamationCircleTwoTone rev={undefined} />,
			content: questao?.descricao,
			okText: 'Sim',
			okType: `${ativo ? 'danger' : 'primary'}`,
			cancelText: 'Não',
			onOk: () =>
				questaoService
					.patch({ id: questao.id, ativo: !ativo })
					.then(() => {
						setAtivo(!ativo);
						notification({ description: 'questao alterada com sucesso!', type: 'success', message: 'Sucesso' });
					})
					.finally(() => setIsGlobalLoading(false)),
		});
	};

	const menu = (
		<Menu>
			<Menu.Item onClick={showDeleteConfirm}>
				<a rel="noopener noreferrer">Suspender/Ativar</a>
			</Menu.Item>
		</Menu>
	);

	return (
		<div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
			<div className="user" id="cursos" style={{ width: '30%' }}>
				<div className="nome">
					<p>{questao?.id}</p>
					{ativo ? (
						<div>
							<div className="estado-user">
								<div className="dot-verde"></div>
								<p className="ativo">Ativo</p>
							</div>
						</div>
					) : (
						<div>
							<div className="estado-user">
								<div className="dot-vermelho"></div>
								<p className="inativo">Inativo</p>
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="funcao-user" id="cursos-id" style={{ width: '25%' }}>
				<div dangerouslySetInnerHTML={{ __html: questao?.descricao ?? '' }}></div>
			</div>
            <div className="funcao-user" id="cursos-id" style={{ width: '25%' }}>
				<p>{questao?.trilha?.descricao ?? "Nenhuma trilha associada"}</p>
			</div>

			<div className="funcao-user" id="cursos-id" style={{ width: '10%' }}></div>
			<div className="registro-opcao" style={{ width: '5%' }}>
				<a id="editar" onClick={goTo}>
					<Unicons.UilEdit size="20" color="#92A9CB" />
				</a>
				<Dropdown overlay={menu} placement="bottomRight" arrow>
					<Unicons.UilEllipsisH className="button-dropdown" size="20" color="#92A9CB" />
				</Dropdown>
			</div>
		</div>
	);
});

AcademyQuestaoItemList.displayName = 'AcademyQuestaoItemList';

export default AcademyQuestaoItemList;
