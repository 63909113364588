import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import IconButton from '../../../../components/ButtonVoltar';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import '../../../../global.css';
import { IMaterialApoioDTO } from '../../../../models/happy-code-api.model';
import { Predicate } from '../../../../models/predicate.model';
import { PageableResponse } from '../../../../models/response.model';
import { useMaterialApoioService } from '../../../../services/materiais.apoio.service';
import FiltrosMaterialApoio from './filtros-material';
import AcademyMaterialApoioList from './item-lista';


export default function AcademyMaterialApoio() {
	const [predicate] = useState<Predicate>(new Predicate());
	const [data, setData] = useState<PageableResponse<IMaterialApoioDTO>>();
	const materialApoioService = useMaterialApoioService();
	const history = useHistory();
	const { setIsGlobalLoading } = useGlobalContext();
	const {idPasta} = useParams<any>();

	const findMaterialApoio = () => {
		setIsGlobalLoading(true);
		predicate.addOption('pasta.id', idPasta);
		materialApoioService
			.find(predicate)
			.then(({ data }) => {
				setData(data);		
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findMaterialApoio();
	};

	const ordenar = (campo: string) => {
		predicate.addSort(campo);
		findMaterialApoio();
	};	

	return (
		<div className="container">
			<NewMenu selecionado={37} />
			<div className="dashboard">
				<div className="box-title">
				<IconButton rota="/academy/gestao-pasta-material">Voltar</IconButton>
					<h3 className="title">Arquivos ({data?.totalElements || 0})</h3>
					<Button
						className="button-primary"
						id="criar-curso"
						icon={<PlusOutlined rev={undefined} />}
						onClick={() => history.push(`/academy/gestao-pasta-material/${idPasta}/materiais-apoio/create`)}
					>
						Upload de material de apoio 
					</Button>
				</div>
				<FiltrosMaterialApoio findMaterialApoio={findMaterialApoio} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '22%' }}>
							<span>ID</span>
							<div className="arrows" onClick={() => ordenar('id')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '29%' }}>
							<span>DESCRIÇÃO DO ARQUIVO</span>
							<div className="arrows" onClick={() => ordenar('nome')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>

						<div className="item" style={{ width: '10%' }}>
							<span>PASTA RELACIONADA</span>
						</div>

						<div className="item" style={{ width: '25%' }}></div>
					</div>

					<div className="tabela-body">{data && data.content.map((materialApoio, index) => <AcademyMaterialApoioList materialApoio={materialApoio} key={index} />)}</div>

					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data?.pageable?.pageNumber + 1 : 0}
							count={data ? data?.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
