import { useMemo, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { INegocioUnidadeDTO } from '../../../models/happy-code-api.model';
import { Predicate } from '../../../models/predicate.model';
import { useNegocioUnidadeService } from '../../../services/negocio-unidade.service';

interface OffersObjectArray {
	color: string;
	name: string;
	onClick: () => void;
	disabled?: boolean;
}

export default function useOffersObject() {
	//const [offersObjectArray, setOffersObjectArray] = useState<OffersObjectArray[]>();
	const { unidade } = useGlobalContext();
	const negocioUnidadeService = useNegocioUnidadeService();
	const [negocioUnidades, setNegocioUnidades] = useState<INegocioUnidadeDTO[]>();

	const offersObjectArray = negocioUnidades?.map(
		(un) =>
			({
				color: un.negocio.cor,
				name: un.negocio.nomeCurto,
			} as OffersObjectArray)
	);

	useMemo(() => {
		if (!unidade?.id) return;

		const predicate = new Predicate();
		predicate.addOption('unidade.id', unidade.id);
		negocioUnidadeService.findList(predicate).then((response) => setNegocioUnidades(response.data));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade?.id]);

	return {
		offersObjectArray,
	};
}
