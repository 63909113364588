import { Button, Modal } from 'antd';
import { IMensagemHomeCleanDTO, IMensagemHomeDTO } from '../../models/happy-code-api.model';
import { setObject } from '../../util/store';
import { useState } from 'react';

interface ModalProps {
	content: IMensagemHomeDTO;
	open: boolean;
	setOpen: (open: boolean) => void;
}

export default function ModalMensagemHome({ content, open, setOpen }: ModalProps) {
	const [confirmLoading, setConfirmLoading] = useState(false);
	const toCloseModal = () => {
		setConfirmLoading(true);
		setOpen(false);
		setObject('modalLogin', false);
		setConfirmLoading(false);
	};

	return (
		<>
			<Modal
				confirmLoading={confirmLoading}
				style={{ top: 3 }}
				mask
				maskStyle={{ backdropFilter: 'blur(2px)' }}
				width={950}
				title={<div className="modal-title">{content?.titulo}</div>}
				visible={open}
				onCancel={toCloseModal}
				footer={
					<Button key="submit" type="primary" className="modal-footer-button" loading={confirmLoading} onClick={toCloseModal}>
						Entendi
					</Button>
				}
			>
				<div className="render-modal" dangerouslySetInnerHTML={{ __html: content?.mensagem }} />
			</Modal>
		</>
	);
}
