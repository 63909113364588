import { useHoraSedeContext } from '../../../../../context/HoraSedeContext';

export default function useInputsContentArray() {

	const { horaSede, setHoraSede } = useHoraSedeContext();

	const createInputContent = (label: string, horaSedeKey: string) => ({
		label,
		defaultValue: horaSede?.[horaSedeKey],
		value: horaSede?.[horaSedeKey] ?? 0,
		onChange: (event) =>
			setHoraSede({ ...horaSede, [horaSedeKey]: event ?? 0 }),
	});

	const inputsContent = [
		createInputContent('Split', 'splitUmHoraEMeiaCapital'),
		createInputContent('Upgrade', 'splitUpgradeUmHoraEMeiaCapital'),
		createInputContent('Split', 'splitTresHorasCapital'),
		createInputContent('Upgrade', 'splitUpgradeTresHorasCapital'),
		createInputContent('Split', 'splitQuatroHorasEMeiaCapital'),
		createInputContent('Upgrade', 'splitUpgradeQuatroHorasEMeiaCapital'),
		createInputContent('Split', 'splitSeisHorasCapital'),
		createInputContent('Upgrade', 'splitUpgradeSeisHorasCapital'),

		createInputContent('Split', 'splitAdicionalCapital'),
		createInputContent('Upgrade', 'splitAdicionalUpgradeCapital'),

		createInputContent('Split', 'splitCurtaUmHoraEMeiaCapital'),
		createInputContent('Split', 'splitCurtaTresHorasCapital'),
		createInputContent('Split', 'splitCurtaQuatroHorasEMeiaCapital'),
		createInputContent('Split', 'splitCurtaSeisHorasCapital'),

		createInputContent('Split', 'splitColoniaCapital'),

		createInputContent('Split', 'splitEscolaCapital'),
	];

	const createSectionContent = (title: string, start: number, end: number) => ({
		title,
		inputsContent: inputsContent.slice(start, end),
	});

	const inputsSectionContent = [
		createSectionContent('1h e 30m', 0, 2),
		createSectionContent('3h', 2, 4),
		createSectionContent('4h e 30m', 4, 6),
		createSectionContent('6h', 6, 8),
		createSectionContent('Split Adicional', 8, 10),

		createSectionContent('Curta - 1h e 30m', 10, 11),
		createSectionContent('Curta - 3h', 11, 12),
		createSectionContent('Curta - 4h e 30m', 12, 13),
		createSectionContent('Curta - 6h', 13, 14),

		createSectionContent('Colônia', 14, 15),

		createSectionContent('Escola', 15, 16),
	];

	return {
		inputsSectionContent,
	};
}
