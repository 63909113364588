import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined';
import * as Unicons from '@iconscout/react-unicons';
import { Button } from 'antd';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import logoSpace from '../../assets/img/login/logo-space.png';
import logoMarcaHappy from '../../assets/img/login/LOGO_HAPPY_BRANCO.png';
import logoMarcaCB from '../../assets/img/login/Logomarca_Cento_Britanico_bco hor.png';
import { useGlobalContext } from '../../context/GlobalContext';
import { useUsuarioService } from '../../services/usuario.service';
import '../Login/style.css';
import { useNotificationService } from './../../services/notification.service';
import './style.css';

interface TInputs {
  password: string;
  repassword: string;
}

export const RecuperarSenha: React.FunctionComponent = () => {

  const { register, handleSubmit, watch, formState: { errors } } = useForm<TInputs>();
  const notification = useNotificationService();
  const userService = useUsuarioService();
  const history = useHistory();
  const password = useRef({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { token } = useParams<any>();

  const { setIsGlobalLoading } = useGlobalContext()
  password.current = watch("password", "");

  const onRecuperaSenha = (data: TInputs) => {
    setIsGlobalLoading(true);
    const payload = {
      token,
      senha: data.password
    }
    userService.alteraSenha(payload)
      .then(() => {
        notification({ message: "Sucesso!", description: "Sua senha foi alterada com sucesso, entre com sua nova senha!" });
        history.push('');
      })
      .catch(error => notification({ message: "Erro!", description: error.response.data.message, type: "error" }))
      .finally(() => setIsGlobalLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(onRecuperaSenha)} className="container-login" style={{ backgroundColor: 'var(--white-color)' }}>
      <div className="formulario-box">
      <img src={logoSpace} alt="Logo-Space" className='logo-principal' />
        <div className="formulario">
          <div className="arrow">
            <Link to="/">
              <Button className="button-second-voltar" icon={<ArrowLeftOutlined rev={undefined}   style={{color: '#fff'}}/>}>Voltar</Button>
            </Link>
          </div>
          <h3>Recuperação de senha</h3>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitant diam nibh id sollicitudin. Malesuada amet.</p> */}
          <ul className="password-requisitos" style={{ color: 'var(--white-color' }}>
            <li>Mínimo 5 caracteres</li>
            <li>Pelo menos 1 letra maiúscula</li>
            <li>Pelo menos 1 número</li>
          </ul>
          <div className="input">
            <Unicons.UilLock size="20" color="#6A7C96" className="iconForm" />
            <input
              type="password" placeholder="Digite a senha"
              {...register('password', {
                required: true,
                pattern: /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z!@#$%&*]{5,}$/,
              })}
            />
            {errors.password && <small className="input-error">A senha digitada não possui os requisitos acima</small>}
          </div>

          <div className="input" >
            <Unicons.UilLock size="20" color="#6A7C96" className="iconForm" />
            <input type="password" placeholder="Digite novamente a senha"
              {...register('repassword',
                { validate: value => value === password.current || "As senhas não combinam" }
              )}
            />
            {errors.repassword && <small className="input-error">{errors.repassword.message}</small>}
          </div>
          <button type="submit" className="button-second button-login" style={{ width: '100%' }}>
            <span>Enviar</span>
          </button>
          <div className='logomarcas' >
								<img src={logoMarcaHappy} alt="Logo-happy" />
								<img src={logoMarcaCB} alt="Logo-cb" />
							</div>
        </div>
      </div>
    </form>
  );
}
