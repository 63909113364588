import * as Unicons from '@iconscout/react-unicons';
import { Drawer } from 'antd';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../assets/img/login/logo-space.png';
import logoMarcaHappy from '../../assets/img/login/LOGO_HAPPY_BRANCO.png';
import logoMarcaCB from '../../assets/img/login/Logomarca_Cento_Britanico_bco hor.png';
import layout from '../../assets/img/login/girl.png';
import PasswordEye from '../../components/PasswordEye';
import { useGlobalContext } from '../../context/GlobalContext';
import { Operators } from '../../enum/operators.enum';
import '../../global.css';
import {
	IUnidadeCleanDTO,
	IUserAuthDTO,
} from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useAuthService } from '../../services/auth.service';
import { useNotificationService } from '../../services/notification.service';
import { useUsuarioUnidadeService } from '../../services/usuario-unidade.service';
import { useUsuarioService } from '../../services/usuario.service';
import { clear, setObject } from '../../util/store';
import './style.css';

interface TInputs {
	user: string;
	password: string;
}

export const Login: React.FunctionComponent = () => {
	const [eyePassword, setEyePassword] = useState<boolean>(false);
	const { register, handleSubmit } = useForm<TInputs>();
	const { setUsuario, setToken, setIsGlobalLoading, setUnidade } = useGlobalContext();

	const [open, setOpen] = useState(false);
	const [unidades, setUnidades] = useState<IUnidadeCleanDTO[]>([]);

	const history = useHistory();

	const notification = useNotificationService();
	const authService = useAuthService();
	const userService = useUsuarioService();
	const usuarioUnidadeService = useUsuarioUnidadeService();

	const onToggle = () => setOpen(!open);

	const usuarioFranquia = async (data: IUserAuthDTO) => {
		setIsGlobalLoading(true);
		const predicate = new Predicate();
		predicate.addOption('usuario.id', data.id);
		predicate.addOption('unidade.usuario.funcao.codigo', 'IST', Operators.NOT_EQUAL);

		await usuarioUnidadeService.findList(predicate).then(({ data }) => {
			if (data.length === 1) {
				setUnidade(data[0].unidade);
				history.push('/franquia');
			} else if (data.length > 1) {
				onToggle();
				const unidades = data.map((usuarioUnidade) => usuarioUnidade.unidade);
				setUnidades(unidades);
			} else {
				notification({
					type: 'warning',
					message: 'Atenção!',
					description: 'Usuário não possui acesso a Franquias',
				});
			}
		});
	};

	const fetchNomeUsuario = async (userAuth: IUserAuthDTO) => {
		setIsGlobalLoading(true);

		userService
			.findById({ id: userAuth.id })
			.then(async ({ data }) => {
				userAuth.usuario = data.pessoa.nome;
				setUsuario(userAuth);

				if (userAuth.perfil === 'SEDE') {
					history.push('/');
					setIsGlobalLoading(false);
				} else await usuarioFranquia(userAuth);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const onLogin = (login) => {
		setIsGlobalLoading(true);
		clear();

		authService
			.login(login)
			.then(({ data: token }) => setToken(token))
			.then(() => userService.getPrincipal())
			.then(({ data }) => fetchNomeUsuario(data))
			.finally(() => setIsGlobalLoading(false));
	};

	const onClickUnidade = (unidade: IUnidadeCleanDTO) => {
		setUnidade(unidade);
		setObject('modalLogin', true);
		history.push('/franquia');
	};

	const optionsUnidade = unidades.map((unidade) => {
		return (
			<div onClick={() => onClickUnidade(unidade)} className="box-login" key={unidade.id}>
				<Unicons.UilBuilding size="30" color="#92A9CB" />
				<p>{unidade.descricao}</p>
			</div>
		);
	});

	return (
		<Fragment>
			<form onSubmit={handleSubmit(onLogin)}>
				<div className="container-login" style={{ backgroundColor: 'var(--white-color' }}>

					<div className="formulario-box">

						<div className="formulario">
							<img src={logo} alt="Logo-Space" className='logo-principal-home' />
							<h3>Bem-vindo,</h3>
							<p>Informe seu e-mail e senha para realizar o login</p>
							<div className="input">
								<Unicons.UilEnvelopeAlt size="20" color="#6A7C96" className="iconForm" />
								<input {...register('user', { required: true })} type="text" id="username" placeholder="Email" />
							</div>
							<div className="input">
								<Unicons.UilLock size="20" color="#6A7C96" className="iconForm" />
								<input {...register('password', { required: true })} type={eyePassword ? 'text' : 'password'} id="password" placeholder="Senha" />
								<PasswordEye eventEye={eyePassword} setEventEye={setEyePassword} />
							</div>
							<div className="lembrete">
								{/* { <div className="checkbox">
                                    <input type="checkbox" id="lembrete" />
                                    <span>Lembre de mim</span>
                                </div> } */}
								<Link to="/senha">
									<span id="green-happy">Esqueceu sua senha ?</span>
								</Link>
							</div>

							<button type="submit" id="entrar" className="button-second button-login" >
								<span style={{ color: '#0C306C' }}>Entrar</span>
							</button>

							<div className='logomarcas' >
								<img src={logoMarcaHappy} alt="Logo-happy" />
								<img src={logoMarcaCB} alt="Logo-cb" />
							</div>


							{/* <span className="conta">Ainda não tem uma conta? </span>
                            <span className="cadastro">Cadastre-se agora</span> */}
						</div>
					</div>
				</div>
			</form>

			<Drawer title="Selecione uma Franquia para Logar" onClose={onToggle} visible={open} placement="top">
				<div className="boxes-login">
					<div style={{ display: 'flex', position: 'absolute' }}>{optionsUnidade}</div>
				</div>
			</Drawer>
		</Fragment>
	);
};
