import { useCallback, useEffect, useState } from 'react';
import { useAlunoContext } from '../../context/AlunoContext';
import useCheckIfPageIsUpgrade from '../../globalHooks/matricula/use-check-if-page-is-upgrade.hook';
import { IMatriculaDTO, ITurmaGradeCleanDTO } from '../../models/happy-code-api.model';
import { OffersChipType } from '../../types/offer/offers-chip.type';

interface UseMiniaturaProps {
	turma: ITurmaGradeCleanDTO;
	variant: OffersChipType;
	currentContractEnrollments: IMatriculaDTO[];
	otherEnrollmentsData: IMatriculaDTO[];
}

export default function useMiniatura({ turma, variant, currentContractEnrollments, otherEnrollmentsData }: UseMiniaturaProps) {
	const { matricula, setMatricula } = useAlunoContext();

	const isTurmaSelected = matricula.turmas.some((selectedTurma) => selectedTurma.turma.id === turma.id);

	const { isUpgradePage } = useCheckIfPageIsUpgrade();

	const selectTurmaOnClick = () => {
		if (isTurmaSelected) {
			setMatricula((prev) => ({
				...prev,
				turmas: matricula.turmas.filter((selectedTurma) => selectedTurma.turma.id !== turma?.id),
			}));
		}
	};

	const [currentEnrollment, setCurrentEnrollment] = useState(false);

	const handleCurrentEnrollments = useCallback(() => {
		if (!isUpgradePage || !currentContractEnrollments || !turma) return;

		for (let i = 0; i < currentContractEnrollments.length; i++) {
			const isMatchingEnrollment = currentContractEnrollments[i].turma.id === turma.id;

			if (isMatchingEnrollment) return setCurrentEnrollment(true);
		}
	}, [currentContractEnrollments, isUpgradePage, turma]);

	const [otherEnrollments, setOtherEnrollments] = useState(false);

	const handleOtherPreviousEnrollments = useCallback(() => {
		if (!isUpgradePage || !otherEnrollmentsData || !turma) return;

		for (let i = 0; i < otherEnrollmentsData.length; i++) {
			const isMatchingEnrollment = otherEnrollmentsData[i].turma.id === turma.id;

			if (isMatchingEnrollment && !currentEnrollment) return setOtherEnrollments(true);
		}
	}, [isUpgradePage, otherEnrollmentsData, turma, currentEnrollment]);

	useEffect(() => {
		Promise.all([handleCurrentEnrollments(), handleOtherPreviousEnrollments()]);
	}, [handleCurrentEnrollments, handleOtherPreviousEnrollments]);

	const disabled = otherEnrollments || currentEnrollment;

	const turmaCinzaClass = otherEnrollments ? 'box-cinza' : '';

	const turmaSelectedClass = useCallback(() => {
		if (currentEnrollment) return 'azul-disabled';

		return isTurmaSelected && !otherEnrollments ? 'selected' : `${variant.replace('ô', 'o')}`;
	}, [currentEnrollment, isTurmaSelected, otherEnrollments, variant]);

	return {
		isTurmaSelected,
		turmaSelectedClass,
		selectTurmaOnClick,
		otherEnrollments,
		disabled,
		turmaCinzaClass,
	};
}
