import { useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Button, DatePicker, Progress } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import { useUnidadeContext } from '../../../context/UnidadeContext';
import { TipoDocumentoEnum } from '../../../enum/tipoDocumento.enum';
import '../style.css';
import { MenuRevisao } from './../../../components/MenuRevisao/index';
import { format, startOfDay } from 'date-fns';
import { formatDistanceStrict } from 'date-fns/esm';
import { ptBR } from 'date-fns/locale';

export const FranquiaCreateStep10: React.FunctionComponent = () => {
  const { unidade, setRevisao } = useUnidadeContext();
  const history = useHistory();
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  useEffect(() => {
    setRevisao(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const verifyDocClassName = (documento) => {
    if (unidade.unidadeDocumentos.find((doc) => doc.tipoDocumentoId === documento)) {
      return 'check-doc';
    }
    return 'check-doc-red';
  }

  const verifyDocIcon = (documento) => {
    if (unidade.unidadeDocumentos.find((doc) => doc.tipoDocumentoId === documento)) {
      return (
        <Unicons.UilCheck size="16" color="#FFFFFF" />
      );
    }
    return (
      <Unicons.UilTimes size="16" color="#FFFFFF" />
    );
  }

  const getQtdDocumentoPendente = () => {
    return 8 - unidade.unidadeDocumentos.reduce((acc, curr) => {
      if (acc.some(u => u.tipoDocumentoId === curr.tipoDocumentoId)) return acc;
      else return [...acc, curr];
    }, []).length;
  }
  // Assuming dataContrato is in the format "YYYY-MM-DD"
  const calculateYearsAndMonths = () => {
    const today = moment(new Date());
    const contractDate = moment(unidade?.dataContrato, "YYYY-MM-DD");
    const difInMonths = today.diff(contractDate, "months");
    let counter = 0;

    let remainingMonths = difInMonths;
    for (remainingMonths; remainingMonths >= 12; counter++) {
      setYears(years + 1);
      remainingMonths -= 12;
    }
    setMonths(remainingMonths);

  };


  useEffect(() => {
    calculateYearsAndMonths();
  }, [unidade?.dataContrato]);

  return (
    <div className="container-fondo">
      <Header />
      <div className="progress">
        <Progress className="progress-user" percent={100} strokeLinecap="square" showInfo={false} />
      </div>
      <MenuRevisao qtdDocumentoPendente={getQtdDocumentoPendente()} />
      <div className="franquia" style={{ top: 70 }}>
        <div className="franquia-row">
          <h5>Revisão de Dados</h5>
          <div className="row-revisao">
            <div className="box-revisao">
              <div className="dados">
                <Link to="/franquias/create">
                  <Button className="button-line">Editar</Button>
                </Link>
                <h4>Dados Básicos</h4>
                <div className="nome">
                  <h5>Nome da Franquia</h5>
                  <span>{unidade?.descricao}</span>
                </div>
                <div className="dados-franquia">
                  <div className="cpf"style={{marginRight:20}}>
                    <h6>CNPJ</h6>
                    <span>{unidade?.cnpj}</span>
                  </div>
                  <div className="cpf"style={{marginRight:20}}>
                    <h6>Inscrição Estadual</h6>
                    <span>{unidade?.inscricaoEstadual}</span>
                  </div>
                  <div className="cpf"style={{marginRight:20}}>
                    <h6>Razão social</h6>
                    <span>{unidade?.razaoSocial}</span>
                  </div>
                  <div className="cpf"style={{marginRight:20}}>
                    <h6>Tempo operação</h6>
                    <span>{years + (years === 1 ? ' ano ' : ' anos ') + months + (months === 1 ? ' mês ' : ' meses ')}</span>
                    <div className="cpf" style={{marginRight:20}}>
                      <h6>Telefone Comandante</h6>
                      <span>{unidade?.telefoneComandante}</span>
                    </div>
                    <div className="cpf"style={{marginRight:20}}>
                      <h6>Telefone Guerreiro</h6>
                      <span>{unidade?.telefoneGuerreiro}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <Link to="/franquias/create/step-2">
                  <Button className="button-line">Editar</Button>
                </Link>
                <h4>Localização</h4>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>CEP</h6>
                    <span>{unidade?.endereco.cep}</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>ENDEREÇO</h6>
                    <span>{unidade?.endereco?.logradouro}</span>
                  </div>
                  <div className="cpf">
                    <h6>NUMERO</h6>
                    <span>{unidade?.endereco?.numero}</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>COMPLEMENTO</h6>
                    <span>{unidade?.endereco?.complemento}</span>
                  </div>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <Link to="/franquias/create/step-3">
                  <Button className="button-line">Editar</Button>
                </Link>
                <h4>Contrato</h4>
                <div className="input-revisao">
                  <div className="input-franquia">
                    <div className="box-data">
                      <span>Assinatura</span>
                      <DatePicker format={'DD-MM-YYYY'} disabled={true} value={moment(unidade?.dataContrato)} placeholder={'Assinatura'} suffixIcon={null} />
                    </div>
                    <Unicons.UilCalendarAlt size="24" color="#6A7C96" />
                  </div>
                  <div className="input-franquia">
                    <div className="box-data">
                      <span>Abertura</span>
                      <DatePicker format={'DD-MM-YYYY'} disabled={true} value={moment(unidade?.dataAbertura)} placeholder={'Assinatura'} suffixIcon={null} />
                    </div>
                    <Unicons.UilCalendarAlt size="24" color="#6A7C96" />
                  </div>
                </div>
                <div className="input-revisao">
                  <div className="input-franquia">
                    <div className="box-data">
                      <span>Renovação</span>
                      <DatePicker format={'DD-MM-YYYY'} disabled={true} value={moment(unidade?.dataRenovacaoContrato)} placeholder={'Assinatura'} suffixIcon={null} />
                    </div>
                    <Unicons.UilCalendarAlt size="24" color="#6A7C96" />
                  </div>
                  <div className="input-franquia">
                    <div className="box-data">
                      <span>Término</span>
                      <DatePicker format={'DD-MM-YYYY'} disabled={true} value={moment(unidade?.dataTerminoContrato)} placeholder={'Assinatura'} suffixIcon={null} />
                    </div>
                    <Unicons.UilCalendarAlt size="24" color="#6A7C96" />
                  </div>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <Link to="/franquias/create/step-4">
                  <Button className="button-line">Editar</Button>
                </Link>
                <h4>Responsáveis</h4>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>RESPONSÁVEL LEGAL</h6>
                    <span>{unidade?.responsavelLegal?.nome}</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>GESTOR</h6>
                    <span>{unidade?.gestor?.nome}</span>
                  </div>
                </div>
              </div>
              <div className="line"></div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <Link to="/franquias/create/step-7">
                  <Button className="button-line">Editar</Button>
                </Link>
                <h4>Documentos</h4>
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className={verifyDocClassName(TipoDocumentoEnum.CTT)}>
                    {verifyDocIcon(TipoDocumentoEnum.CTT)}
                  </div>
                  <p style={{ marginBottom: 0 }}>Contrato</p>
                </div>
                {/* <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div> */}
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className={verifyDocClassName(TipoDocumentoEnum.CNDRFE)}>
                    {verifyDocIcon(TipoDocumentoEnum.CNDRFE)}
                  </div>
                  <p style={{ marginBottom: 0 }}>Certidão Negativa de Débito da Receita Federal e Estadual</p>
                </div>
                {/* <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div> */}
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className={verifyDocClassName(TipoDocumentoEnum.CNP)}>
                    {verifyDocIcon(TipoDocumentoEnum.CNP)}
                  </div>
                  <p style={{ marginBottom: 0 }}>Certidão Negativa da Prefeitura</p>
                </div>
                {/* <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div> */}
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className={verifyDocClassName(TipoDocumentoEnum.CDFCTCEF)}>
                    {verifyDocIcon(TipoDocumentoEnum.CDFCTCEF)}
                  </div>
                  <p style={{ marginBottom: 0 }}>Certidão dos Distribuidores Fiscal, Civel, Trabalhista e <br></br>Criminal nas esferas Estadual e Federal</p>
                </div>
                {/* <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div> */}
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className={verifyDocClassName(TipoDocumentoEnum.CPT)}>
                    {verifyDocIcon(TipoDocumentoEnum.CPT)}
                  </div>
                  <p style={{ marginBottom: 0 }}>Certidão de Protesto</p>
                </div>
                {/* <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div> */}
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className={verifyDocClassName(TipoDocumentoEnum.ACR)}>
                    {verifyDocIcon(TipoDocumentoEnum.ACR)}
                  </div>
                  <p style={{ marginBottom: 0 }}>Antecedentes criminais</p>
                </div>
                {/* <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div> */}
              </div>
              <div className="documentos">
                <div className="estado">
                  <div className={verifyDocClassName(TipoDocumentoEnum.DIR)}>
                    {verifyDocIcon(TipoDocumentoEnum.DIR)}
                  </div>
                  <p style={{ marginBottom: 0 }}>Declaração de Imposto de Renda dos últimos 2 anos</p>
                </div>
                {/* <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div> */}
              </div>
              <div className="documentos" style={{ paddingBottom: 30 }}>
                <div className="estado">
                  <div className={verifyDocClassName(TipoDocumentoEnum.LVB)}>
                    {verifyDocIcon(TipoDocumentoEnum.LVB)}
                  </div>
                  <p style={{ marginBottom: 0 }}>Laudo de vistoria dos bombeiros</p>
                </div>
                {/* <div className="detalhes">
                  <Unicons.UilAngleDown size="16" color="rgba(52, 68, 83, 0.5)" />
                </div> */}
              </div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <Link to="/franquias/create/step-9">
                  <Button className="button-line">Editar</Button>
                </Link>
                <h4>Classificação</h4>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>STATUS</h6>
                    <span>{unidade?.statusUnidade?.descricao}</span>
                  </div>
                </div>
                {/* <div className="dados-franquia">
                    <div className="cpf">
                      <h6>NEGOCIOS DE UNIDADE</h6>
                      <span>{unidade?.negocioUnidades.map(item => item['descricao']).join(', ')}</span>
                    </div>
                  </div> */}
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>MODELO</h6>
                    <span>{unidade?.modeloUnidade?.descricao}</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>Tipo Unidade</h6>
                    <span>{unidade?.tipoUnidade?.descricao}</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>Clusterização</h6>
                    <span>{unidade?.cluster?.descricao}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-revisao">
              <div className="dados">
                <Link to="/franquias/create/step-14">
                  <Button className="button-line">Editar</Button>
                </Link>
                <h4>Dados Bancários</h4>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>Número do banco</h6>
                    <span>{unidade?.banco}</span>
                  </div>
                </div>
                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>Número da agência</h6>
                    <span>{unidade?.agencia}</span>
                  </div>
                  <div className="cpf">
                    <h6>Dígito da agência</h6>
                    <span>{unidade?.digitoAgencia}</span>
                  </div>
                </div>

                <div className="dados-franquia">
                  <div className="cpf">
                    <h6>Número da conta</h6>
                    <span>{unidade?.conta}</span>
                  </div>
                  <div className="cpf">
                    <h6>Dígito da conta</h6>
                    <span>{unidade?.digitoConta}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="botoes" style={{ paddingBottom: 30 }}>
            <Link to="/franquias/create/step-9">
              <Button className="button-second" style={{ width: 345 }}>Voltar</Button>
            </Link>
            <div onClick={() => saveFranquia()} className="button-primary" style={{ width: 345 }}><span>Salvar Unidade</span></div>
          </div> */}
        </div>
      </div>
      <Link to="/franquias">
        <div className="fechar"><Unicons.UilTimes size="20" color="#353F49" /></div>
      </Link>
      <div className="botao-voltar-revisao">
        <Button className="button-second" icon={<ArrowLeftOutlined rev={undefined} />} onClick={() => history.goBack()}>
          Voltar
        </Button>
      </div>
    </div>
  )
}
