import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/private-route/private-route';
import { AlunoContextProvider } from './context/AlunoContext';
import { CampanhaContextProvider } from './context/CampanhaContext';
import { ColaboradorContextProvider } from './context/ColaboradorContext';
import { CursoContextProvider } from './context/CursoContext';
import { CursoFranquiaContextProvider } from './context/CursoFranquiaContext';
import { EscolaContextProvider } from './context/EscolaContext';
import { HoraSedeContextProvider } from './context/HoraSedeContext';
import { NegocioContextProvider } from './context/NegocioContext';
import { SalaContextProvider } from './context/SalaContext';
import { TaxaContextProvider } from './context/TaxaContext';
import { TurmaContextProvider } from './context/TurmaContext';
import { UnidadeContextProvider } from './context/UnidadeContext';
import { UsuarioContextProvider } from './context/UsuarioContext';
import { AcademyConecta } from './pages/Academy/AcaConecta/Conecta';
import ConectaCreate from './pages/Academy/AcaConecta/Conecta/conecta-create';
import { AcademyAulas } from './pages/Academy/AcaMaterial/Aulas';
import aulaCreate from './pages/Academy/AcaMaterial/Aulas/aula-create';
import { AcademyAulasCurriculos } from './pages/Academy/AcaMaterial/AulasCurriculo';
import aulaCurriculoStep1Create from './pages/Academy/AcaMaterial/AulasCurriculo/step1';
import { AcademyAulasProdutoEscola } from './pages/Academy/AcaMaterial/AulasProdutoEscola';
import aulaProdutoEscolaStep1Create from './pages/Academy/AcaMaterial/AulasProdutoEscola/step1';
import { AcademyCurso } from './pages/Academy/AcaMaterial/Cursos';
import { AcademyMaterial } from './pages/Academy/AcaMaterial/Material';
import { AcademyCurriculoMaterial } from './pages/Academy/AcaMaterial/MaterialCurriculo';
import { AcademyProdutoEscolaMaterial } from './pages/Academy/AcaMaterial/MaterialProdutoEscola';
import { AcademyQuestoes } from './pages/Academy/AcaQuestoes/Questoes';
import { AcademyAcaAula } from './pages/Academy/AcaTrilha/Aulas';
import AcaAulaCreate from './pages/Academy/AcaTrilha/Aulas/aula-create';
import { AcademyAcaCurso } from './pages/Academy/AcaTrilha/Cursos';
import CursoCreate from './pages/Academy/AcaTrilha/Cursos/curso-create';
import { AcademyAcaTopico } from './pages/Academy/AcaTrilha/Topico';
import AcaTopicoCreate from './pages/Academy/AcaTrilha/Topico/topico-create';
import { AcademyTrilha } from './pages/Academy/AcaTrilha/Trilhas';
import TrilhaCreate from './pages/Academy/AcaTrilha/Trilhas/trilha-create';
import { AcademyWebinar } from './pages/Academy/AcaWebinar/Webinar';
import webinarCreate from './pages/Academy/AcaWebinar/Webinar/webinar-create';
import { AlunosDetalhe } from './pages/Alunos/detalhe';
import { Alunos } from './pages/Alunos/pages/alunos/alunos.screen';
import { AlunosDetalheMatricula } from './pages/Alunos/pages/detalhe-matricula/detalhe-matricula.page';
import { AlunosCreateStep1 } from './pages/Alunos/step-1';
import { AlunosCreateStep2 } from './pages/Alunos/step-2';
import { AlunosCreateStep3 } from './pages/Alunos/step-3';
import { AlunosCreateStep4 } from './pages/Alunos/step-4';
import { AlunosCreateStep5 } from './pages/Alunos/step-5';
import { AlunosTransferenciaTurma } from './pages/Alunos/transferencia-turma';
import AlunosUpgradeCobranca from './pages/Alunos/upgrade-turma';
import Autonomia from './pages/Autonomia/index';
import Campanhas from './pages/Campanhas/index';
import CampanhasCreateStep1 from './pages/Campanhas/step-1';
import CampanhasCreateStep2 from './pages/Campanhas/step-2';
import CampanhasCreateStep3 from './pages/Campanhas/step-3';
import CampanhasCreateStep4 from './pages/Campanhas/step-4';
import CampanhasCreateStep5 from './pages/Campanhas/step-5';
import CampanhasCreateStep6 from './pages/Campanhas/step-6';
import CheckoutSede from './pages/Checkout copy';
import CheckoutEscola from './pages/CheckoutEscola';
import CheckoutNew from './pages/CheckoutNew';
import { Colaboradores } from './pages/Colaboradores/index';
import { ColaboradoresCreateStep1 } from './pages/Colaboradores/step-1';
import { ColaboradoresCreateStep2 } from './pages/Colaboradores/step-2';
import { ColaboradoresCreateStep3 } from './pages/Colaboradores/step-3';
import { ColaboradoresCreateStep4 } from './pages/Colaboradores/step-4';
import { Competencias } from './pages/Competencias';
import CompetenciaCreate from './pages/Competencias/competencia-create';
import { Conteudos } from './pages/Conteudos';
import ConteudoCreate from './pages/Conteudos/conteudo-create';
import { Crm } from './pages/Crm';
import { CrmEscola } from './pages/CrmEscola';
import Curriculo from './pages/Curriculos';
import CurriculoCreateStep1 from './pages/Curriculos/step-1';
import { Cursos } from './pages/Cursos/index';
import { CursosCreateStep1 } from './pages/Cursos/step-1';
import { CursosCreateStep2 } from './pages/Cursos/step-2';
import { CursosCreateStep3 } from './pages/Cursos/step-3';
import { CursosCreateStep4 } from './pages/Cursos/step-4';
import { CursosCreateStep5 } from './pages/Cursos/step-5';
import { CursosCreateStep6 } from './pages/Cursos/step-6';
import { CursoFranquia } from './pages/CursosFranquia/index';
import { CursoFranquiaRevisao } from './pages/CursosFranquia/step-2';
import { CursoFranquiaRevisaoFinal } from './pages/CursosFranquia/step-3';
import { CursoFranquiaTurma } from './pages/CursosFranquia/turma';
import { OfertaCursoHub } from './pages/CursosOfertaHub';
import { CursosOfertaCreateStep1 } from './pages/CursosOfertaHub/step-1';
import { CursosOfertaCreateStep2 } from './pages/CursosOfertaHub/step-2';
import { CursosOfertaCreateStep3 } from './pages/CursosOfertaHub/step-3';
import { Dashboard } from './pages/Dashboard';
import DashboardDetalhes from './pages/Dashboard/detalhes';
import { Franquia } from './pages/Dashboard/franquia';
import { EscolaCreateResponsavelScreen } from './pages/Escola/screens/escola-create-responsavel.screen';
import EscolaCreateStep1Screen from './pages/Escola/screens/escola-create-step-1.screen';
import { EscolaCreateStep2Screen } from './pages/Escola/screens/escola-create-step-2.screen';
import { EscolaCreateStep3Screen } from './pages/Escola/screens/escola-create-step-3.screen';
import { EscolaCreateStepRevisaoScreen } from './pages/Escola/screens/escola-create-step-revisao.screen';
import EscolaScreen from './pages/Escola/screens/escola.screen';
import { Excelencia } from './pages/Excelencia';
import { falaComandante } from './pages/FalaComandante';
import FaqB2B from './pages/FaqB2B';
import FaqB2C from './pages/FaqB2C';
import { Financeiro } from './pages/Financeiro/index';
import { FinanceiroSede } from './pages/FinanceiroSede/index';
import { FranquiaCreateGestor } from './pages/Franquias/Dados/gestor';
import { FranquiaCreateResponsavel } from './pages/Franquias/Dados/responsavel';
import { FranquiaCreateSocio } from './pages/Franquias/Dados/socio';
import FranquiaCreate from './pages/Franquias/Dados/step-1';
import { FranquiaCreateStep10 } from './pages/Franquias/Dados/step-10';
import FranquiaCreateSept11 from './pages/Franquias/Dados/step-11';
import FranquiaCreateSept12 from './pages/Franquias/Dados/step-12';
import FranquiaCreateSept13 from './pages/Franquias/Dados/step-13';
import FranquiaCreateBanco from './pages/Franquias/Dados/step-14';
import { FranquiaCreateStep2 } from './pages/Franquias/Dados/step-2';
import { FranquiaCreateStep3 } from './pages/Franquias/Dados/step-3';
import { FranquiaCreateStep4 } from './pages/Franquias/Dados/step-4';
import FranquiaCreateSept5 from './pages/Franquias/Dados/step-5';
import FranquiaCreateSept6 from './pages/Franquias/Dados/step-6';
import { FranquiaCreateStep7 } from './pages/Franquias/Dados/step-7';
import FranquiaCreateSept8 from './pages/Franquias/Dados/step-8';
import { FranquiaCreateStep9 } from './pages/Franquias/Dados/step-9';
import VinculoExterno from './pages/Franquias/Dados/vinculo-externo';
import VinculoTipoPagamento from './pages/Franquias/Dados/vinculo-tipo-pagamento';
import GrupoFinanceiroListagemScreen from './pages/GrupoFinanceiro/screens/grupo-financeiro-listagem.screen';
import GrupoContratoCreateStep1Screen from './pages/Grupos/screens/grupo-contrato-create-step-1.screen';
import { GrupoContratoCreateStep2Screen } from './pages/Grupos/screens/grupo-contrato-create-step-2.screen';
import { GrupoContratoCreateStep3Screen } from './pages/Grupos/screens/grupo-contrato-create-step-3.screen';
import { GrupoContratoCreateStepRevisaoScreen } from './pages/Grupos/screens/grupo-contrato-create-step-revisao.screen';
import GrupoContratoListagemScreen from './pages/Grupos/screens/grupo-contrato-listagem.screen';
import { GrupoCreateResponsavelScreen } from './pages/Grupos/screens/grupo-create-responsavel.screen';
import GrupoCreateStep1Screen from './pages/Grupos/screens/grupo-create-step-1.screen';
import { GrupoCreateStep2Screen } from './pages/Grupos/screens/grupo-create-step-2.screen';
import { GrupoCreateStep3Screen } from './pages/Grupos/screens/grupo-create-step-3.screen';
import { GrupoCreateStepRevisaoScreen } from './pages/Grupos/screens/grupo-create-step-revisao.screen';
import EscolaGrupo from './pages/Grupos/screens/grupo-listagem.screen';
import { Habilidades } from './pages/Habilidades';
import HabilidadesCreate from './pages/Habilidades/habilidades-create';
import { HappyGPT } from './pages/HappyGpt';
import { Home } from './pages/Home';
import HoraSede from './pages/HoraSede';
import { HoraSedeCreateStep3 } from './pages/HoraSede/pages/step-3/step-3.page';
import { HoraSedeCreateStep4 } from './pages/HoraSede/pages/step-4/step-4.page';
import HoraSedeCreateStep1 from './pages/HoraSede/step-1';
import HoraSedeCreateStep2 from './pages/HoraSede/step-2';
import { HoraSedeCreateStep6 } from './pages/HoraSede/step-6';
import { HoraUnidade } from './pages/HoraUnidade';
import { HoraUnidadeCreateStep1 } from './pages/HoraUnidade/step-1';
import { HoraUnidadeCreateStep2 } from './pages/HoraUnidade/step-2';
import { HoraUnidadeCreateStep3 } from './pages/HoraUnidade/step-3';
import { Instrutor } from './pages/Instrutor';
import { Insiginia } from './pages/Insígnia';
import InsiginiaCreate from './pages/Insígnia/insignia-create';
import LearnBanner from './pages/LearnBanner';
import BannerCreate from './pages/LearnBanner/banner-create';
import { LearnPush } from './pages/LearnPush';
import LearnPushCreate from './pages/LearnPush/learn-push-create';
import { Login } from './pages/Login';
import { MatriculasCreateStep3 } from './pages/Matriculas/pages/step-3/step-3.page';
import { MatriculasCreateStep1 } from './pages/Matriculas/step-1';
import { MatriculasCreateStep2 } from './pages/Matriculas/step-2';
import { MatriculasCreateStep4 } from './pages/Matriculas/step-4';
import { MatriculasCreateStep5 } from './pages/Matriculas/step-5';
import { Medalhas } from './pages/Medalhas';
import MedalhaCreate from './pages/Medalhas/medalha-create';
import MensagemHome from './pages/MensagemHome';
import MensagemHomeCreate from './pages/MensagemHome/mensagem-create';
import { MetasUnidade } from './pages/MetaUnidade';
import MetaUnidadeCreate from './pages/MetaUnidade/meta-unidade-create';
import { Metas } from './pages/Metas';
import MetaCreate from './pages/Metas/meta-create';
import { MetasTipo } from './pages/MetasTipo';
import MetaTipoCreate from './pages/MetasTipo/meta-tipo-create';
import Negocio from './pages/Negocio';
import NegocioCreateStep1 from './pages/Negocio/step-1';
import NegocioCreateStep2 from './pages/Negocio/step-2';
import { NegocioCreateStep3 } from './pages/Negocio/step-3';
import { NegocioCreateStep4 } from './pages/Negocio/step-4';
import { NegocioCreateStep6 } from './pages/Negocio/step-6';
import { OfertaNegocioHub } from './pages/NegocioOfertaHub';
import { NegocioOfertaCreateStep1 } from './pages/NegocioOfertaHub/step-1';
import { NegocioOfertaCreateStep2 } from './pages/NegocioOfertaHub/step-2';
import { NegocioOfertaCreateStep3 } from './pages/NegocioOfertaHub/step-3';
import { Noticias } from './pages/Noticias';
import NoticiasCreate from './pages/Noticias/noticia-create';
import { OfferPage } from './pages/Offer/offer.page';
import PaginaColetaInformacao from './pages/PaginaColetaInformacao';
import ProdutoEscola from './pages/ProdutoEscola';
import ProdutoEscolaCreateStep1 from './pages/ProdutoEscola/step-1';
import { Quiz } from './pages/Quiz';
import QuizCreate from './pages/Quiz/quiz-create';
import { RecuperarSenha } from './pages/RecuperarSenha';
import Recurso from './pages/Recurso';
import RecursoCreateStep1 from './pages/Recurso/step-1';
import DashboardPowerBi from './pages/Relatorios/DashboardReport/dashboard-powerbi.screen';
import RelFinanceiroDetalhado from './pages/Relatorios/RelatorioHub/FinanceiroDetalhado/financeiro-detalhado';
import RelMatriculaNovo from './pages/Relatorios/RelatorioHub/Matricula/index';
import RelNotasFiscal from './pages/Relatorios/RelatorioHub/NotaFiscal';
import RelConciliacaoBancariaNew from './pages/Relatorios/RelatorioHub/RelatorioConciliacaoBancaria/conciliacao';
import RelRematriculaNovo from './pages/Relatorios/RelatorioHub/Rematricula';
import RelAniversario from './pages/Relatorios/RelatorioHub/aniversario';
import RelConciliacaoBancaria from './pages/Relatorios/RelatorioHub/conciliacao';
import RelConciliacaoBancariaEscolas from './pages/Relatorios/RelatorioHub/conciliacao-escolas';
import RelFinanceiro from './pages/Relatorios/RelatorioHub/financeiro';
import RelFinanceiroEscola from './pages/Relatorios/RelatorioHub/financeiro-escolas';
import RelInadimplenciaHub from './pages/Relatorios/RelatorioHub/inadimplenciaHub';
import Relatorio from './pages/Relatorios/RelatorioHub/index';
import RelMatriculaSedeCurriculoPrematriculado from './pages/Relatorios/RelatorioHub/matricula-diaria-franquia-curriculo-prematriculado';
import RelNotas from './pages/Relatorios/RelatorioHub/notas';
import RelProgressoTrilhaInstrutorAcademy from './pages/Relatorios/RelatorioHub/progressoTrilhaInstrutor';
import RelProgressoTrilhaInstrutorAcademySede from './pages/Relatorios/RelatorioHub/progressoTrilhaInstrutorSede';
import RelRegistroAvaliacao from './pages/Relatorios/RelatorioHub/registro-avaliacao';
import RelRegistroTarefa from './pages/Relatorios/RelatorioHub/registro-tarefa';
import RelRegistroFrequenciaAlunoAcademy from './pages/Relatorios/RelatorioHub/registroFrequenciaAluno';
import RelRegistroFrequenciaTurmaAcademy from './pages/Relatorios/RelatorioHub/registroFrequenciaTurma';
import RelatorioSede from './pages/Relatorios/RelatorioSede';
import RelFinanceiroDetalhadoSede from './pages/Relatorios/RelatorioSede/FinanceiroDetalhado/financeiro-detalhado';
import RelMatriculaNovoSede from './pages/Relatorios/RelatorioSede/Matricula';
import RelConciliacaoBancariaSedeNew from './pages/Relatorios/RelatorioSede/RelatorioConciliacaoBancaria/conciliacao';
import RelRematriculaSedeNovo from './pages/Relatorios/RelatorioSede/Rematricula';
import RelConciliacaoBancariaSede from './pages/Relatorios/RelatorioSede/conciliacao-sede';
import RelConsolidacaoEscolaSede from './pages/Relatorios/RelatorioSede/consolidacao-escola';
import RelEscolaEscola from './pages/Relatorios/RelatorioSede/contrato-escolas';
import RelEvolucaoLicencaEscolaSede from './pages/Relatorios/RelatorioSede/evolucao-licenca-escola';
import RelFinanceiroEscolaSede from './pages/Relatorios/RelatorioSede/financeiro-escolas';
import RelFinanceiroPagos from './pages/Relatorios/RelatorioSede/financeiro-pagos';
import RelFinanceiroSede from './pages/Relatorios/RelatorioSede/financeiro-sede';
import RelFinanceiroSede2 from './pages/Relatorios/RelatorioSede/financeiro-sede2';
import RelInadimplencia from './pages/Relatorios/RelatorioSede/inadimplencia';
import RelMatriculaSede from './pages/Relatorios/RelatorioSede/matricula-diaria-sede';
import RelMatriculaSedeCurriculo from './pages/Relatorios/RelatorioSede/matricula-diaria-sede-curriculo';
import RelMatriculaNaoEfetivada from './pages/Relatorios/RelatorioSede/matricula-nao-efetivada';
import RelNotasSede from './pages/Relatorios/RelatorioSede/notas-sede';
import Rel3k from './pages/Relatorios/RelatorioSede/relatorio-3k';
import RelExelenciaNotaTotal from './pages/Relatorios/RelatorioSede/relatorio-exelencia-nota-total';
import { RotinasAtualizacao } from './pages/RotinasAtualizacao';
import Sala from './pages/Sala';
import SalaCreateStep1 from './pages/Sala/step-1';
import SalaCreateStep2 from './pages/Sala/step-2';
import { Senha } from './pages/Senha';
import Stemplay from './pages/Stemplay';
import StemplayAlunos from './pages/Stemplay/alunos';
import StemplayTurmaCreate from './pages/Stemplay/create';
import StemplayTurmasDetalhe from './pages/Stemplay/detalhe';
import StemplayTurmaEdit from './pages/Stemplay/edit';
import StemplayPainel from './pages/Stemplay/painel';
import StemplayTurmasProgresso from './pages/Stemplay/progresso';
import StemplayRelatorioIndividual from './pages/Stemplay/relatorio-individual';
import StemplayTurmas from './pages/Stemplay/turmas';
import Taxas from './pages/Taxas/index';
import TaxasCreateStep1 from './pages/Taxas/step-1';
import TaxasCreateStep2 from './pages/Taxas/step-2';
import TaxasCreateStep3 from './pages/Taxas/step-3';
import TaxasCreateStep4 from './pages/Taxas/step-4';
import TaxasCreateStep6 from './pages/Taxas/step-6';
import { TipoConteudo } from './pages/TipoConteudo';
import TipoConteudoCreate from './pages/TipoConteudo/tipoConteudo-create';
import { Turmas } from './pages/Turmas/index';
import { LancamentoAula } from './pages/Turmas/lancamento-aula';
import { TurmasObservacoes } from './pages/Turmas/observacoes';
import { TurmasDetalhe } from './pages/Turmas/pages/detalhe/detalhe.page';
import { TurmasSkills } from './pages/Turmas/skills';
import { TurmasCreateStep1 } from './pages/Turmas/step-1';
import { TurmasCreateStep2 } from './pages/Turmas/step-2';
import { TurmasCreateStep3 } from './pages/Turmas/step-3';
import UsuariosCreateFranquiaStep1 from './pages/Usuarios/franquia-step-1';
import UsuariosCreateFranquiaStep2 from './pages/Usuarios/franquia-step-2';
import UsuariosCreateFranquiaStep3 from './pages/Usuarios/franquia-step-3';
import UsuariosCreateFranquiaStep4 from './pages/Usuarios/franquia-step-4';
import UsuariosCreateFranquiaStepFuncao from './pages/Usuarios/franquia-step-funcao';
import { Usuarios } from './pages/Usuarios/index';
import { Profile } from './pages/Usuarios/profile';
import RecuperacaoSenha from './pages/Usuarios/recuperacao';
import UserSenha from './pages/Usuarios/senha';
import UsuariosCreateStep1 from './pages/Usuarios/step-1';
import UsuariosCreateStep2 from './pages/Usuarios/step-2';
import UsuariosCreateStep3 from './pages/Usuarios/step-3';
import UsuariosCreateStep4 from './pages/Usuarios/step-4';
import UsuariosCreateStep5 from './pages/Usuarios/step-5';
import UsuariosCreateStep6 from './pages/Usuarios/step-6';
import UsuariosCreateStep7 from './pages/Usuarios/step-7';
import UsuariosCreateStep8 from './pages/Usuarios/step-8';
import UsuariosCreateStep9 from './pages/Usuarios/step-9';
import { Brasao } from './pages/brasao';
import BrasaoCreate from './pages/brasao/brasao-create';
import { AcademyQuestoesCreate } from './pages/Academy/AcaQuestoes/Questoes/questoes-create';
import AcademyMaterialDeApoio from './pages/Academy/AcaMaterialApoio/Pasta';
import AcademyPastaMaterialApoio from './pages/Academy/AcaMaterialApoio/Pasta';
import AcademyPastaMaterialApoioCreate from './pages/Academy/AcaMaterialApoio/Pasta/pasta-create';
import AcademyMaterialApoio from './pages/Academy/AcaMaterialApoio/MaterialApoio';
import AcademyMaterialApoioCreate from './pages/Academy/AcaMaterialApoio/MaterialApoio/material-create';

const Routes: React.FunctionComponent = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/push-learn" exact component={LearnPush}></Route>
				<Route path="/push-learn/:idPush?/create" exact component={LearnPushCreate}></Route>
				<Route path="/banner-learn" exact component={LearnBanner}></Route>
				<Route path="/banner-learn-create/:idBanner?" exact component={BannerCreate}></Route>
				{/* <Route path="/checkout/:id/:idTipo?" exact component={Checkout}></Route> */}
				<Route path="/checkout/:id/:idTipo?" exact component={CheckoutNew}></Route>
				<Route path="/checkout-escola/:idPedido/:idTipo?" exact component={CheckoutEscola}></Route>
				<Route path="/checkout-sede/:id/:multa?" exact component={CheckoutSede}></Route>
				<Route path="/relatorio/:id/:idsec" exact component={DashboardPowerBi}></Route>
				<Route path="/conteudos" exact component={Conteudos}></Route>
				<Route path="/conteudos/:idConteudo?/create" exact component={ConteudoCreate}></Route>
				<Route path="/competencias" exact component={Competencias}></Route>
				<Route path="/competencias/:idCompetencia?/create" exact component={CompetenciaCreate}></Route>
				<Route path="/habilidades" exact component={Habilidades}></Route>
				<Route path="/habilidades/:idHabilidade?/create" exact component={HabilidadesCreate}></Route>
				<Route path="/brasao" exact component={Brasao}></Route>
				<Route path="/brasao/:idBrasao?/create" exact component={BrasaoCreate}></Route>
				<Route path="/tipo-conteudo" exact component={TipoConteudo}></Route>
				<Route path="/tipo-conteudo/:idTipoConteudo?/create" exact component={TipoConteudoCreate}></Route>
				<Route path="/medalhas" exact component={Medalhas}></Route>
				<Route path="/medalhas/:idMedalha?/create" exact component={MedalhaCreate}></Route>
				<Route path="/insignia" exact component={Insiginia}></Route>
				<Route path="/insignia/:idInsignia?/create" exact component={InsiginiaCreate}></Route>
				<Route path="/noticias" exact component={Noticias}></Route>
				<Route path="/noticias/:idNoticia?/create" exact component={NoticiasCreate}></Route>
				<Route path="/quiz" exact component={Quiz}></Route>
				<Route path="/quiz/:idQuiz?/create" exact component={QuizCreate}></Route>
				<Route path="/login" exact component={Login}></Route>
				<Route path="/senha" exact component={Senha}></Route>
				<Route path="/recuperar-senha/:token" exact component={RecuperarSenha}></Route>
				<Route path="/coleta/complemento-informacao/:leadCodigoId" exact component={PaginaColetaInformacao}></Route>
				<PrivateRoute path="/excelencia" exact component={Excelencia}></PrivateRoute>
				<PrivateRoute path="/" exact component={Home}></PrivateRoute>
				<PrivateRoute path="/offer-page" exact component={OfferPage}></PrivateRoute>
				<PrivateRoute path="/relatorio/aniversario" exact component={RelAniversario}></PrivateRoute>
				<PrivateRoute path="/relatorio/conciliacao" exact component={RelConciliacaoBancaria}></PrivateRoute>
				<PrivateRoute path="/relatorio/conciliacao-new" exact component={RelConciliacaoBancariaNew}></PrivateRoute>
				<PrivateRoute path="/relatorio/conciliacao-escola" exact component={RelConciliacaoBancariaEscolas}></PrivateRoute>
				{/* <PrivateRoute
					path="/relatorio/matricula"
					exact
					component={RelMatricula}
				></PrivateRoute> */}
				<PrivateRoute path="/relatorio/matricula" exact component={RelMatriculaNovo}></PrivateRoute>
				<PrivateRoute path="/relatorio/financeiro" exact component={RelFinanceiro}></PrivateRoute>
				<PrivateRoute path="/relatorio/financeiro-escola" exact component={RelFinanceiroEscola}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/matricula" exact component={RelMatriculaNovoSede}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/financeiro-escola" exact component={RelFinanceiroEscolaSede}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/evolucao-licenca-escola" exact component={RelEvolucaoLicencaEscolaSede}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/consolidacao-escola" exact component={RelConsolidacaoEscolaSede}></PrivateRoute>
				{/* <PrivateRoute
					path="/relatorio/rematricula"
					exact
					component={RelRematricula}
				></PrivateRoute> */}
				<PrivateRoute path="/relatorio/rematricula" exact component={RelRematriculaNovo}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/rematricula" exact component={RelRematriculaSedeNovo}></PrivateRoute>
				<PrivateRoute path="/relatorio/notas" exact component={RelNotas}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/notas-sede" exact component={RelNotasSede}></PrivateRoute>
				<PrivateRoute path="/relatorio/inadimplencia-hub" exact component={RelInadimplenciaHub}></PrivateRoute>
				<PrivateRoute path="/relatorio" exact component={Relatorio}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede" exact component={RelatorioSede}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/financeiro-sede" exact component={RelFinanceiroSede}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/financeiro-sede2" exact component={RelFinanceiroSede2}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/financeiro-detalhado-sede" exact component={RelFinanceiroDetalhadoSede}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/matricula-nao-efetivada" exact component={RelMatriculaNaoEfetivada}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/financeiro-pagos" exact component={RelFinanceiroPagos}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/matricula-diaria-sede" exact component={RelMatriculaSede}></PrivateRoute>
				<PrivateRoute
					path="/relatorio-sede/matricula-diaria-sede-curriculo-prematriculado"
					exact
					component={RelMatriculaSedeCurriculoPrematriculado}
				></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/matricula-diaria-sede-curriculo" exact component={RelMatriculaSedeCurriculo}></PrivateRoute>
				<PrivateRoute
					path="/relatorio-sede/matricula-diaria-franquia-curriculo-prematriculado"
					exact
					component={RelMatriculaSedeCurriculoPrematriculado}
				></PrivateRoute>
				{/* <PrivateRoute
					path="/relatorio-sede/matricula-diaria-franquia-curriculo"
					exact
					component={RelMatriculaFranquiaCurriculo}
				></PrivateRoute> */}
				<PrivateRoute path="/relatorio-sede/conciliacao-sede" exact component={RelConciliacaoBancariaSede}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/conciliacao-sede-new" exact component={RelConciliacaoBancariaSedeNew}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/contrato-escola" exact component={RelEscolaEscola}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/inadimplencia" exact component={RelInadimplencia}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/relatorio-3k" exact component={Rel3k}></PrivateRoute>
				<PrivateRoute path="/relatorio/registro-frequencia-turma-academy" exact component={RelRegistroFrequenciaTurmaAcademy}></PrivateRoute>
				<PrivateRoute path="/relatorio/registro-frequencia-aluno-academy" exact component={RelRegistroFrequenciaAlunoAcademy}></PrivateRoute>
				<PrivateRoute path="/relatorio/progresso-trilha-instrutor-academy" exact component={RelProgressoTrilhaInstrutorAcademy}></PrivateRoute>
				<PrivateRoute path="/relatorio/progresso-trilha-instrutor-academy-sede" exact component={RelProgressoTrilhaInstrutorAcademySede}></PrivateRoute>
				<PrivateRoute path="/relatorio/registro-avaliacao" exact component={RelRegistroAvaliacao}></PrivateRoute>
				<PrivateRoute path="/relatorio/registro-tarefa" exact component={RelRegistroTarefa}></PrivateRoute>
				<PrivateRoute path="/relatorio/financeiro-detalhado" exact component={RelFinanceiroDetalhado}></PrivateRoute>
				<PrivateRoute path="/relatorio/nota-fiscal" exact component={RelNotasFiscal}></PrivateRoute>
				<PrivateRoute path="/relatorio-sede/relatorio-exelencia-nota-total" exact component={RelExelenciaNotaTotal}></PrivateRoute>
				<PrivateRoute path="/happy-gpt" exact component={HappyGPT}></PrivateRoute>
				<PrivateRoute path="/fala-comandante" exact component={falaComandante}></PrivateRoute>
				<PrivateRoute path="/usuarios/senha" exact component={UserSenha}></PrivateRoute>
				<PrivateRoute path="/usuarios/senha/recuperacao" exact component={RecuperacaoSenha}></PrivateRoute>
				<PrivateRoute path="/usuarios/profile" exact component={Profile}></PrivateRoute>
				<PrivateRoute path="/autonomia" exact component={Autonomia}></PrivateRoute>
				<PrivateRoute path="/franquia" exact component={Franquia}></PrivateRoute>
				<PrivateRoute path="/mensagem-home" exact component={MensagemHome}></PrivateRoute>
				<PrivateRoute path="/mensagem-home-create/:id?" exact component={MensagemHomeCreate}></PrivateRoute>
				<PrivateRoute path="/rodar-rotinas" exact component={RotinasAtualizacao}></PrivateRoute>
				<PrivateRoute path="/instrutor" exact component={Instrutor}></PrivateRoute>
				<PrivateRoute path="/financeiro" exact component={Financeiro}></PrivateRoute>
				<PrivateRoute path="/financeiro/:nomeAluno" exact component={Financeiro}></PrivateRoute>
				<PrivateRoute path="/financeiro-sede" exact component={FinanceiroSede}></PrivateRoute>
				<PrivateRoute path="/faq/b2c" exact component={FaqB2C}></PrivateRoute>
				<PrivateRoute path="/faq/b2b" exact component={FaqB2B}></PrivateRoute>
				<PrivateRoute path="/stemplay" exact component={Stemplay}></PrivateRoute>
				<PrivateRoute path="/stemplay/turmas" exact component={StemplayTurmas}></PrivateRoute>
				<PrivateRoute path="/stemplay/detalhe" exact component={StemplayTurmasDetalhe}></PrivateRoute>
				<PrivateRoute path="/stemplay/create" exact component={StemplayTurmaCreate}></PrivateRoute>
				<PrivateRoute path="/stemplay/edit" exact component={StemplayTurmaEdit}></PrivateRoute>
				<PrivateRoute path="/stemplay/progresso" exact component={StemplayTurmasProgresso}></PrivateRoute>
				<PrivateRoute path="/stemplay/relatorio-individual" exact component={StemplayRelatorioIndividual}></PrivateRoute>
				<PrivateRoute path="/stemplay/alunos" exact component={StemplayAlunos}></PrivateRoute>
				<PrivateRoute path="/stemplay/painel" exact component={StemplayPainel}></PrivateRoute>

				<PrivateRoute path="/academy/gestao-material/cursos" exact component={AcademyCurso}></PrivateRoute>
				<PrivateRoute path="/academy/gestao-material/curriculos/:idCurriculo/aulas" exact component={AcademyAulasCurriculos}></PrivateRoute>
				<PrivateRoute path="/academy/gestao-material/cursos/:idCurso/aulas/:idAula/material" exact component={AcademyMaterial}></PrivateRoute>
				
				<PrivateRoute path="/academy/gestao-pasta-material" exact component={AcademyPastaMaterialApoio}></PrivateRoute> 
				<PrivateRoute path="/academy/gestao-pasta-material/create/:idPasta?" exact component={AcademyPastaMaterialApoioCreate}></PrivateRoute> 
				<PrivateRoute path="/academy/gestao-pasta-material/:idPasta?/materiais-apoio" exact component={AcademyMaterialApoio}></PrivateRoute>
				<PrivateRoute path="/academy/gestao-pasta-material/:idPasta?/materiais-apoio/create/:idMaterialApoio?" exact component={AcademyMaterialApoioCreate}></PrivateRoute>


				<PrivateRoute path="/academy/gestao-material/curriculos/:idCurriculo/aulas/:idAula/material" exact component={AcademyCurriculoMaterial}></PrivateRoute>

				<PrivateRoute path="/academy/gestao-material/curriculo/:idCurriculo" exact component={AcademyAulasCurriculos}></PrivateRoute>

				<PrivateRoute path="/academy/aulas/curriculos/:idCurriculo/create" exact component={aulaCurriculoStep1Create}></PrivateRoute>
				<PrivateRoute path="/academy/aulas/curriculos/:idCurriculo/create/:idAula" exact component={aulaCurriculoStep1Create}></PrivateRoute>

				<PrivateRoute path="/academy/gestao-material/produto-escolas/:idProdutoEscola" exact component={AcademyAulasProdutoEscola}></PrivateRoute>
				<PrivateRoute path="/academy/aulas/produto-escolas/:idProdutoEscola/create" exact component={aulaProdutoEscolaStep1Create}></PrivateRoute>
				<PrivateRoute path="/academy/aulas/produto-escolas/:idProdutoEscola/create/:idAula" exact component={aulaProdutoEscolaStep1Create}></PrivateRoute>
				<PrivateRoute
					path="/academy/gestao-material/produto-escolas/:idProdutoEscola/aulas/:idAula/material"
					exact
					component={AcademyProdutoEscolaMaterial}
				></PrivateRoute>

				<PrivateRoute path="/academy/gestao-trilha/trilha" exact component={AcademyTrilha}></PrivateRoute>

				<PrivateRoute path="/academy/gestao-material/cursos/:idCurso/aulas" exact component={AcademyAulas}></PrivateRoute>
				<PrivateRoute path="/academy/aulas/:idCurso/create/:idAula" exact component={aulaCreate}></PrivateRoute>
				<PrivateRoute path="/academy/aulas/:idCurso/create" exact component={aulaCreate}></PrivateRoute>
				<PrivateRoute path="/academy/gestao-trilha/trilha/:idTrilha/cursos" exact component={AcademyAcaCurso}></PrivateRoute>
				<PrivateRoute path="/academy/gestao-trilha/trilha/:idTrilha/cursos/:idCurso/aulas" exact component={AcademyAcaAula}></PrivateRoute>
				<PrivateRoute path="/academy/gestao-trilha/trilha/:idTrilha/cursos/:idCurso/aulas/:idAula/topico" exact component={AcademyAcaTopico}></PrivateRoute>

				<PrivateRoute path="/academy/trilhas/create" exact component={TrilhaCreate}></PrivateRoute>
				<PrivateRoute path="/academy/trilhas/:idTrilha/create" exact component={TrilhaCreate}></PrivateRoute>

				<PrivateRoute path="/academy/gestao-questao" exact component={AcademyQuestoes}></PrivateRoute>
				<PrivateRoute path="/academy/gestao-questao/create/:idQuestao?" exact component={AcademyQuestoesCreate}></PrivateRoute>


				<PrivateRoute path="/academy/trilhas/:idTrilha/cursos/create" exact component={CursoCreate}></PrivateRoute>
				<PrivateRoute path="/academy/trilhas/:idTrilha/cursos/:idCurso/create" exact component={CursoCreate}></PrivateRoute>

				<PrivateRoute path="/academy/trilhas/:idTrilha/cursos/:idCurso/aula/create" exact component={AcaAulaCreate}></PrivateRoute>
				<PrivateRoute path="/academy/trilhas/:idTrilha/cursos/:idCurso/aula/:idAula/create" exact component={AcaAulaCreate}></PrivateRoute>

				<PrivateRoute path="/academy/trilhas/:idTrilha/cursos/:idCurso/aula/:idAula/topico/create" exact component={AcaTopicoCreate}></PrivateRoute>
				<PrivateRoute path="/academy/trilhas/:idTrilha/cursos/:idCurso/aula/:idAula/topico/:idTopico/create" exact component={AcaTopicoCreate}></PrivateRoute>

				<PrivateRoute path="/curriculo" exact component={Curriculo}></PrivateRoute>

				<PrivateRoute path="/produto-escola" exact component={ProdutoEscola}></PrivateRoute>
				<PrivateRoute path="/produto-escola/create/step-1/:idProduto?" exact component={ProdutoEscolaCreateStep1}></PrivateRoute>

				<PrivateRoute path="/curriculo/create/step-1" exact component={CurriculoCreateStep1}></PrivateRoute>
				<PrivateRoute path="/curriculo/create/step-1/:id" exact component={CurriculoCreateStep1}></PrivateRoute>

				<PrivateRoute path="/recurso" exact component={Recurso}></PrivateRoute>
				<PrivateRoute path="/recurso/create/step-1" exact component={RecursoCreateStep1}></PrivateRoute>
				<PrivateRoute path="/recurso/create/step-1/:id" exact component={RecursoCreateStep1}></PrivateRoute>

				<PrivateRoute path="/academy/gestao-webinar/webinar" exact component={AcademyWebinar}></PrivateRoute>
				<PrivateRoute path="/academy/webinars/create" exact component={webinarCreate}></PrivateRoute>
				<PrivateRoute path="/academy/webinars/:idWebinar/create" exact component={webinarCreate}></PrivateRoute>

				<PrivateRoute path="/academy/gestao-conecta/conecta" exact component={AcademyConecta}></PrivateRoute>
				<PrivateRoute path="/academy/conectas/create" exact component={ConectaCreate}></PrivateRoute>
				<PrivateRoute path="/academy/conectas/:idConecta/create" exact component={ConectaCreate}></PrivateRoute>
			</Switch>
			<SalaContextProvider>
				<Switch>
					<PrivateRoute path="/sala" exact component={Sala}></PrivateRoute>
					<PrivateRoute path="/sala/create/step-1/:id?" exact component={SalaCreateStep1}></PrivateRoute>
					<PrivateRoute path="/sala/create/step-2/:id?" exact component={SalaCreateStep2}></PrivateRoute>
				</Switch>
			</SalaContextProvider>
			<NegocioContextProvider>
				<Switch>
					<PrivateRoute path="/negocio" exact component={Negocio}></PrivateRoute>
					<PrivateRoute path="/negocio/create/step-1" exact component={NegocioCreateStep1}></PrivateRoute>
					<PrivateRoute path="/negocio/create/step-2" exact component={NegocioCreateStep2}></PrivateRoute>
					<PrivateRoute path="/negocio/create/step-3" exact component={NegocioCreateStep3}></PrivateRoute>
					<PrivateRoute path="/negocio/create/step-4" exact component={NegocioCreateStep4}></PrivateRoute>
					<PrivateRoute path="/negocio/create/step-6/" exact component={NegocioCreateStep6}></PrivateRoute>
					<PrivateRoute path="/negocio-oferta/:idNegocio" exact component={OfertaNegocioHub}></PrivateRoute>
					<PrivateRoute path="/negocio-oferta/step-1/:idNegocio" exact component={NegocioOfertaCreateStep1}></PrivateRoute>
					<PrivateRoute path="/negocio-oferta/step-2/:idNegocio" exact component={NegocioOfertaCreateStep2}></PrivateRoute>
					<PrivateRoute path="/negocio-oferta/step-3/:idNegocio" exact component={NegocioOfertaCreateStep3}></PrivateRoute>
				</Switch>
			</NegocioContextProvider>
			<HoraSedeContextProvider>
				<Switch>
					<PrivateRoute path="/hora" exact component={HoraSede}></PrivateRoute>
					<PrivateRoute path="/hora/create/step-1" exact component={HoraSedeCreateStep1}></PrivateRoute>
					<PrivateRoute path="/hora/create/step-2" exact component={HoraSedeCreateStep2}></PrivateRoute>
					<PrivateRoute path="/hora/create/step-3" exact component={HoraSedeCreateStep3}></PrivateRoute>
					<PrivateRoute path="/hora/create/step-4" exact component={HoraSedeCreateStep4}></PrivateRoute>
					<PrivateRoute path="/hora/create/step-6/" exact component={HoraSedeCreateStep6}></PrivateRoute>
					<PrivateRoute path="/hora-oferta/:idhora" exact component={HoraUnidade}></PrivateRoute>
					<PrivateRoute path="/hora-oferta/step-1/:idhora" exact component={HoraUnidadeCreateStep1}></PrivateRoute>
					<PrivateRoute path="/hora-oferta/step-2/:idhora" exact component={HoraUnidadeCreateStep2}></PrivateRoute>
					<PrivateRoute path="/hora-oferta/step-3/:idhora" exact component={HoraUnidadeCreateStep3}></PrivateRoute>
				</Switch>
			</HoraSedeContextProvider>
			<TurmaContextProvider>
				<CursoFranquiaContextProvider>
					<Switch>
						<PrivateRoute path="/turmas" exact component={Turmas}></PrivateRoute>
						<PrivateRoute path="/turmas/professor" exact component={Turmas}></PrivateRoute>
						<PrivateRoute path="/turmas/detalhe/:id" exact component={TurmasDetalhe}></PrivateRoute>
						<PrivateRoute path="/turmas/professor/detalhe/:id" exact component={TurmasDetalhe}></PrivateRoute>
						<PrivateRoute path="/turmas/professor/lancamento-aula/:id" exact component={LancamentoAula}></PrivateRoute>
						<PrivateRoute path="/turmas/skills/aluno/:alunoId/frequencia/:idFrequencia" exact component={TurmasSkills}></PrivateRoute>
						<PrivateRoute path="/turmas/observacoes/aluno/:alunoId/frequencia/:idFrequencia" exact component={TurmasObservacoes}></PrivateRoute>
						<PrivateRoute path="/turmas/create/step-1" exact component={TurmasCreateStep1}></PrivateRoute>
						<PrivateRoute path="/turmas/create/step-2" exact component={TurmasCreateStep2}></PrivateRoute>
						<PrivateRoute path="/turmas/create/step-3" exact component={TurmasCreateStep3}></PrivateRoute>
						<PrivateRoute path="/curso-franquia" exact component={CursoFranquia}></PrivateRoute>
						<PrivateRoute path="/curso-franquia/turma" exact component={CursoFranquiaTurma}></PrivateRoute>
						<PrivateRoute path="/curso-franquia/step-2" exact component={CursoFranquiaRevisao}></PrivateRoute>
						<PrivateRoute path="/curso-franquia/step-3" exact component={CursoFranquiaRevisaoFinal}></PrivateRoute>
					</Switch>
				</CursoFranquiaContextProvider>
			</TurmaContextProvider>
			<AlunoContextProvider>
				<Switch>
					<PrivateRoute path="/alunos" exact component={Alunos}></PrivateRoute>
					<PrivateRoute path="/alunos/create/step-1" exact component={AlunosCreateStep1}></PrivateRoute>
					<PrivateRoute path="/alunos/create/step-1/lead/:idLead" exact component={AlunosCreateStep1}></PrivateRoute>
					<PrivateRoute path="/alunos/create/step-2" exact component={AlunosCreateStep2}></PrivateRoute>
					<PrivateRoute path="/alunos/create/step-3" exact component={AlunosCreateStep3}></PrivateRoute>
					<PrivateRoute path="/alunos/create/step-4" exact component={AlunosCreateStep4}></PrivateRoute>
					<PrivateRoute path="/alunos/create/step-5" exact component={AlunosCreateStep5}></PrivateRoute>
					<PrivateRoute path="/alunos/detalhe" exact component={AlunosDetalhe}></PrivateRoute>
					<PrivateRoute path="/alunos/detalhe/:idAluno" exact component={AlunosDetalhe}></PrivateRoute>
					<PrivateRoute path="/alunos/detalhe-matricula" exact component={AlunosDetalheMatricula}></PrivateRoute>
					<PrivateRoute path="/alunos/detalhe-matricula/:idMatricula/:idFinanceiro?" exact component={AlunosDetalheMatricula}></PrivateRoute>
					<PrivateRoute path="/alunos/transferencia-turma/:idMatricula" exact component={AlunosTransferenciaTurma} />
					<PrivateRoute path="/alunos/upgrade-turma/:idMatricula" exact component={AlunosUpgradeCobranca} />
					{/*<PrivateRoute path="/alunos/relatorio-habilidades/:idMatricula" exact component={RelatorioHabilidades} />*/}
					{/*<PrivateRoute path="/matriculas/create/matriculas-em-andamento" exact component={MatriculasEmAndamento}></PrivateRoute>*/}
					<PrivateRoute path="/matriculas/create/curso/:idFinanceiro?/:idTurma?" exact component={OfferPage}></PrivateRoute>
					<PrivateRoute path="/matriculas/create/step-1" exact component={MatriculasCreateStep1}></PrivateRoute>
					<PrivateRoute path="/matriculas/create/step-2" exact component={MatriculasCreateStep2}></PrivateRoute>
					<PrivateRoute path="/matriculas/create/step-3/:idFinanceiro?" exact component={MatriculasCreateStep3}></PrivateRoute>
					<PrivateRoute path="/matriculas/create/step-4/:idMatricula" exact component={MatriculasCreateStep4}></PrivateRoute>
					<PrivateRoute path="/matriculas/create/step-5" exact component={MatriculasCreateStep5}></PrivateRoute>
					<PrivateRoute path="/crm" exact component={Crm}></PrivateRoute>
					<PrivateRoute path="/crm/:idLead" exact component={Crm}></PrivateRoute>
				</Switch>
			</AlunoContextProvider>
			<UnidadeContextProvider>
				<Switch>
					<PrivateRoute path="/franquias" exact component={Dashboard}></PrivateRoute>
					<PrivateRoute path="/franquias/detalhes" exact component={DashboardDetalhes}></PrivateRoute>
					<PrivateRoute path="/franquias/create" exact component={FranquiaCreate}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-2" exact component={FranquiaCreateStep2}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-3" exact component={FranquiaCreateStep3}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-4" exact component={FranquiaCreateStep4}></PrivateRoute>
					<PrivateRoute path="/franquias/create/responsavel" exact component={FranquiaCreateResponsavel}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-5" exact component={FranquiaCreateSept5}></PrivateRoute>
					<PrivateRoute path="/franquias/create/gestor" exact component={FranquiaCreateGestor}></PrivateRoute>
					<PrivateRoute path="/franquias/create/socio" exact component={FranquiaCreateSocio}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-6" exact component={FranquiaCreateSept6}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-7" exact component={FranquiaCreateStep7}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-8" exact component={FranquiaCreateSept8}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-9" exact component={FranquiaCreateStep9}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-10" exact component={FranquiaCreateStep10}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-11" exact component={FranquiaCreateSept11}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-12" exact component={FranquiaCreateSept12}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-13" exact component={FranquiaCreateSept13}></PrivateRoute>
					<PrivateRoute path="/franquias/create/step-14" exact component={FranquiaCreateBanco}></PrivateRoute>
					<PrivateRoute path="/franquias/vinculo-externo/:id" exact component={VinculoExterno}></PrivateRoute>
					<PrivateRoute path="/franquias/pagamento-tipo" exact component={VinculoTipoPagamento}></PrivateRoute>
					<PrivateRoute path="/franquia/turmas" exact component={Turmas}></PrivateRoute>
				</Switch>
			</UnidadeContextProvider>
			<UsuarioContextProvider>
				<Switch>
					<PrivateRoute path="/usuarios" exact component={Usuarios}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/step-1" exact component={UsuariosCreateStep1}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/step-2" exact component={UsuariosCreateStep2}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/step-3" exact component={UsuariosCreateStep3}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/step-4" exact component={UsuariosCreateStep4}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/step-5" exact component={UsuariosCreateStep5}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/step-6" exact component={UsuariosCreateStep6}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/step-7" exact component={UsuariosCreateStep7}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/step-7/:id" exact component={UsuariosCreateStep7}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/step-8" exact component={UsuariosCreateStep8}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/step-9" exact component={UsuariosCreateStep9}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/franquia/step-1" exact component={UsuariosCreateFranquiaStep1}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/franquia/step-2" exact component={UsuariosCreateFranquiaStep2}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/franquia/step-3" exact component={UsuariosCreateFranquiaStep3}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/franquia/step-4" exact component={UsuariosCreateFranquiaStep4}></PrivateRoute>
					<PrivateRoute path="/usuarios/create/franquia/step-funcao" exact component={UsuariosCreateFranquiaStepFuncao}></PrivateRoute>
				</Switch>
			</UsuarioContextProvider>
			<TaxaContextProvider>
				<Switch>
					<PrivateRoute path="/taxas" exact component={Taxas}></PrivateRoute>
					<PrivateRoute path="/taxas/create/step-1" exact component={TaxasCreateStep1}></PrivateRoute>
					<PrivateRoute path="/taxas/create/step-2" exact component={TaxasCreateStep2}></PrivateRoute>
					<PrivateRoute path="/taxas/create/step-3" exact component={TaxasCreateStep3}></PrivateRoute>
					<PrivateRoute path="/taxas/create/step-4" exact component={TaxasCreateStep4}></PrivateRoute>
					<PrivateRoute path="/taxas/create/step-6" exact component={TaxasCreateStep6}></PrivateRoute>
					<PrivateRoute path="/taxas/create/step-6/:id" exact component={TaxasCreateStep6}></PrivateRoute>
				</Switch>
			</TaxaContextProvider>
			<CampanhaContextProvider>
				<Switch>
					<PrivateRoute path="/campanhas" exact component={Campanhas}></PrivateRoute>
					<PrivateRoute path="/campanhas/create/step-1" exact component={CampanhasCreateStep1}></PrivateRoute>
					<PrivateRoute path="/campanhas/create/step-2" exact component={CampanhasCreateStep2}></PrivateRoute>
					<PrivateRoute path="/campanhas/create/step-3" exact component={CampanhasCreateStep3}></PrivateRoute>
					<PrivateRoute path="/campanhas/create/step-4" exact component={CampanhasCreateStep4}></PrivateRoute>
					<PrivateRoute path="/campanhas/create/step-5" exact component={CampanhasCreateStep5}></PrivateRoute>
					<PrivateRoute path="/campanhas/create/step-6" exact component={CampanhasCreateStep6}></PrivateRoute>
					<PrivateRoute path="/campanhas/create/step-6/:id" exact component={CampanhasCreateStep6}></PrivateRoute>
				</Switch>
			</CampanhaContextProvider>
			<CursoContextProvider>
				<Switch>
					<PrivateRoute path="/cursos" exact component={Cursos}></PrivateRoute>
					<PrivateRoute path="/cursos/create/step-1" exact component={CursosCreateStep1}></PrivateRoute>
					<PrivateRoute path="/cursos/create/step-2" exact component={CursosCreateStep2}></PrivateRoute>
					<PrivateRoute path="/cursos/create/step-3" exact component={CursosCreateStep3}></PrivateRoute>
					<PrivateRoute path="/cursos/create/step-4" exact component={CursosCreateStep4}></PrivateRoute>
					<PrivateRoute path="/cursos/create/step-5" exact component={CursosCreateStep5}></PrivateRoute>
					<PrivateRoute path="/cursos/create/step-6" exact component={CursosCreateStep6}></PrivateRoute>
					<PrivateRoute path="/cursos/create/step-6/:editarCurso" exact component={CursosCreateStep6}></PrivateRoute>
					<PrivateRoute path="/cursos-oferta/:idCurso" exact component={OfertaCursoHub}></PrivateRoute>
					<PrivateRoute path="/cursos/oferta/step-1/:idCursoUnidade" exact component={CursosOfertaCreateStep1}></PrivateRoute>
					<PrivateRoute path="/cursos/oferta/step-2/:idCursoUnidade" exact component={CursosOfertaCreateStep2}></PrivateRoute>
					<PrivateRoute path="/cursos/oferta/step-3/:idCursoUnidade" exact component={CursosOfertaCreateStep3}></PrivateRoute>
				</Switch>
			</CursoContextProvider>
			<ColaboradorContextProvider>
				<Switch>
					<PrivateRoute path="/colaboradores" exact component={Colaboradores}></PrivateRoute>
					<PrivateRoute path="/colaboradores/create/step-1" exact component={ColaboradoresCreateStep1}></PrivateRoute>
					<PrivateRoute path="/colaboradores/create/step-2" exact component={ColaboradoresCreateStep2}></PrivateRoute>
					<PrivateRoute path="/colaboradores/create/step-3" exact component={ColaboradoresCreateStep3}></PrivateRoute>
					<PrivateRoute path="/colaboradores/create/step-4" exact component={ColaboradoresCreateStep4}></PrivateRoute>
					<PrivateRoute path="/colaboradores/create/step-4/:id" exact component={ColaboradoresCreateStep4}></PrivateRoute>
				</Switch>
			</ColaboradorContextProvider>
			<ColaboradorContextProvider>
				<Switch>
					<PrivateRoute path="/metas-tipo" exact component={MetasTipo}></PrivateRoute>
					<PrivateRoute path="/metas-tipo/create" exact component={MetaTipoCreate}></PrivateRoute>
					<PrivateRoute path="/metas-tipo/create/:idMetaTipo" exact component={MetaTipoCreate}></PrivateRoute>
					<PrivateRoute path="/metas" exact component={Metas}></PrivateRoute>
					<PrivateRoute path="/metas/create" exact component={MetaCreate}></PrivateRoute>
					<PrivateRoute path="/metas/create/:idMeta" exact component={MetaCreate}></PrivateRoute>
					<PrivateRoute path="/meta/:idMeta/metas-unidade/create" exact component={MetaUnidadeCreate}></PrivateRoute>
					<PrivateRoute path="/metas-unidade/:idMeta" exact component={MetasUnidade}></PrivateRoute>
					<PrivateRoute path="/metas-unidade/create/:idMetaUnidade" exact component={MetaUnidadeCreate}></PrivateRoute>
				</Switch>
			</ColaboradorContextProvider>

			<EscolaContextProvider>
				<Switch>
					<PrivateRoute path="/escolas/grupos" exact component={EscolaGrupo}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/detalhes" exact component={DashboardDetalhes}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/create/responsavel/:tipo" exact component={GrupoCreateResponsavelScreen}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/create/step-1" exact component={GrupoCreateStep1Screen}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/create/step-2" exact component={GrupoCreateStep2Screen}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/create/step-3" exact component={GrupoCreateStep3Screen}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/create/step-4" exact component={GrupoCreateStepRevisaoScreen}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/:idGrupo/contratos" exact component={GrupoContratoListagemScreen}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/:idGrupo/contratos/create/step-1" exact component={GrupoContratoCreateStep1Screen}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/:idGrupo/contratos/create/step-1/:idLead" exact component={GrupoContratoCreateStep1Screen}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/:idGrupo/contratos/create/step-2" exact component={GrupoContratoCreateStep2Screen}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/:idGrupo/contratos/create/step-3" exact component={GrupoContratoCreateStep3Screen}></PrivateRoute>
					<PrivateRoute path="/escolas/grupos/:idGrupo/contratos/create/step-4" exact component={GrupoContratoCreateStepRevisaoScreen}></PrivateRoute>

					<PrivateRoute path="/escolas" exact component={EscolaScreen}></PrivateRoute>
					<PrivateRoute path="/escolas/create/step-1" exact component={EscolaCreateStep1Screen}></PrivateRoute>
					<PrivateRoute path="/escolas/create/step-2" exact component={EscolaCreateStep2Screen}></PrivateRoute>
					<PrivateRoute path="/escolas/create/step-3" exact component={EscolaCreateStep3Screen}></PrivateRoute>
					<PrivateRoute path="/escolas/create/step-4" exact component={EscolaCreateStepRevisaoScreen}></PrivateRoute>
					<PrivateRoute path="/escolas/create/responsavel/:tipo" exact component={EscolaCreateResponsavelScreen}></PrivateRoute>

					<PrivateRoute path="/escolas-financeiro" exact component={GrupoFinanceiroListagemScreen}></PrivateRoute>
					<PrivateRoute path="/escolas/crm" exact component={CrmEscola}></PrivateRoute>
				</Switch>
			</EscolaContextProvider>
		</BrowserRouter>
	);
};

export default Routes;
